import { act, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  getlivePrice,
  GetClientScript,
  getCoinPrice,
  checkStatus,
} from "./api";
import socket from "../../api/socket";
import { CLIENT_URL } from "../../constants";
import toast from "react-hot-toast";
import { toastSettings } from "../../constants/index";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
const useLivePrice = () => {
  const [count, setcount] = useState(1);
  const [oldRateval, setOldRateVal] = useState([]);
  const [rateval, setRateVal] = useState([]);
  const [goldCoinRate, setGoldCoinRate] = useState([]);
  const [activeTab, setActiveTab] = useState("gold");
  const [hlColor, setHlColor] = useState("");
  const [clickable, setClickable] = useState(true);
  const [isSkeleton, setIsSkeleton] = useState(false);

  const userInfo = localStorage.getItem("userId");
  let onCount = 0;
  const [registerCount, setRegisterCount] = useState(onCount);

  let timeoutID = "";
  let dataRef = useRef();
  let dataRefOld = useRef();
  const Router = useNavigate();
  const {
    data: { data: { data: liverates } = {} } = {},
    refetch: priceRefetch,
    isLoading,
  } = useQuery("fetchOffers", () => getlivePrice(count, activeTab), {
    enabled: false,
    onSuccess: (data) => {
      console.log(data?.data?.data, "dataliverate");
    },
  });
  const toggleTab = (tabName) => {
    setActiveTab(tabName);
    setClickable(false);
    clearTimeout(timeoutID);
    setcount(2);
    setTimeout(() => {
      setClickable(true);
    }, 500);
  };

  useEffect(() => {
    if (registerCount !== 0) {
      // socket.emit("clear-socket", socket.id);

      socket.emit("register", CLIENT_URL, activeTab, "homePage");
      socket.on("data-received", (data) => {

        if (data?.isSuccess) {
          setOldRateVal(rateval)
          setRateVal(data?.data)
          // dataRefOld.current=dataRef.current;
          // dataRef.current = data?.data;
        }
      });
    }
  }, [activeTab]);

  const handleSkeleton = () => {
    setIsSkeleton(true);
    setTimeout(() => {
      setIsSkeleton(false);
    }, 1000);
  };
  const tradeChecker = async (item) => {
    if (userInfo) {
      const payload = {
        alphaname: item?.scriptId,
        betaName: userInfo,
        amt: item?.sell,
      };
      handleSCode(item?.scriptId);
      const check = await checkStatusTrade(payload);
    } else {
      Router("/login");
    }

    // if(!item?.allowTrade){
    //   toast.error(
    //     "Trade Not Allowed for this script",
    //     toastSettings
    //   );
    //   return false;
    // }else{
    //   return true;
    // }
  };

  const { mutate: checkStatusTrade } = useMutation(
    (payload) =>
      checkStatus(payload?.alphaname, payload?.betaName, payload?.amt),
    {
      onSuccess: (data) => {
        if (data?.data?.is_success) {
          if (data?.data?.status) {
            handleSocket();
            Router("/new-order");

            toast.success(data?.data?.msg);
          } else {
            handleSCode("");
            toast.error(data?.data?.msg);
          }
        }
      },
      onError: () => {
        toast.error("An Error occured while sending OTP");
      },
    }
  );

  const handleSocket = () => {
    socket.emit("page-change", "homePage");
  };

  useEffect(() => {
    socket.emit("register", CLIENT_URL, activeTab, "homePage");
    socket.on("data-received", (data) => {

      if (data?.isSuccess) {
        console.log(oldRateval, "checks1", rateval);
        setOldRateVal(rateval ? rateval : [])
        setRateVal(data?.data)
        // dataRefOld.current=dataRef.current;
        // dataRef.current = data?.data;
      }
    });
    onCount++;
    setRegisterCount(onCount);
  }, []);

  useEffect(() => {
    if (count != 0) {
      timeoutID = setTimeout(() => {
        // setOldRateVal(dataRef.current);
        setcount(count + 1);
        socket.on("data-received", (data) => {
          if (data?.isSuccess) {
            console.log(oldRateval, "checks2", rateval);
            setOldRateVal(rateval ? rateval : [])
            setRateVal(data?.data)
            // dataRefOld.current=dataRef.current;
            // dataRef.current = data?.data;
          }
        });
      }, 500);
    }
    return () => clearTimeout(timeoutID);
  }, [count]);

  const handleColor = (index, field, rate = false) => {
    const oldDatas = oldRateval?.length > 0 ? oldRateval[index][field] : 0;
    const liveData = rateval?.length > 0 ? rateval[index][field] : 0;
    let sign = Math.sign(liveData - oldDatas);

    if (rate) {
      return liveData;
    } else {

      if (liveData > oldDatas) {
        return "green";
      } else if (liveData < oldDatas) {
        return "red";
      } else {
        return "bg-gray-200";
      }
    }


    // if (sign === 1) {
    //   return "green";
    // } else if (sign === -1) {
    //   return "red";
    // } else {
    //   return "bg-gray-200";
    // }
  };
  const handleSCode = (sc) => {
    let scriptCode = sc;
    localStorage.setItem("scId", scriptCode);
  };

  return {
    isLoading,
    count,
    handleColor,
    liverates,
    oldRateval,
    handleSCode,
    goldCoinRate,
    activeTab,
    toggleTab,
    hlColor,
    dataRef,
    clickable,
    isSkeleton,
    handleSkeleton,
    handleSocket,
    tradeChecker, dataRefOld,
    oldRateval,
    rateval
  };
};

export default useLivePrice;
