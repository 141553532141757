import React from "react";
import PrivacyPolicy from "../container/FooterContent/Privacy-policy";

const Privacy = () => {
  return (
    <>
      <PrivacyPolicy />
    </>
  );
};

export default Privacy;
