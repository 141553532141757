// import { createApi } from '@reduxjs/toolkit/query/react'
// import axiosBaseQuery from '../../../api/axiosBaseQuery'
//
//
// const liveRootApi=createApi({
//     reducerPath:"liveRoot",
//     baseQuery:axiosBaseQuery(),
//     endpoints:(builder)=>({
//         getLiveRates:builder.query({
//                 query: () => ({ url: '/live-rates', method: 'GET',params: {}}),
//         }),
//     })
// })
//
// export const { useGetLiveRatesQuery } = liveRootApi;
// export default liveRootApi;

