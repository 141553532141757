import React from "react";
import LoginPage from "../Features/Auth/Components/LoginPage";

const Login = () => {
  return (
    <div>
      <LoginPage/>
    </div>
  );
};

export default Login;
