import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getScriptTypes,
  getSelectedScript,
  setSelectedScript,
} from "../../State/homePageSlice";
import { RootDataCards } from "../RootDataCards";
import ScriptsTable from "../ScriptsTable";

function TabletScreen() {
  const dispatch = useDispatch();
  const scriptTypes = useSelector(getScriptTypes);
  const activeTab = useSelector(getSelectedScript);
  return (
    <div className="flex flex-col w-full h-full px-12 py-3 mt-44 mb-20">
      <div className="flex mx-4 w-full border border-gray-300 rounded-2xl shadow-sm bg-white py-3 bg-custom-gradient">
        {scriptTypes?.map((script, index) => {
          if (script?.active) {
            return (
              <React.Fragment key={index}>
                <div
                  className="size-full flex flex-col items-center justify-center gap-1"
                  onClick={() => {
                    dispatch(setSelectedScript(script.title));
                  }}
                >
                  <p
                    className={`
                   
                    text-white
                     cursor-pointer`}
                  >
                    {script?.title?.toUpperCase()}
                  </p>
                  {activeTab == script?.title && (
                    <div className="w-[90px] bg-slate-700 h-[2px]"></div>
                  )}
                </div>
                {index !== scriptTypes?.length - 1 && (
                  <div className="h[4px] w-[2px] bg-slate-800"></div>
                )}
              </React.Fragment>
            );
          }
        })}
      </div>
      <RootDataCards />

      <ScriptsTable />
    </div>
  );
}

export default TabletScreen;
