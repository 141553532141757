import React from "react";
import TradeHistoryPage from "../container/Trade/TradeHistory";

const TradeHistory = () => {
  return (
    <>
      <TradeHistoryPage />
    </>
  );
};

export default TradeHistory;
