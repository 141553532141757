import { useNavigate } from "react-router-dom";
import { useDeviceType } from "../../../utils/useMobile";
import gold1 from "../../../assets/cards/bars 1.png";
import gold2 from "../../../assets/cards/gold 3.png";
import goldRate from "../../../assets/cards/golddollar.png";
import dollar from "../../../assets/cards/dollar.png";
import silverRate from "../../../assets/cards/silverdollar.png";
import { memo } from "react";
import { usePriceFetcher } from "../../LivePrice/Hooks/usePriceFetcher";
import { useClientRootScripts } from "../Hooks/useClientRootScripts";

export const RootDataCards = memo(() => {
  const { data } = useClientRootScripts();

  return (
    <div className="flex justify-between items-center space-x-4 px-2 py-8">
      {data
        ? data?.map((item, index) => {
            return <RootCard item={item} key={item._id} />;
          })
        : Array.from({ length: 5 }).map((_, index) => (
            <ShimmerRootCard key={index} />
          ))}
    </div>
  );
});

const RootCard = ({ item }) => {
  const isDesktop = useDeviceType("desktop");
  const isMobileScreen = useDeviceType("mobile");
  const images = {
    "GOLD ($)": goldRate,
    "SILVER ($)": silverRate,
    "INR (₹)": dollar,
    GOLD: gold2,
    SILVER: gold1,
  };
  const navigate = useNavigate();

  return (
    <div
      onClick={() =>
        navigate(`/chart?${item.title.split(" ")[0].toLowerCase()}`)
      }
      key={item._id}
      className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-full text-center shadow-md flex flex-col items-center justify-center hover:cursor-pointer"
    >
      <div className="flex justify-center mb-2 border-[1px] border-gray-600 rounded-full h-[50px] w-[50px]  bg-white items-center mt-[-30px]">
        <img
          src={images[item.title] || silverRate}
          alt="Gold Icon"
          className="h-[25px] w-[25px]"
        />
      </div>
      <div className="text-gray-600 mt-5">{item.title}</div>
      <>
        <PriceTag item={item} isMobileScreen={isMobileScreen} />
      </>
    </div>
  );
};

const PriceTag = ({ item, isMobileScreen }) => {
  const isDesktop = useDeviceType("desktop");
  const { price, color, percentage, diff, percentageColor } = usePriceFetcher({
    refer: item.rootScript,
    multiplier: item?.buyMultiplier,
    addition: item?.buyAdditive,
    type: "buy",
  });
  return (
    <>
      <div key={item}>
        {isDesktop && (
          <div className="" style={{ height: "15px", width: "10px" }}></div>
        )}
        <p
          style={{
            color: `${color}`,
            transition: "color 0.1s ease-in-out",
            fontSize: `${isMobileScreen ? "30px" : "24px"}`,
            fontWeight: "bold",
          }}
        >
          {price &&
          parseFloat(price).toFixed(2) % 1 !== 0 &&
          parseFloat(price).toFixed(1) % 1 !== 0
            ? parseFloat(price).toFixed(2)
            : parseFloat(price).toFixed(0)}
        </p>
      </div>
      <div className={` rounded-full py-[5px] px-4 mt-3 mx-1 bg-gray-200`}>
        <div className="flex justify-center items-center w-full">
          <div>
            <p className="text-[10px] mr-1">
              {diff > 0 && "+"}
              {diff.toFixed(2)}
            </p>
            <p className="text-[10px] mr-1">
              ({percentage > 0 && "+"}
              {percentage.toFixed(2)}%)
            </p>
          </div>
          {percentageColor === "red" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={`red`}
              class="size-6"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z"
                clip-rule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={`green`}
              className="size-6"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
      </div>
    </>
  );
};

const ShimmerRootCard = () => (
  <div className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-full text-center shadow-md flex flex-col items-center justify-center relative ">
    {/* Icon Shimmer */}
    <div className="mb-2 border-[1px] border-gray-600 rounded-full h-[50px] w-[50px] bg-gray-200 mt-[-30px] relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-100 to-transparent animate-shimmer" />
    </div>

    {/* Title Shimmer */}
    <div className="h-4 bg-gray-200 rounded w-3/4 mt-5 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-100 to-transparent animate-shimmer" />
    </div>
    <div className="h-[70px] overflow-hidden"></div>

    {/* Price Shimmer */}
    <div className="h-6 bg-gray-200 rounded w-1/2 mt-4 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-gray-100 to-transparent animate-shimmer" />
    </div>
  </div>
);
