import React, { useContext, useState } from "react";
import phone from "../../assets/phone.png";
import whatsapp from "../../assets/whatsapp.png";
import signout from "../../assets/signout.png";
import LogoutModal from "../CustomModal/CustomModal";
import { useSelector } from "react-redux";
import {
  getClientphoneNumber,
  getWhatsAppNumber,
} from "../../Store/clientSlice";

const DrawerFooter = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const phone = useSelector(getClientphoneNumber);
  const whatsApp = useSelector(getWhatsAppNumber);
  const logout = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="flex w-full h-[50px] justify-between items-center">
        <div className="flex items-center justify-normal gap-2 ml-3">
          <div className="h-[40px] w-[40px] bg-custom-gradient flex items-center justify-center rounded-full">
            <a href={`tel:+${phone}`} target="_blank" rel="noreferrer">
              <img src={phone} alt="phone" />
            </a>
          </div>
          <div className="h-[40px] w-[40px] bg-custom-gradient flex items-center justify-center rounded-full">
            <a
              href={`https://wa.me/${whatsApp}?text=Hello%20I%20am%20interested%20in%20your%20services`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={whatsapp} alt="phone" />
            </a>
          </div>
        </div>
        <div
          className="h-full w-[160px] bg-custom-gradient flex items-center justify-center rounded-tl-3xl gap-2"
          onClick={logout}
        >
          <img src={signout} alt="" />
          <p className="text-white">LOG OUT</p>
        </div>
      </div>
      <LogoutModal isModalOpen={isModalOpen} setModalOpen={setIsModalOpen} />
    </>
  );
};

export default DrawerFooter;
