import { useState } from "react";
import TrendPredictionCard from "./TrendPredictionCard";

function PredictionContainer({ reference }) {
  const symbolVal = {
    gold: "TVC:GOLD",
    silver: "SILVER",
    inr: "FX_IDC:USDINR",
  };
  const [selected, setSelected] = useState("gold");
  return (
    <div className="flex flex-col mr-3 mt-4 mb-4" ref={reference}>
      {/* Header */}
      <div className="bg-custom-gradient flex items-center justify-center h-[40px] rounded-t-[20px]">
        <p className="text-lg text-white">Prediction</p>
      </div>

      {/* Content with Horizontal Scrolling */}
      <div className="flex flex-col bg-white rounded-b-[20px] p-4 ">
        {/* Scrollable Button Section */}
        <div className="flex flex-wrap gap-2 mb-4 justify-center">
          {Object.keys(symbolVal).map((key) => (
            <button
              key={key}
              onClick={() => setSelected(key)}
              className={`px-4 py-2 rounded-lg text-white font-semibold transition-all 
            ${
              selected === key
                ? "shadow-lg shadow-yellow-500 bg-yellow-500"
                : "bg-gray-700 hover:bg-gray-600"
            }
          `}
            >
              {key.toUpperCase()}
            </button>
          ))}
        </div>

        {/* Scrollable Trend Prediction Card */}
        <div className="overflow-x-auto w-full">
          <TrendPredictionCard symbol={symbolVal[selected]} type={selected} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center">
      {/* Glowing Selection Buttons */}
      <div className="flex gap-4 mb-4">
        {Object.keys(symbolVal).map((key) => (
          <button
            key={key}
            onClick={() => setSelected(key)}
            className={`px-4 py-2 rounded-lg text-white font-semibold transition-all 
              ${selected === key ? "shadow-lg shadow-yellow-500 bg-yellow-500" : "bg-gray-700 hover:bg-gray-600"}
            `}
          >
            {key.toUpperCase()}
          </button>
        ))}
      </div>

      <TrendPredictionCard symbol={symbolVal[selected]} type={selected} />
    </div>
  );
}

export default PredictionContainer;
