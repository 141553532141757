import { FaChevronUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getSelectedScript } from "../../State/homePageSlice";
import gold1 from "../../../../assets/cards/bars 1.png";
import gold2 from "../../../../assets/cards/gold 3.png";
import goldRate from "../../../../assets/cards/golddollar.png";
import dollar from "../../../../assets/cards/dollar.png";
import silverRate from "../../../../assets/cards/silverdollar.png";
import { useHandleCalculatePrice } from "../../Hooks/useHandleCalculatePrice";
import { useHandleTradeRouting } from "../../Hooks/useHandleTradeRouting";
import { getMargin } from "../../../Access/Store/accessSlice";
import { useClientScriptFetcher } from "../../Hooks/useClientScriptFetcher";
const MobileScriptTable = () => {
  const { scripts } = useClientScriptFetcher({ pagination: false });
  const showScript = useSelector(getSelectedScript);

  return scripts?.map((item, index) => {
    return <ScriptRow item={item} index={index} />;
  });
};

const ScriptRow = ({ item, index }) => {
  const images = {
    "GOLD ($)": goldRate,
    "SILVER ($)": silverRate,
    "INR (₹)": dollar,
    GOLD: gold2,
    SILVER: gold1,
  };
  return (
    <div
      className="flex w-full h-auto min-h-[80px] border-[1px] border-gray-200 py-2 rounded-3xl bg-white justify-between items-center mt-3 px-2 last:mb-6"
      key={index}
    >
      <div className="flex w-2/3 px-2 justify-start items-center gap-2">
        {/* <img */}
        {/*   src={images[item?.rootScript]} */}
        {/*   alt="Gold Icon" */}
        {/*   className="w-[28px] h-[28px] pb-2" */}
        {/* /> */}
        <p className="text-[14px]">{item?.title}</p>
      </div>
      <Price item={item} type={"buy"} />
      <div className="flex gap-1 w-1/3 ml-2 justify-center items-start ">
        <Price item={item} type={"sell"} />

        {/* <div className="flex mr-2 mt-3"> */}
        {/*   {handleColor(index, "sell") == "green" ? ( */}
        {/*     <FaArrowAltCircleUp style={{ fontSize: "20px", color: "green" }} /> */}
        {/*   ) : handleColor(index, "sell") == "red" ? ( */}
        {/*     <FaArrowAltCircleDown style={{ fontSize: "20px", color: "red" }} /> */}
        {/*   ) : ( */}
        {/*     <BsPatchCheckFill style={{ fontSize: "20px", color: "gray" }} /> */}
        {/* )} */}
        {/* </div> */}
      </div>
    </div>
  );
};

const Price = ({ item, type }) => {
  const { price, color, high, low } = useHandleCalculatePrice({
    type,
    item,
  });
  const canTrade = useSelector(getMargin);
  const { tradeChecker } = useHandleTradeRouting();
  return (
    <div className="flex flex-col items-center justify-center gap-1">
      {item?.buy !== "-" ? (
        <>
          <div
            style={{ color: color }}
            className={`flex items-center justify-center border-2 ${color} px-4  h-[40px] rounded-3xl`}
            onClick={() => {
              canTrade && tradeChecker(item);
            }}
          >
            <p>
              {item.rootScript?.includes("$") || item.isCosting ? "$" : "₹"}
              {price && parseFloat(price).toFixed(1) % 1 !== 0
                ? parseFloat(price).toFixed(1)
                : parseFloat(price).toFixed(0)}
            </p>
          </div>
          {type === "buy" && item?.buy == "-" ? (
            <p className=" text-gray-500 text-[12px]">H: - | L: -</p>
          ) : (
            <p className=" text-gray-500 text-[12px] flex flex-col">
              <span className="text-green-500 font-medium">
                H:{high.toFixed(1)}
              </span>
              <span className="text-red-500 font-medium">
                L:{low.toFixed(1)}
              </span>
            </p>
          )}
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

export default MobileScriptTable;
