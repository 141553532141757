import { useHandleCalculatePrice } from "../../Features/Home/Hooks/useHandleCalculatePrice";
export const useBuyAndSellRate = (script) => {
  const { price: buyPrice, color: buyColor } = useHandleCalculatePrice({
    type: "buy",
    item: script,
  });
  const { price: sellPrice, color: sellColor } = useHandleCalculatePrice({
    type: "sell",
    item: script,
  });
  return { buyPrice, sellColor, buyColor, sellPrice };
};
