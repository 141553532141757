import axios from "axios";
import { api, apiEndPoints } from "../../api/index";
import { CLIENT_URL } from "../../constants/index";
export const getBankdetails = async () => { 


    const response = await api.get(apiEndPoints.bankAccounts, {
      params: {
        pagination: false,
        clientId: CLIENT_URL,
      },
    });
    return response;
  
};

