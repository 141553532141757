import React, { createContext, useState, useEffect } from "react";

// Create the UserContext
const AppContext = createContext();

// Create a provider component
const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("LoggedInUser");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const clearUser = () => {
    setUser(null);
    localStorage.clear();
  };

  return (
    <AppContext.Provider value={{ user, clearUser, setUser }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
