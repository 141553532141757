import React, { useEffect } from "react";
import { useNotificationPanelHook } from "../Hooks/useNotificationPanelHook";
import NotificationScreen from "./NotificationScreen";

const NotificationPanel = () => {
  const { isOpen } = useNotificationPanelHook();
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className="w-full h-full bg-gray-800 bg-opacity-90 fixed inset-0 z-40">
          <div
            className="absolute z-50 right-0 flex flex-col top-0 h-full overflow-y-scroll  bg-gray-50 p-4 sm:p-6 lg:p-8 transform transition ease-in-out duration-700"
            style={{ width: "70vw", maxWidth: "400px" }}
          >
            <NotificationScreen />
          </div>
        </div>
      )}
    </>
  );
};
export default NotificationPanel;
