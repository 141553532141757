import React from "react";
import MainScreen from "./MobileScreen";
import { useDispatch, useSelector } from "react-redux";
import {
  getScriptTypes,
  getSelectedScript,
  setSelectedScript,
} from "../../State/homePageSlice";

const MobileHome = (props) => {
  const dispatch = useDispatch();
  const scriptTypes = useSelector(getScriptTypes);
  const activeTab = useSelector(getSelectedScript);

  return (
    <div className="flex h-auto w-full flex-col mt-40 mb-20">
      <div className="bg-[#F3F3F3] h-full w-full flex flex-col px-2">
        <div className="flex justify-between items-center px-10 py-1  my-2 bg-white border-2 border-color-[#C6C6C6] rounded-2xl ">
          {scriptTypes?.map((script, index) => {
            if (script?.isShow) {
              return (
                <React.Fragment key={index}>
                  <div
                    className="flex flex-col gap-1 justify-center items-center"
                    onClick={() => dispatch(setSelectedScript(script?.title))}
                  >
                    <p
                      className={`${
                        activeTab == script?.title
                          ? "text-color-primary"
                          : "text-black"
                      } text-[12px]`}
                    >
                      {script?.title?.toUpperCase()}
                    </p>
                    {activeTab == script?.title && (
                      <div className="w-[60px] bg-slate-700 h-[2px]"></div>
                    )}
                  </div>

                  <div className="w-[2px] h-[20px] bg-color-primary"></div>
                </React.Fragment>
              );
            }
          })}
        </div>

        <MainScreen
        // liverates={liverates}
        // handleColor={handleColor}
        // handleSCode={handleSCode}
        // handleSocket={handleSocket}
        // activeTab={activeTab}
        />
      </div>
    </div>
  );
};

export default MobileHome;
