import { configureStore } from "@reduxjs/toolkit";
import pendingOrdersApiSlice from "../Features/PendingOrders/Store/pendingOrderApiSlice";
import authAPISlice from "../Features/Auth/Store/AuthApiSlice";
import AuthReducer, { setUserId } from "../Features/Auth/Store/AuthSlice";
import liveRootApiSlice from "../Features/LivePrices/Store/LiveRootApiSlice";
import limitReducer, {
  resetLimit,
} from "../Features/UserLimit/Store/limitSlice";
// import liveRootReducer from "../Features/LivePrices/Store/liveRootScriptSlice";
import chattingReducer from "../Features/Chatting/Store/chattingSlice";
import notificationReducer, {
  resetNotificationCount,
} from "../Features/Notifications/Store/notificationSlice";
import notificationApiSlice from "../Features/Notifications/Store/notificationApiSlice";
import liveRates from "../Features/LivePrice/Store/livePriceSlice";
import homepageReducer from "../Features/Home/State/homePageSlice";
import orderReducer from "../Features/Orders/State/OrderSlice";
import alertApiSlice from "../Features/Alerts/Store/alertApiSlice";
import newsApiSlice from "../Features/News/Hooks/getNewsApiSlice";
import clientScriptAPI from "../Features/Home/State/ClientScriptsApi";
import { setUnauthorizedErrorHandler } from "../api/errorHandler";
import clientReducer from "./clientSlice";
import accessReducer from "../Features/Access/Store/accessSlice";
const store = configureStore({
  reducer: {
    auth: AuthReducer,
    limit: limitReducer,
    client: clientReducer,
    // liveRootScript: liveRootReducer,
    homepage: homepageReducer,
    liveRates: liveRates,
    chatting: chattingReducer,
    notification: notificationReducer,
    order: orderReducer,
    access: accessReducer,

    [pendingOrdersApiSlice.reducerPath]: pendingOrdersApiSlice.reducer,
    [newsApiSlice.reducerPath]: newsApiSlice.reducer,
    [clientScriptAPI.reducerPath]: clientScriptAPI.reducer,
    [alertApiSlice.reducerPath]: alertApiSlice.reducer,
    [authAPISlice.reducerPath]: authAPISlice.reducer,
    // [liveRootApiSlice.reducerPath]: liveRootApiSlice.reducer,
    [notificationApiSlice.reducerPath]: notificationApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      newsApiSlice.middleware,
      clientScriptAPI.middleware,
      pendingOrdersApiSlice.middleware,
      alertApiSlice.middleware,
      authAPISlice.middleware,
      // liveRootApiSlice.middleware,
      notificationApiSlice.middleware,
    ),
});

setUnauthorizedErrorHandler(() => {
  store.dispatch(setUserId(null));
  store.dispatch(resetLimit());
  store.dispatch(resetNotificationCount());
  // store.dispatch(notificationApiSlice.util.resetApiState());
});

export default store;
