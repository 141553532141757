import React, { useContext, useState } from "react";
import { Modal, Button } from "antd";
import logoutImg from "../../assets/logout.svg";
import toast from "react-hot-toast";
import { AppContext } from "../../context/AppContext";
import { useDispatch } from "react-redux";
import { setUserId } from "../../Features/Auth/Store/AuthSlice";
import { handleUnauthorizedError } from "../../api/errorHandler";
import notificationApiSlice from "../../Features/Notifications/Store/notificationApiSlice";

const LogoutModal = (props) => {
  const { isModalOpen, setModalOpen } = props;
  const { clearUser } = useContext(AppContext);
  const dispatch = useDispatch();

  const handleOk = () => {
    const country = localStorage.getItem("COUNTRY");
    localStorage.clear();
    localStorage.setItem("COUNTRY", country);
    clearUser();
    dispatch(setUserId(null));
    handleUnauthorizedError();
    dispatch(notificationApiSlice.util.resetApiState());
    toast.success("Logged Out");
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} // Custom footer
        centered
        wrapClassName="rounded-lg"
        className="custom-modal bg-white p-6 rounded-lg  text-center"
        closeIcon={
          <span className="text-gray-500 hover:text-gray-700">&times;</span>
        }
      >
        <div className="text-center flex flex-col gap-5">
          <h2 className="text-3xl text-gray-900">LOGOUT</h2>
          <div className="flex justify-center items-center w-full">
            <img src={logoutImg} alt="" />
          </div>

          <p className="text-lg text-gray-700">
            Are you sure you want to Logout?
          </p>
          <div className="flex justify-center mt-6">
            <Button
              onClick={handleOk}
              className="bg-white border-2 border-black text-black px-12 py-5 rounded-full mr-4 hover:bg-gray-100"
            >
              OK
            </Button>
            <Button
              onClick={handleCancel}
              className="bg-custom-gradient text-white px-12 py-5 rounded-full hover:from-teal-600 hover:to-green-800"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LogoutModal;
