import React from "react";
import OrderPage from "../container/Order/Order";

const Order = () => {
  return (
    <>
      <OrderPage />
    </>
  );
};

export default Order;
