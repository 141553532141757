import React, { useContext, useState } from "react";
import logo from "../../assets/bullioon-logo.png";
import { AWS_CLIENT_CODE, AWS_S3_URL } from "../../constants/index";
import { useDeviceType, useMobile } from "../../utils/useMobile";
import hamburger from "../../assets/navbar.svg";
import profile from "../../assets/profile.svg";
import { Card, Drawer } from "antd";
import { AppContext } from "../../context/AppContext";
import DrawerHeader from "../../components/Drawer/DrawerHeader";
import DrawerContent from "../../components/Drawer/DrawerContent";
import DrawerFooter from "../../components/Drawer/DrawerFooter";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import toast from "react-hot-toast";
import MarqueeContainer from "../Marquee/Marquee";
import LogoutModal from "../../components/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "../../Features/Auth/Store/AuthSlice";
import NotificationIcon from "../../Features/Notifications/Components/NotificationIcon";
import { Link } from "react-router-dom";
import MoreItemsIcons from "./MoreItemsIcon";
import {
  getCanSeeNotifications,
  getCanSeeTradeHistory,
} from "../../Features/Access/Store/accessSlice";

const Navbar = () => {
  const isMobileScreen = useDeviceType("mobile");
  const isDesktop = useDeviceType("desktop");
  const isTablet = useDeviceType("tablet");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const disaptch = useDispatch();
  // const { user, clearUser, setUser } = useContext(AppContext);
  const user = JSON.parse(useSelector(getUserId));
  const { confirm } = Modal;

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const canSeeNotifications = useSelector(getCanSeeNotifications);
  const canSeeTradeHistory = useSelector(getCanSeeTradeHistory);
  // const showConfirm = () => {
  //   confirm({
  //     title: "Log Out?",
  //     icon: <ExclamationCircleFilled />,
  //     content: "Are you sure you want to log out",
  //     onOk() {
  //       localStorage.clear();
  //       clearUser();
  //       toast.success("Logged Out");
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };

  const logout = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div className="bg-header-background shadow-sm shadow-indigo-500/40 z-12 relative">
        <div className="flex items-center justify-between gap-[20px] sm:flex-none">
          {isDesktop ? (
            <div className="w-1/5 py-4 px-3">
              <Link to="/">
                <img
                  src={`${AWS_S3_URL}/clients/${AWS_CLIENT_CODE}/logo.png`}
                  alt=""
                  className="w-[200px] h-[50px]"
                />
              </Link>
            </div>
          ) : (
            <>
              <div className="flex flex-col w-full z-30 fixed top-0">
                <div className="flex py-4 items-center bg-white">
                  <img
                    src={hamburger}
                    alt="burger"
                    className="w-[20px] h-[15.5px] ml-6"
                    onClick={toggleDrawer}
                  />
                  <div className={`flex justify-center items-center w-full`}>
                    <Link to="/">
                      <img
                        src={`${AWS_S3_URL}/clients/${AWS_CLIENT_CODE}/logo.png`}
                        alt=""
                        className={`w-[200px] h-[40px] `}
                      />
                    </Link>
                  </div>
                </div>

                <MarqueeContainer />
              </div>
            </>
          )}
          {isDesktop && (
            <div className="flex size-full justify-end items-center pr-10">
              <div className="flex  items-center justify-end w-1/2">
                <div
                  className="py-[7px]  mx-2 w-full  flex flex-col gap-2 items-center justify-center"
                  role="button"
                >
                  <Link
                    to="/"
                    className="flex flex-col justify-start items-center gap-1"
                  >
                    <img
                      src={AWS_S3_URL + "/header/liverate.svg"}
                      alt="location"
                      className="w-[25px] h-[25px]"
                    />
                    <span className="text-[#3D3D3D] text-[10px]">
                      LIVE RATES
                    </span>
                  </Link>
                </div>
                {canSeeTradeHistory ? (
                  <div
                    className="py-[7px] w-full mx-2  flex flex-col items-center gap-2"
                    role="button"
                  >
                    <Link
                      to="/trade"
                      className="flex items-center justify-center flex-col gap-1"
                    >
                      <img
                        src={AWS_S3_URL + "/header/orders.svg"}
                        alt="location"
                        className="w-[25px] h-[25px]"
                      />
                      <span className="text-[#3D3D3D] text-[10px]">TRADES</span>
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
                {canSeeTradeHistory ? (
                  <div
                    className="py-[7px] mx-2 w-full flex flex-col gap-2 items-center"
                    role="button"
                  >
                    <Link
                      to="/pending-orders"
                      className="flex items-center justify-center flex-col gap-1"
                    >
                      <img
                        src={AWS_S3_URL + "/header/pendingorders.svg"}
                        alt="location"
                        className="w-[25px] h-[25px]"
                      />
                    </Link>
                    <span className="text-[#3D3D3D] text-[10px]">LIMIT</span>
                  </div>
                ) : (
                  <></>
                )}
                {canSeeNotifications ? <NotificationIcon /> : <></>}
                <MoreItemsIcons />
                <div className="w-3/4">
                  {user !== null ? (
                    <div
                      className="cursor-pointer w-full"
                      onClick={user ? logout : null}
                    >
                      <div className="text-[#2c3875] text-[13px] font-semibold  gap-1 flex items-center justify-center  w-[140px]">
                        <div className="h-[40px] w-[60px] border-[1px] border-gray-100 rounded-full bg-white flex justify-center items-center">
                          <img
                            src={profile}
                            alt="profile"
                            className="w-[20px] h-[20px] ml-1"
                          />
                        </div>
                        <p className="w-full">
                          {user && user?.user && user?.user?.name}
                        </p>
                        {/* <p className="w-full">Ashish Sood</p> */}
                      </div>
                    </div>
                  ) : (
                    <div className="cursor-pointer">
                      <Link to="/login">
                        <div className="text-[#2c3875] text-[13px] font-semibold leading-4 tracking-wid flex items-center justify-center px-1 gap-1 w-full">
                          <div className="h-[40px] w-[60px] border-[1px] border-gray-100 rounded-full bg-white flex justify-center items-center">
                            <img
                              src={profile}
                              alt="profile"
                              className="w-[20px] h-[20px] ml-1"
                            />
                          </div>

                          <p>LOGIN</p>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Drawer
        title={<DrawerHeader />}
        placement="left"
        closable={false}
        onClose={toggleDrawer}
        open={openDrawer}
        rootStyle={{
          padding: 0,
        }}
        footer={user ? <DrawerFooter /> : null}
        className="custom-drawer"
      >
        <DrawerContent onClose={toggleDrawer} />
      </Drawer>
      <LogoutModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default Navbar;
