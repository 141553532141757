import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Navbar,
  Home,
  Trade,
  Footer,
  Order,
  Login,
  TradeHistory,
  Privacy,
} from "./pages";
import { useDeviceType } from "./utils/useMobile";
import BottomNavbar from "./layouts/Mobile/BottomNavbar";
import Marquee from "./layouts/Marquee/Marquee";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import { useContext, useEffect } from "react";
import socket from "./api/socket";
import { CLIENT_URL, toastSettings } from "./constants";
import { AppContext } from "./context/AppContext";
import ProtectedRoute from "./pages/ProtectedRoute";
import MarqueeContainer from "./layouts/Marquee/Marquee";

function App() {
  const isMobileScreen = useDeviceType("mobile");
  const isDesktop = useDeviceType("desktop");
  const queryClient = new QueryClient();
  const { user } = useContext(AppContext);

  useEffect(() => {
    socket.on("connect", () => {
      socket.emit("store-socket-id", socket.id);
    });
    socket.emit("get-script-type", CLIENT_URL, "homePage");
    socket.on("client-script-types", (data) => {
      if (data?.isSuccess) {
        localStorage.setItem("SCRYT_TYPES", JSON.stringify(data?.data));
      }
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster
        position="top-center"
        containerStyle={{
          top: 80,
        }}
        toastOptions={toastSettings}
      />
      <Navbar />
      {isDesktop && <MarqueeContainer />}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/trade"
            element={<ProtectedRoute element={<Trade />} />}
          />
          <Route
            path="/history"
            element={<ProtectedRoute element={<TradeHistory />} />}
          />
          <Route
            path="/new-order"
            element={<ProtectedRoute element={<Order />} />}
          />
          <Route path="/login" element={user ? <Home /> : <Login />} />
          <Route path="/privacy-policy.html" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
      {isDesktop ? <Footer /> : <BottomNavbar />}
    </QueryClientProvider>
  );
}

export default App;
