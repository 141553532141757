import React from 'react'
import PendingOrdersPage from '../Features/PendingOrders/PendingOrdersPage'


export default function PrendingOrders() {
  return (
    <div>
      <PendingOrdersPage/>
    </div>
  )
}
