import { FaApple, FaGooglePlay } from "react-icons/fa";
import { useClientData } from "./useClientData";
import { Link } from "react-router-dom";
const Footer = () => {
  const { address, phone, email, playStoreLink, appStoreLink } =
    useClientData();
  return (
    <div className="bg-footer-backgound size-full">
      <div className="w-full bg-footer-backgound px-6 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Address Column (Takes Half Width on Desktop) */}
          <div className="flex flex-col justify-between">
            <div>
              <p className="text-xl font-semibold text-[#171659]">ADDRESS</p>
              <p className="mt-3">{address}</p>
            </div>
            {/* Store Links */}
            <div className="flex gap-4 mt-4">
              <a
                href={playStoreLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition"
              >
                <FaGooglePlay size={20} />
                <span className="text-sm">Get it on Google Play</span>
              </a>
              <a
                href={appStoreLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition"
              >
                <FaApple size={20} />
                <span className="text-sm">Download on the App Store</span>
              </a>
            </div>
            {/* Legal Links */}
            <div className="flex flex-wrap mt-4 gap-2 text-xs text-[#302F2F]">
              <p className="cursor-pointer">SITE MAP</p>
              <span>|</span>
              <p className="cursor-pointer">PRIVACY POLICY</p>
              <span>|</span>
              <p className="cursor-pointer">TERMS & CONDITIONS</p>
              <span>|</span>
              <p className="cursor-pointer">FAQs</p>
            </div>
          </div>

          {/* Right Side (Takes Half Space) */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Column 2 - Contact Info */}
            <div className="flex flex-col gap-4">
              <div>
                <p className="text-xl font-semibold text-[#171659]">PHONE</p>
                <div className="flex flex-col gap-1 mt-2">
                  <p>
                    {">"} +{phone}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-xl font-semibold text-[#171659]">EMAIL</p>
                <p className="mt-2">
                  <a
                    href={`mailto:${email}`}
                    className="text-blue-600 hover:underline"
                  >
                    {">"} {email}
                  </a>
                </p>
              </div>
            </div>

            {/* Column 3 - Quick Links */}
            <div>
              <p className="text-xl font-semibold text-[#171659]">
                Quick Links
              </p>
              <ul className="mt-4 space-y-2">
                <li className="cursor-pointer hover:text-blue-600">
                  <Link to={"/"}>{">"} Home</Link>
                </li>

                <li className="cursor-pointer hover:text-blue-600">
                  <Link to={"/contact-us"}>{">"} Contact us</Link>
                </li>
                <li className="cursor-pointer hover:text-blue-600">
                  <Link to={"/trade"}>{">"} Trades</Link>
                </li>
                <li className="cursor-pointer hover:text-blue-600">
                  <Link to={"/pending-orders"}>{">"} Pending Orders</Link>
                </li>
                <li className="cursor-pointer hover:text-blue-600">
                  <Link to={"/bank-details"}>{">"} Bank Details</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="flex h-[40px] bg-custom-gradient size-full justify-between items-center px-10 text-white mt-10">
        <p>Copyrights &copy; 2024. All Rights Reserved</p>
        <p>
          <a href="https://wa.me/919888898866?text=Hello">
            Powered by : Gemlay Software &copy;
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
