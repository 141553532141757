export { default as Home } from "./Home";
export { default as Trade } from "./Trade";
export { default as TradeHistory } from "./TradeHistory";
export { default as Order } from "./Order";
export { default as Login } from "./Login";
export { default as Privacy } from "./Privacy";
export { default as Navbar } from "../layouts/Navbar/Navbar";
export { default as Footer } from "../layouts/Footer/Footer";
export {default as Chart} from "./chart"
export {default as PendingOrders} from "./PrendingOrders"
