import { FaCircleChevronDown } from "react-icons/fa6";
import { FaCircleChevronUp } from "react-icons/fa6";
import gold1 from "../../../../assets/cards/bars 1.png";
import gold2 from "../../../../assets/cards/gold 3.png";
import goldRate from "../../../../assets/cards/golddollar.png";
import dollar from "../../../../assets/cards/dollar.png";
import silverRate from "../../../../assets/cards/silverdollar.png";
import { usePriceFetcher } from "../../../LivePrice/Hooks/usePriceFetcher";
import { useClientRootScripts } from "../../Hooks/useClientRootScripts";
const MobileRootCards = () => {
  const { data } = useClientRootScripts();
  return (
    <div className="flex justify-between items-center gap-2 py-3 relative ">
      {data?.map((item, index) => {
        return <MobileRootItem item={item} key={index} />;
      })}
    </div>
  );
};

const MobileRootItem = ({ item }) => {
  const images = {
    "GOLD ($)": goldRate,
    "SILVER ($)": silverRate,
    "INR (₹)": dollar,
    GOLD: gold2,
    SILVER: gold1,
  };
  const { price, color, percentage, diff, percentageColor } = usePriceFetcher({
    refer: item?.rootScript,
    multiplier: item?.buyMultiplier,
    addition: item?.buyAdditive,
    type: "buy",
  });
  return (
    <div className="w-full h-[120px] overflow-auto flex justify-center items-center flex-col shadow-md bg-white rounded-xl ">
      <div className="w-[40px] h-[40px] border-[1px] border-solid rounded-full absolute top-[-5px] bg-white z-10 flex items-center justify-center">
        <img
          src={
            images[item?.title]
              ? images[item?.title]
              : item?.title?.includes("GOLD")
                ? gold2
                : silverRate
          }
          alt="Gold Icon"
          className="w-[22px] pb-2"
        />
      </div>
      <p className="text-[12px] mt-4">{item?.title?.toUpperCase()}</p>

      <p
        style={{
          color: `${color}`,
          transition: "color 0.1s ease-in-out",
          fontSize: `13px`,
          fontWeight: "bold",
        }}
      >
        {price && parseFloat(price).toFixed(1) % 1 !== 0
          ? parseFloat(price).toFixed(1)
          : parseFloat(price).toFixed(0)}
      </p>

      <div className="flex  justify-center  items-center py-1 bg-[#FFEBEB]  w-[100%] px-4 rounded-[30px] my-1 gap-1 mt-3">
        <p className="text-[6px] flex flex-col items-center justify-center">
          <span>
            {diff > 0 && "+"}
            {diff?.toFixed(2)}
          </span>
          <span>
            ({percentage > 0 && "+"}
            {percentage?.toFixed(2)}%)
          </span>
        </p>
        {/* {percentageColor === "red" ? ( */}
        {/*   <FaCircleChevronDown style={{ color: "red", fontSize: "24px" }} /> */}
        {/* ) : ( */}
        {/*   <FaCircleChevronUp style={{ color: "green", fontSize: "24px" }} /> */}
        {/* )} */}
      </div>
    </div>
  );
};

export default MobileRootCards;
