import React from "react";
import useOrder from "./hooks";
import { useDeviceType } from "../../utils/useMobile";

const OrderPage = () => {
  const {
    dataRef: liverates,
    qtyData,
    form,
    isLoading,
    isFetched,
    handleChange,
    handleChangeLimitType,
    handleSubmit,loader
  } = useOrder();

  console.log(liverates, "scId4", isFetched);

  const isTablet = useDeviceType("tablet");

  return (
    <div
      className={`h-full w-full bg-white flex justify-center items-start py-10 ${
        isTablet ? "mt-64" : ""
      }`}
    >
      <div className="flex border-2 border-primary-700 py-3 w-[370px] mt-48 md:mt-12 rounded-[20px] bg-row-background">
        <div className="flex flex-col justify-start items-center w-full gap-2 my-10">
          <p className="">{liverates?.current?.product}</p>
          <div className="flex py-1 px-4 mb-4">
            <div
              className={`px-8 py-1 rounded-l-xl cursor-pointer ${
                form?.limitType == "market" ? "bg-custom-gradient" : "bg-white"
              }`}
              onClick={() => {
                handleChangeLimitType("market");
              }}
            >
              <p
                className={` ${
                  form?.limitType == "market" ? "text-white" : ""
                }`}
              >
                MARKET
              </p>
            </div>
            <div
              className={`px-8 py-1 rounded-r-xl cursor-pointer ${
                form?.limitType == "pending" ? "bg-custom-gradient" : "bg-white"
              }`}
              onClick={() => {
                handleChangeLimitType("pending");
              }}
            >
              <p
                className={` ${
                  form?.limitType == "pending" ? "text-white" : ""
                }`}
              >
                PENDING
              </p>
            </div>
          </div>
          <div className="flex w-full justify-between items-center px-16 mb-4">
            <div className="flex flex-col items-center">
              <p>BID</p>
              <p className="text-red-500 text-[20px]">
                {liverates?.current?.buy != "-"
                  ? liverates?.current?.buy
                  : liverates?.current?.sell}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <p>ASK</p>
              <p className="text-green-800 text-[20px]">
                {liverates?.current?.sell}
              </p>
            </div>
          </div>
          <div className="flex flex-col w-full items-center justify-center px-16 gap-3">
            <div className="flex items-center justify-between gap-3 w-full">
              <p className="text-[14px]">QUANTITY:- </p>
              <select
                className="py-1 px-4 self-center bg-white rounded-2xl pr-8"
                name="quantity"
                value={form?.quantity}
                onChange={handleChange}
              >
                <option value={""}>{"select"}</option>
                {qtyData?.map((qty, index) => (
                  <option key={index} value={qty?.value}>
                    {qty?.label}
                  </option>
                ))}
              </select>
            </div>
            {form?.limitType == "pending" && (
              <div className="flex items-center justify-between w-full">
                <p className="text-[14px]">PRICE:- </p>
                <input
                  type="text"
                  className="py-[4px] px-4 bg-white rounded-2xl w-[130px] pl-8"
                  placeholder="Enter"
                />
              </div>
            )}
          </div>
          {!loader &&
          <div className="flex w-full justify-between items-center px-20 mt-8">
            <button
              className="px-6 py-2 bg-[#FF5353] text-white rounded-xl"
              onClick={() => {
                handleSubmit("sell", liverates?.current?.sell);
              }}
            >
              SELL
            </button>
            <button
              className="px-6 py-2 bg-[#008D17] text-white rounded-xl"
              onClick={() => {
                handleSubmit(
                  "buy",
                  liverates?.current?.buy != "-"
                    ? liverates?.current?.buy
                    : liverates?.current?.sell
                );
              }}
            >
              BUY
            </button>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
