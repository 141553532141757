import { useState } from "react";
import { useGetPendingOrdersQuery } from "../Store/pendingOrderApiSlice";

export const usePendingOrdersHook = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const {
    data: { data = [], page = 1, pages = 1 } = {},
    error,
    isLoading,
  } = useGetPendingOrdersQuery({ pageNumber: currentPage, pageSize });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return {
    data,
    currentPage,
    pages,
    handlePageChange,
  };
};
