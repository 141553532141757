import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../../api/axiosBaseQuery";
const user = JSON.parse(localStorage.getItem("LoggedInUser"));
const pendingOrdersApiSlice = createApi({
  reducerPath: "pendingOrder",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPendingOrders: builder.query({
      query: ({ pageSize, pageNumber }) => ({
        url: "/trade",
        method: "GET",
        params: {
          pagination: true,
          userId: user?.userId,
          pageNumber,
          status: "onwatching",
          pageSize,
        },
      }),
      transformResponse: (response) => response,
      providesTags: ["pendingOrders"],
    }),
    updatePendingOrders: builder.mutation({
      query: ({ id, data }) => ({
        url: `/trade/update/${id}`,
        method: "PUT",
        data: data,
      }),
      async onQueryStarted({ id, data }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            pendingOrdersApiSlice.util.invalidateTags(["PendingOrders"]),
          );
        } catch (err) {
          console.log(err);
        }
      },
      invalidatesTags: ["pendingOrders"],
    }),
  }),
});

export const { useGetPendingOrdersQuery, useUpdatePendingOrdersMutation } =
  pendingOrdersApiSlice;
export default pendingOrdersApiSlice;
