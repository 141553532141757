import { useAuthHook } from "../Hooks/useAuthHook"
import ResendOtpTimmer from "./ResendOtpTimmer"

function LoginPage() {
const startingMobile="+91 "
const {setName,
  otp,
    showOtp,
    handleMobileNumberChange,
    handleOtpChange,
    phoneNumber,
    lodingLogin,
    loadingOtp,
    handleSubmit,
    handleResendOtp,
    }=useAuthHook()
  return (
    <div className="w-full h-screen justify-center items-center flex flex-col relative">
      <div className="w-350  flex flex-col justify-center items-center  my-auto">
        <div className="text-lg font-bold text-[#002433]">LOGIN / SIGNUP</div>
          <input placeholder="Name" disabled={showOtp} className="text-sm h-[45px] mt-5 border-2 w-full border-[#E6E6E6] text-[#302F2F] rounded-3xl px-5 p-1 !outline-none" onChange={(e)=>setName(e.target.value)}/>
          <div className="relative mt-5 w-full">
      <span class="absolute inset-y-0 left-0 pr-2 border-r flex items-center pl-3 my-2">
        <img src={"/India.svg"} alt="india" className="h-5 w-5"/>
      </span>
      <input 
      disabled={showOtp}
        type="text" 
        class="pl-12 pr-10 text-sm h-[45px] border-2 w-full border-[#E6E6E6] text-[#302F2F] rounded-3xl px-3 p-1 !outline-none"
        placeholder="Mobile Number"
        value={phoneNumber?startingMobile+phoneNumber:startingMobile}
        onChange={handleMobileNumberChange}
      />
      {phoneNumber.length==10 &&<span class="absolute inset-y-0 right-0 flex items-center pr-3">
      <img src={"/Check.svg"} alt="check" className="h-5 w-5"/>
      </span>}
      
    </div>

        <div className="self-end text-right text-xs mt-2  text-[#222C85] hover:cursor-pointer">Change Number</div>
        
        {showOtp &&(
            <>
            <input placeholder="OTP"  className="text-sm h-[45px] mt-5 border-2 w-full border-[#E6E6E6] text-[#302F2F] rounded-3xl px-5 p-1 !outline-none" value={otp} onChange={handleOtpChange}/>
            <ResendOtpTimmer handleResent={handleResendOtp}/>
            </>
        )
        }
        
        <div className="text-[#747070] mt-5 text-xs">By continuing,I agree to <a className="text-[#012433] hover:cursor-pointer">Terms Of Use</a> & <a className="text-[#012433] hover:cursor-pointer">Privacy Policy</a></div>
        <button className="border border-red w-full my-2 p-2 text-white bg-black rounded-3xl my-5" onClick={handleSubmit} disabled={loadingOtp || lodingLogin}>{showOtp?"Submit":"Get Otp"}</button>
        <div className="mt-2 flex justify-center items-center mx-3">
        <div class="flex-grow border-t border-black"></div>or
        <div class="flex-grow border-t border-black"></div>
        </div>
        <div className="text-[#747070] text-xs">Having touble logged in? <a className="text-[#012433] hover:cursor-pointer">Get help</a></div>
      </div>
      <div className="absolute bottom-0 left-0">
      <img src={"/Login1.svg"} alt="semi circle" width={300} height={300}/>
      </div>
      <div className="absolute bottom-0 right-0 flex">
      <img src={"/Login3.svg"} alt="flower" width={200} height={200}/>
      <div className="absolute bottom-0 right-150">
      <img src={"/Login2.svg"} alt="ring" width={200} height={200}/>
      </div>
      </div>
      </div>
  )
}

export default LoginPage
