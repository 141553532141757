import React, { useState } from "react";
import { useDeviceType } from "../../utils/useMobile";
import useLivePrice from "./hooks";
import MainScreen from "./Screens/Desktop/MainScreen";
import MobileHome from "./MobileHome";

const HomePage = () => {
  const isMobileScreen = useDeviceType("mobile");
  const isDesktop = useDeviceType("desktop");
  const isTablet = useDeviceType("tablet");
  const {
    isLoading,
    count,
    handleColor,
    dataRef,
    handleSCode,
    goldCoinRate,
    activeTab,
    toggleTab,
    clickable,
    hlColor,
    isSkeleton,
    handleSkeleton,
    handleSocket,
    tradeChecker,dataRefOld,
    oldRateval,
rateval
  } = useLivePrice();

  const scriptTypes = JSON.parse(localStorage.getItem("SCRYT_TYPES"));
  const userInfo = JSON.parse(localStorage.getItem("LoggedInUser"));

  console.log(scriptTypes, "scryptTYypes", dataRef.current, isTablet);

  return (
    <>
      <>
        {isDesktop && (
          <div className="">
            <div className="flex h-full bg-[#F3F3F3]">
              <div className="w-3/4 px-6 py-6">
                <div className="flex border-[1px] justify-around px-6 rounded-full border-gray-200 py-2 bg-white shadow-sm">
                  {scriptTypes?.map((script, index) => {
                    if (script?.active) {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className="size-full flex flex-col items-center justify-center gap-1"
                            onClick={() => {
                              handleSkeleton();
                              if (clickable) {
                                toggleTab(script?.title);
                              }
                            }}
                          >
                            <p
                              className={`
                      activeTab == "gold" ? "text-color-primary" : "text-black"
                    } cursor-pointer`}
                            >
                              {script?.title?.toUpperCase()}
                            </p>
                            {activeTab == script?.title && (
                              <div className="w-[90px] bg-slate-700 h-[2px]"></div>
                            )}
                          </div>
                          {index !== scriptTypes?.length - 1 && (
                            <div className="h[4px] w-[2px] bg-slate-800"></div>
                          )}
                        </React.Fragment>
                      );
                    }
                  })}
                </div>
                <>


                  <MainScreen
                    handleColor={handleColor}
                    liverates={rateval}
                    oldrates={oldRateval}
                    isSkeleton={isSkeleton}
                    handleSCode={handleSCode}
                    handleSocket={handleSocket}
                    activeTab={activeTab}
                    tradeChecker={tradeChecker}
                  />
                </>
              </div>

              <div className="w-1/4">
                {userInfo && (
                  <div className="h-1/3 mt-24 mr-3">
                    <div className="flex flex-col rounded-[20px]">
                      <div className="bg-custom-gradient h-[40px] flex items-center justify-center rounded-t-xl">
                        <p className="text-white">TRADING TERMINAL</p>
                      </div>
                      <div className="h-[200px] bg-white flex flex-col items-start justify-center rounded-b-[20px]">
                        <div className="flex gap-3 w-full px-4 items-center">
                          <label htmlFor="" className="text-[14px]">
                            Total Margin :
                          </label>
                          <div className="bg-white w-2/3 border-2 rounded-lg h-[30px]">
                            <p className="flex items-center justify-center">
                              {userInfo?.user?.custLimit}
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="flex gap-3 w-full px-4 items-center">
                          <label htmlFor="" className="text-[14px]">
                            Used Margin:
                          </label>
                          <div className="bg-white w-2/3 border-2 rounded-lg h-[30px]">
                            <p className="flex items-center justify-center">
                              {userInfo?.user?.usedLimit}
                            </p>
                          </div>
                        </div>
                        <br />
                        <div className="flex gap-1 w-full px-4 items-center">
                          <label className="text-[12px]" htmlFor="">
                            Pending Margin :
                          </label>
                          <div className="bg-white w-2/3 border-2 rounded-lg h-[30px]">
                            <p className="flex items-center justify-center">
                              {Number(userInfo?.user?.custLimit) -
                                Number(userInfo?.user?.usedLimit)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="h-full flex flex-col mr-4 mt-5 mb-5">
                  <div className="bg-custom-gradient flex items-center justify-center h-[40px] rounded-t-[20px]">
                    <p className="text-lg text-white">NEWS & EVENTS</p>
                  </div>
                  <div
                    className="flex items-start bg-white rounded-b-[20px] p-4
            "
                  >
                    <p>
                      Gemlay Bullion is a leading bullion dealer in Mohali,
                      Punjab. Gemlay was established in 2012. We are Fatehgarh
                      Sahib most trusted Gold and Silver supplier. Gemlay
                      Bullion has completed more than a decade in the Bullion
                      Industry. This app would help users to get the live rate
                      in Mohali with trading feature for the valued registered
                      customer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isMobileScreen && (
          <MobileHome
            handleColor={handleColor}
            handleSCode={handleSCode}
            activeTab={activeTab}
            toggleTab={toggleTab}
            dataRef={dataRef}
            clickable={clickable}
            liverates={rateval}
            handleSocket={handleSocket}
          />
        )}
        {isTablet && (
          <div className="flex flex-col w-full h-full px-12 py-3 mt-44">
            <div className="flex mx-4 w-full border border-gray-300 rounded-2xl shadow-sm bg-white py-3">
              {scriptTypes?.map((script, index) => {
                if (script?.active) {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className="size-full flex flex-col items-center justify-center gap-1"
                        onClick={() => {
                          handleSkeleton();
                          if (clickable) {
                            toggleTab(script?.title);
                          }
                        }}
                      >
                        <p
                          className={`
                      activeTab == "gold" ? "text-color-primary" : "text-black"
                    } cursor-pointer`}
                        >
                          {script?.title?.toUpperCase()}
                        </p>
                        {activeTab == script?.title && (
                          <div className="w-[90px] bg-slate-700 h-[2px]"></div>
                        )}
                      </div>
                      {index !== scriptTypes?.length - 1 && (
                        <div className="h[4px] w-[2px] bg-slate-800"></div>
                      )}
                    </React.Fragment>
                  );
                }
              })}
            </div>
            <MainScreen
              handleColor={handleColor}
              liverates={dataRef.current}
              isSkeleton={isSkeleton}
              handleSCode={handleSCode}
              handleSocket={handleSocket}
              activeTab={activeTab}
              tradeChecker={tradeChecker}
            />
          </div>
        )}
      </>
    </>
  );
};

export default HomePage;
