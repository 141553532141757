import { api, apiEndPoints } from "../../../src/api/index";

export const getTrade = async (payload) => {
  console.log(payload?.userId, "custId");

  const response = await api.get(apiEndPoints.trade, {
    params: {
      pagination: payload?.pagination,
      pageSize: payload?.pageSize,
      pageNumber: payload?.pageNumber,
      customerId: payload?.userId,
      status: payload?.status,
    },
  });
  return response;
};

export const getCustData = async (no, sc) => {
  const response = await api.get(apiEndPoints.liveRatesCScript, {
    params: {
      no: no,
      sc: sc,
    },
  });
  return response;
};
export const getScriptQty = async (no, sc) => {
  const response = await api.get(apiEndPoints.liveRatesCScriptQty, {
    params: {
      no: no,
      sc: sc,
    },
  });
  return response;
};
export const GetClientScript = async () => {
  const response = await api.get(apiEndPoints.clientScript, {
    params: {
      pagination: false,
      clientId: "66b4c365fc09db0bb45190b5",
      admin: true,
    },
  });
  return response;
};
