import { Pagination } from "@heroui/pagination";

export default function FooterPagination({
  initialPage,
  currentPage,
  total,
  onChange,
}) {
  return (
    total > 1 && (
      <Pagination
        size="sm"
        showShadow
        showControls
        variant="bordered"
        onChange={onChange}
        initialPage={initialPage}
        current={currentPage}
        total={total}
        className={{}}
      />
    )
  );
}
