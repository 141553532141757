import React from "react";

const CardSkeleton = () => {
  return (
    <div className="flex flex-col gap-2 mt-5">
      <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
      <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
      <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
      <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
    </div>
  );
};

export default CardSkeleton;
