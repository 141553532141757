import "./App.css";
import tinycolor from "tinycolor2";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Navbar,
  Home,
  Trade,
  Footer,
  Order,
  Login,
  TradeHistory,
  Privacy,
  Chart,
  PendingOrders,
} from "./pages";
import { useDeviceType } from "./utils/useMobile";
import BottomNavbar from "./layouts/Mobile/BottomNavbar";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import { useContext, useEffect } from "react";
import { CLIENT_URL, toastSettings } from "./constants";
import { AppContext } from "./context/AppContext";
import ProtectedRoute from "./pages/ProtectedRoute";
import MarqueeContainer from "./layouts/Marquee/Marquee";
import { api, apiEndPoints } from "./api";
import AddAlert from "./Features/Alerts/Components/AddAlert";
import FloatingWhatsApp from "./components/FloatingWhatsApp/FloatingWhatsApp";
import { useDispatch, useSelector } from "react-redux";
import { getUserId, setUserId } from "./Features/Auth/Store/AuthSlice";
import FullNewsPage from "./Features/News/Components/FullNewsPage";
import ChattingPage from "./Features/Chatting/Components/ChattingPage";
import NotificationScreen from "./Features/Notifications/Components/NotificationScreen";
import NotificationCountFetcher from "./Features/Notifications/Components/NotificationCountFetcher";
import NotificationPanel from "./Features/Notifications/Components/NotificationPanel";
import BankDetails from "./container/BankDetails/BankDetails";
import ContactUs from "./container/ContactUs/ContactUs";
import BookingDesk from "./container/BookingDesk/BookingDesk";
import TdsTcsCalculator from "./container/TdsCalculator";
import FetchLiveRates from "./Features/LivePrice/Components/FetchLivePrices";
import HomePage from "./Features/Home/Components/HomePage";
import { fetchScriptTypes } from "./Features/Home/State/homePageSlice";
import { setClientData } from "./Store/clientSlice";
import AccessFetch from "./Features/Access/Components/AccessFetcher";
import AccessControlRoute from "./Features/Access/Components/AccessControlRoute";
import { getCanSeeNotifications } from "./Features/Access/Store/accessSlice";
import { HeroUIProvider } from "@heroui/system";
import TermsAndConditions from "./components/PrivacyPolicy/TermsAndConditions";

function App() {
  const isMobileScreen = useDeviceType("mobile");
  const isDesktop = useDeviceType("desktop");
  const queryClient = new QueryClient();
  console.log("theme of the client", CLIENT_URL);
  const user = useSelector(getUserId);
  const dispatch = useDispatch();
  const canSeeNotification = useSelector(getCanSeeNotifications);
  //Theme fetcher
  useEffect(() => {
    api
      .get(apiEndPoints.getStoreTheme(CLIENT_URL))
      .then((theme) => {
        const color = theme?.data?.adminPanelColor;
        console.log("client data", theme?.data);
        dispatch(setClientData(theme?.data));
        if (!color) return;
        document.documentElement.style.setProperty("--primary-color", color);
        document.documentElement.style.setProperty(
          "--secondary-color",
          tinycolor(color).lighten(15).toString(),
        );
      })
      .catch((err) => console.log("theme of the client", err));
    const id = localStorage.getItem("LoggedInUser");
    dispatch(fetchScriptTypes());
    id && dispatch(setUserId(id));
  }, []);

  //<-----------------------For chatting feature---------------------------------->

  //   useEffect(()=>{
  //     const token=localStorage.getItem('BEARER_TOKEN')
  // const chattingSokcet=io.connect(`${API_BASE_URL}messaging`, {query:{token}}
  // )
  //     chattingSokcet.on('connect',()=>{console.log('connected to server websocker')})
  //     chattingSokcet.on('totalUnReadMessages',(data)=>{console.log('connected to server websocker',data)})
  //     chattingSokcet.on("conversationId",(data)=>{console.log('connected to server websocker',data)})
  //     chattingSokcet.on("sendMessageSuccessfull",(data)=>{console.log('connected to server websocker',data)})
  //     setTimeout(()=>{
  //       chattingSokcet.emit("sendMessage","hello")
  //     },1000)

  //   },[])

  return (
    <QueryClientProvider client={queryClient}>
      <HeroUIProvider>
        <AccessFetch />
        <FetchLiveRates />
        {/* To fetch live root scirpt rates */}
        {canSeeNotification ? <NotificationCountFetcher /> : <></>}
        {/* to update the count notification count and does not cause re-render to other components */}
        <Toaster
          position="top-center"
          containerStyle={{
            top: 80,
          }}
          toastOptions={toastSettings}
        />

        <BrowserRouter>
          <Navbar />
          {isDesktop && <MarqueeContainer />}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/trade"
              element={
                <ProtectedRoute
                  element={
                    <AccessControlRoute
                      element={<Trade />}
                      requiredPermission={"margin"}
                    />
                  }
                />
              }
            />
            <Route
              path="/history"
              element={
                <ProtectedRoute
                  element={
                    <AccessControlRoute
                      element={<TradeHistory />}
                      requiredPermission={"tradeHistory"}
                    />
                  }
                />
              }
            />
            <Route
              path="/new-order"
              element={
                <ProtectedRoute
                  element={
                    <AccessControlRoute
                      element={<Order />}
                      requiredPermission={"margin"}
                    />
                  }
                />
              }
            />
            <Route
              path="/chart"
              element={
                <ProtectedRoute
                  element={
                    <AccessControlRoute
                      element={<Chart />}
                      requiredPermission={"chartsNews"}
                    />
                  }
                />
              }
            />
            <Route
              path="/news"
              element={
                <AccessControlRoute
                  element={<FullNewsPage />}
                  requiredPermission={"chartsNews"}
                />
              }
            />
            {/* <Route */}
            {/*   path="/message" */}
            {/*   element={<ProtectedRoute element={<ChattingPage />} />} */}
            {/* /> */}
            <Route
              path="/notifications"
              element={
                <ProtectedRoute
                  element={
                    <AccessControlRoute
                      element={<NotificationScreen />}
                      requiredPermission={"notifications"}
                    />
                  }
                />
              }
            />
            <Route
              path="/bank-details"
              element={
                <ProtectedRoute
                  element={
                    <AccessControlRoute
                      element={<BankDetails />}
                      requiredPermission={"bankDetails"}
                    />
                  }
                />
              }
            />
            <Route
              path="/contact-us"
              element={
                <ProtectedRoute
                  element={
                    <AccessControlRoute
                      element={<ContactUs />}
                      requiredPermission={"contactUs"}
                    />
                  }
                />
              }
            />
            <Route
              path="/booking-desk"
              element={
                <ProtectedRoute
                  element={
                    <AccessControlRoute
                      element={<BookingDesk />}
                      requiredPermission={"bookingDesk"}
                    />
                  }
                />
              }
            />
            <Route
              path="/tds-calculator"
              element={
                <AccessControlRoute
                  element={<TdsTcsCalculator />}
                  requiredPermission={"tdsCalculator"}
                />
              }
            />
            {/* <Route path="/testing" element={<HomePage />} /> */}
            <Route
              path="/pending-orders"
              element={
                <ProtectedRoute
                  element={
                    <AccessControlRoute
                      element={<PendingOrders />}
                      requiredPermission={"tradeHistory"}
                    />
                  }
                />
              }
            />
            <Route
              path="/alert"
              element={
                <ProtectedRoute
                  element={
                    <AccessControlRoute
                      element={<AddAlert />}
                      requiredPermission={"priceAlerts"}
                    />
                  }
                />
              }
            />
            <Route path="/login" element={user ? <Home /> : <Login />} />
            <Route path="/privacy-policy.html" element={<Privacy />} />
            <Route
              path="/terms-and-conditions.html"
              element={<TermsAndConditions />}
            />
          </Routes>
          {canSeeNotification ? <NotificationPanel /> : <></>}
          {isDesktop ? <Footer /> : <BottomNavbar />}
        </BrowserRouter>
        {isDesktop && <FloatingWhatsApp />}
      </HeroUIProvider>
    </QueryClientProvider>
  );
}

export default App;
