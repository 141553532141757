import React, { useEffect } from "react";

const TrendPredictionCard = ({ symbol, type }) => {
  useEffect(() => {
    const existingContainer = document.querySelector(
      `.tradingview-widget-container__widget_${type}`,
    );
    if (existingContainer) {
      existingContainer.innerHTML = ""; // Clear the previous widget content
    }

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      interval: "1M",
      width: "100%",
      isTransparent: false,
      height: "100%",
      symbol,
      showIntervalTabs: true,
      displayMode: "single",
      locale: "en",
      colorTheme: "light",
    });

    const container = document.querySelector(
      `.tradingview-widget-container__widget_${type}`,
    );
    if (container) {
      container.appendChild(script);
    }

    return () => {
      if (container && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [symbol, type]);

  return (
    <div className="h-[370px] w-full rounded-xl  overflow-auto mr-3 mt-4 mb-4  min-h-[370px] ">
      <div className="tradingview-widget-container h-full">
        <div
          className={`tradingview-widget-container__widget_${type} h-full`}
        />
      </div>
    </div>
  );
};

export default TrendPredictionCard;
