import React from "react";
import LoginPage from "../container/Auth/Login";

const Login = () => {
  return (
    <div>
      <LoginPage />
    </div>
  );
};

export default Login;
