import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchAccess } from "../Store/accessSlice";

function AccessFetch() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAccess());
  }, [dispatch]);
  return <></>;
}

export default AccessFetch;
