import { api, apiEndPoints } from "../../../src/api/index";

export const getlivePrice = async (no) => {
  const response = await api.get(apiEndPoints.liveRatesBullion, {
    params: {
      pagination: false,
      no: no,
    },
  });
  return response;
};
export const getLoggedUser = async (id) => {
  if (id) {
    const response = id && (await api.get(apiEndPoints.customer(id)));
    return response;
  }
};
export const CreateOrder = async (payload) => {
  const response = await api.post(apiEndPoints.trade, payload);
  return response;
};
export const getSingleScriptPrice = async (no, sc) => {
  const response = await api.get(apiEndPoints.liveRatesCScript, {
    params: {
      no: no,
      sc: sc,
    },
  });
  return response;
};
export const getScriptQty = async (no, sc) => {
  console.log("scId66", sc);

  const response = await api.get(apiEndPoints.liveRatesCScriptQty, {
    params: {
      no: no,
      sc: sc,
    },
  });
  return response;
};

export const getScriptByCode = async (code) => {
  const { data } = await api.get("/client-script/script-code", {
    params: { code },
  });
  console.log("data", data);
  return data;
};

export const GetClientScript = async () => {
  const response = await api.get(apiEndPoints.clientScript, {
    params: {
      pagination: false,
      clientId: "66b4c365fc09db0bb45190b5",
      admin: true,
    },
  });
  return response;
};
