import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUpdateViewedStatusMutation } from "../Store/notificationApiSlice";
import {
  fetchNotificationCount,
  setNotificationPanel,
} from "../Store/notificationSlice";

export const useNotificatoinCardHook = (item) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateViewdStatus, {}] = useUpdateViewedStatusMutation();
  const timeAgo = (createdAt) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(createdAt)) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds}s ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}min ago`;
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)}h ago`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 86400)}d ago`;
    return `${Math.floor(diffInSeconds / 2592000)}mo ago`;
  };

  const handleNotificationClick = async () => {
    navigate(item.url);
    await updateViewdStatus(item._id).unwrap();
    dispatch(fetchNotificationCount());
    dispatch(setNotificationPanel(false));
  };
  return { handleNotificationClick, timePassed: timeAgo(item.createdAt) };
};

