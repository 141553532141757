import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, apiEndPoints } from "../../../api";

const initialState = {
  gold: {
    limit: 0,
    used: 0,
  },
  silver: {
    limit: 0,
    used: 0,
  },
};

export const fetchUserLimits = createAsyncThunk(
  "userLimit/fetchUserLimits",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.get(apiEndPoints.customer(id));
      return data?.storeEmployee || {};
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

const limitSlice = createSlice({
  name: "userLimit",
  initialState,
  reducers: {
    // getLimitByName: (state, { payload }) => {
    //   return state[payload];
    // },
    resetLimit: (state, { payload }) => {
      state.gold.limit = 0;
      state.gold.used = 0;
      state.silver.limit = 0;
      state.silver.used = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserLimits.fulfilled, (state, { payload }) => {
      state.gold.limit = payload?.custGoldLimit || 0;
      state.gold.used = payload?.usedGoldLimit || 0;
      state.silver.limit = payload?.custSilverLimit || 0;
      state.silver.used = payload?.usedSilverLimit || 0;
    });
  },
});

export const getGoldLimit = (state) => state.limit.gold;
export const getSilverLimit = (state) => state.limit.silver;

export const { getLimitByName, resetLimit } = limitSlice.actions;

export default limitSlice.reducer;

