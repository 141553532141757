import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AccessControlRoute = ({
  element,
  requiredPermission,
  redirectTo = "/",
}) => {
  const userAccess = useSelector((state) => state.access);
  console.log("user permission is", userAccess[requiredPermission]);
  if (userAccess.isLoading) return <></>;
  if (!userAccess[requiredPermission]) {
    return <Navigate to={redirectTo} replace />;
  }

  return element;
};

export default AccessControlRoute;
