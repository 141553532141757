import React, { useContext } from "react";
import profile from "../../assets/drawerProfile.png";
import { AppContext } from "../../context/AppContext";
import { AWS_S3_URL } from "../../constants";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getCanSeeBankDetails,
  getCanSeeBookingDesk,
  getCanSeeChartsNews,
  getCanSeeContactUs,
  getCanSeeLiveRates,
  getCanSeeNotifications,
  getCanSeePriceAlerts,
  getCanSeeTdsCalculator,
  getCanSeeTradeHistory,
} from "../../Features/Access/Store/accessSlice";
import chartSvg from "../../assets/chart.svg";
import bankDetailsSvg from "../../assets/bankDetials.svg";
import contactUsSvg from "../../assets/contact-us.svg";
import bookingDeskSvg from "../../assets/bookingDesk.svg";
import alertSvg from "../../assets/alert.svg";
import calculatorSvg from "../../assets/calculator.svg";
import newsSvg from "../../assets/news.svg";
import liveRateSvg from "../../assets/liveRates.svg";
import tradeHistorySvg from "../../assets/tradeHistory.svg";
import pendingOrdersSvg from "../../assets/pendingOrders.svg";
import notificationSvg from "../../assets/notifications.svg";
const DrawerContent = ({ onClose }) => {
  const { user } = useContext(AppContext);
  const bankDetails = useSelector(getCanSeeBankDetails);
  const contactUs = useSelector(getCanSeeContactUs);
  const bookingDesk = useSelector(getCanSeeBookingDesk);
  const tdsCalculator = useSelector(getCanSeeTdsCalculator);
  const alert = useSelector(getCanSeePriceAlerts);
  const chart = useSelector(getCanSeeChartsNews);
  const news = useSelector(getCanSeeChartsNews);
  const notifications = useSelector(getCanSeeNotifications);
  const trades = useSelector(getCanSeeTradeHistory);
  const liveRate = useSelector(getCanSeeLiveRates);
  const list = [
    liveRate && {
      to: "/",
      title: "Live Rates",
      icon: (
        <img src={liveRateSvg} alt="LiveRates" className="h-[18px] w-[18px]" />
      ),
    },
    trades && {
      to: "/trade",
      title: "Trades",
      icon: (
        <img src={tradeHistorySvg} alt="Trades" className="h-[18px] w-[18px]" />
      ),
    },
    trades && {
      to: "/pending-orders",
      title: "Pending Orders",
      icon: (
        <img
          src={pendingOrdersSvg}
          alt="Pending Trades"
          className="h-18px w-18px"
        />
      ),
    },
    notifications && {
      to: "/notifications",
      title: "Notifications",
      icon: (
        <img
          src={notificationSvg}
          alt="Notifications"
          className="h-[18]px w-[18px]"
        />
      ),
    },
    bankDetails && {
      to: "/bank-details",
      title: "Bank Details",
      icon: (
        <img
          src={bankDetailsSvg}
          alt="bankDetials"
          className="h-[18px] w-[18px]"
        />
      ),
    },
    contactUs && {
      to: "/contact-us",
      title: "Contact Us",
      icon: (
        <img src={contactUsSvg} alt="chart" className="h-[18px] w-[18px]" />
      ),
    },
    bookingDesk && {
      to: "/booking-desk",
      title: "Booking Desk",
      icon: (
        <img src={bookingDeskSvg} alt="chart" className="h-[18px] w-[18px]" />
      ),
    },
    tdsCalculator && {
      to: "/tds-calculator",
      title: "Tds Calculator",
      icon: (
        <img src={calculatorSvg} alt="chart" className="h-[18px] w-[18px]" />
      ),
    },
    alert && {
      to: "/alert",
      title: "Alert",
      icon: <img src={alertSvg} alt="chart" className="h-[18px] w-[18px]" />,
    },
    chart && {
      to: "/chart",
      title: "Chart",
      icon: <img src={chartSvg} alt="chart" className="h-[18px] w-[18px]" />,
    },
    news && {
      to: "/news",
      title: "News",
      icon: <img src={newsSvg} alt="chart" className="h-[18px] w-[18px]" />,
    },
  ].filter(Boolean);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="flex w-full bg-custom-gradient h-[60px] items-center justify-start px-6 gap-3">
        <img src={profile} alt="profile" className="h-[30px] w-[30px]" />
        <div className="">
          {user ? (
            <p className="text-white text-xl font-semibold">
              Hi, {user?.user?.name}
            </p>
          ) : (
            <div className="">
              <a href="/login" className="text-white text-xl font-semibold">
                LOGIN
              </a>
            </div>
          )}
        </div>
      </div>
      <div
        className="flex flex-col justify-start
       items-start w-full px-6"
      >
        {list?.map((item, index) => {
          return (
            <div className="border-b-1 w-full py-2">
              <Link
                to={item?.to}
                className="flex  justify-start items-center gap-4 w-full py-2"
                onClick={onClose}
              >
                {item.icon}
                <span className="text-[#3D3D3D] text-[15px] ">
                  {item?.title}
                </span>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DrawerContent;
