import React, { useEffect, useState } from "react";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaArrowAltCircleDown } from "react-icons/fa";

import { BsPatchCheckFill } from "react-icons/bs";

import MobileScreenSkeleton from "../../../../components/Skeletons/Mobile/MobileScreenSkeleton";
import { useNavigate } from "react-router-dom";
import { usePriceFetcher } from "../../../LivePrice/Hooks/usePriceFetcher";
import MobileRootCards from "./MobileRootCards";
import MobileScriptTable from "./MobileScripts";

const MainScreen = (props) => {
  // const { liverates, handleColor, handleSCode, activeTab } = props;
  // const [active, setActive] = useState(true);
  // const [hasRoot, setHasRoot] = useState(true);
  const Router = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => {
  //     setActive(false);
  //   }, 2200);
  // }, []);

  // const roots = liverates?.filter((item) => item?.type == "root");

  return (
    <>
      <>
        <>
          <MobileRootCards />
          <MobileScriptTable />
        </>
      </>
    </>
  );
};

export default MainScreen;
