import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { checkStatus } from "../../../container/Home/api";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setSelectedScriptCode } from "../../Orders/State/OrderSlice";
export const useHandleTradeRouting = () => {
  const Router = useNavigate();
  const dispatch = useDispatch();
  const userInfo = localStorage.getItem("userId");
  const tradeChecker = async (item) => {
    if (userInfo) {
      const payload = {
        alphaName: item?.script_code,
        betaName: userInfo,
        amt: item?.sell,
      };
      if (!item?.allow_trade) return toast.error("cannot be traded");
      dispatch(setSelectedScriptCode(item?.script_code));
      await checkStatusTrade(payload);
    } else {
      Router("/login");
    }
  };
  const { mutate: checkStatusTrade } = useMutation(
    (payload) =>
      checkStatus(payload?.alphaName, payload?.betaName, payload?.amt),
    {
      onSuccess: (data) => {
        console.log("error data", data);

        if (data?.data?.is_success) {
          if (data?.data?.status) {
            Router("/new-order");
            toast.success(data?.data?.msg);
          } else {
            toast.error(data?.data?.msg);
          }
        }
      },
      onError: (e) => {
        toast.error(e?.message);
      },
    },
  );
  return { tradeChecker };
};
