import { createSlice } from "@reduxjs/toolkit"

const initialState={
    totalMessagesCount:0,
    messages:[]
}

const chattingSlice=createSlice({
    name:"chatting",
    initialState,
    reducers:{
        setTotalMessages:(state,{payload})=>{
            state.totalMessagesCount=payload
        },
        updateMessages:(state,{payload})=>{
            state.messages=payload
        }
    }
})

export const {setTotalMessages,updateMessages}=chattingSlice.actions

export default chattingSlice.reducer