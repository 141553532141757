import { useLivePrice } from "./useLivePrice";

export const usePriceFetcher = ({
  refer,
  multiplier = 1,
  addition = 0,
  type,
}) => {
  const { price } = useLivePrice(refer);

  const finalPrice = (price?.commodity || 0) * multiplier + addition;

  const openPrice = (price?.open || 0) * multiplier + addition;
  let percentage = openPrice ? ((finalPrice - openPrice) / openPrice) * 100 : 0;
  let percentageColor = percentage >= 0 ? "green" : "red";

  return {
    price: finalPrice.toFixed(2),
    diff: finalPrice - openPrice,
    percentage,
    percentageColor,
    color:
      type === "buy" ? price?.buyColor || "black" : price?.sellColor || "black",
  };
};
