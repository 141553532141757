import { useGetPendingOrdersQuery } from "../Store/pendingOrderApiSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedScriptCode } from "../../Orders/State/OrderSlice";
import { useDeviceType } from "../../../utils/useMobile";
import { usePendingOrdersHook } from "../Hooks/usePendingOrdersHook";
import FooterPagination from "../../Pagination/components/pagination";

const PendingOrderList = () => {
  const isDesktop = useDeviceType("desktop");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, pages, currentPage, handlePageChange } = usePendingOrdersHook();
  const handleOrderClick = (order) => {
    navigate("/new-order", { state: { data: order } });
    dispatch(setSelectedScriptCode(order.clientScriptId));
  };

  return (
    <div className={`p-4 ${!isDesktop && "my-40"} w-full`}>
      <h1 className="text-2xl font-bold mb-4">Order List</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {data.map((order) => {
          if (order.status != "onwatching") return;
          return (
            <div
              key={order._id}
              className="bg-white shadow-md rounded-md p-4 border hover:shadow-lg cursor-pointer"
              onClick={() => handleOrderClick(order)}
            >
              <h2 className="font-semibold text-lg">
                Order ID: {order.orderId}
              </h2>
              <p className="text-sm text-gray-500">Status: {order.status}</p>
              <p className="text-sm text-gray-500">Product: {order.product}</p>
              <p className="text-sm text-gray-500">
                Price: <span className="font-medium">{order.price}</span>
              </p>
              <p className="text-sm text-gray-500">
                Quantity: <span className="font-medium">{order.quantity}</span>
              </p>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center mt-3 w-full">
        <FooterPagination
          currentPage={currentPage}
          total={pages}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default PendingOrderList;
