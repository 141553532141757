export const AWS_S3_URL = process.env.REACT_APP_AWS_S3_BUCKET_URL;
export const API_BASE_URL = process.env.REACT_APP_PUBLIC_API_URL;
export const NEXT_PUBLIC_API_NAME = process.env.NEXT_PUBLIC_API_NAME;
export const BEARER_TOKEN = "BEARER_TOKEN";
export const AUTH_DATA = "AUTH_DATA";
export const AUTH_HEADER_NAME = "Authorization";
export const SECRET_KEY = process.env.SECRET_KEY;
export const CLIENT_URL = process.env.REACT_APP_PUBLIC_CLIENT;
export const API_NAME = process.env.NEXT_PUBLIC_API_NAME;
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export const AWS_CLIENT_CODE = process.env.REACT_APP_PUBLIC_CLIENT_CODE;

export const toastSettings = {
  duration: 2000,
  position: "top-center",

  // Styling
  style: {
    border: "none",
    padding: "16px",
    background: "#D7C3F1",
    color: "black",
  },
  className: "",

  // Custom Icon
  // icon: "👏",

  // Change colors of success/error/loading icon
  iconTheme: {
    primary: "#AD88C6",
    secondary: "black",
  },

  error: {
    iconTheme: {
      primary: "#EF5A6F",
      secondary: "#fff",
    },
  },

  // Aria
  ariaProps: {
    role: "status",
    "aria-live": "polite",
  },
};
