import { useSelector } from "react-redux";

export const useLivePrice = (rootScript) => {
  let price;
  const liveRates = useSelector((state) => state.liveRates);

  if (rootScript?.includes("-")) {
    const multiplierScripts = rootScript.split("-");
    price = multiplierScripts.reduce(
      (acc, script) => {
        acc.commodity *= liveRates[script]?.commodity || 0;
        acc.open *= liveRates[script]?.open || 0;
        acc.close *= liveRates[script]?.close || 0;
        acc.high *= liveRates[script]?.high || 0;
        acc.low *= liveRates[script]?.low || 0;
        acc.previousTick *= liveRates[script]?.previousTick || 0;
        return acc;
      },
      {
        open: 1,
        close: 1,
        high: 1,
        low: 1,
        commodity: 1,
        id: "",
        buyColor: "black",
        sellColor: "black",
        previousTick: 1,
      },
    );
    debugger;
    price.buyColor =
      price.previousTick > price.commodity
        ? "red"
        : price.previousTick < price.commodity
          ? "green"
          : "black";
    price.sellColor =
      price.previousTick > price.commodity
        ? "red"
        : price.previousTick < price.commodity
          ? "green"
          : "black";
  } else {
    price = liveRates[rootScript];
  }
  return { price };
};
