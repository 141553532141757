import React from "react";
import { useDeviceType } from "../../utils/useMobile";
import { AWS_CLIENT_CODE, AWS_S3_URL } from "../../constants";
import { useHooks } from "./hook";
const BookingDesk = () => {
  const { accounts } = useHooks();
  console.log(accounts, "accounts");
  const bookingDesk = accounts?.length > 0 ? accounts[0]?.bookingDesk : "";
  const isTablet = useDeviceType("tablet");
  const isMobile = useDeviceType("mobile");
  return (
    <div
      className={`h-full w-full bg-white flex items-center md:items-start justify-center ${
        isTablet ? "mt-64" : isMobile ? "mt-44" : "mt-10"
      }  md:mb-10`}
    >
      <div
        className={`flex flex-col bg-login-color w-[350px] lg:w-[400px] border-2 border-primary-800 rounded-3xl items-center gap-8 p-6`}
      >
        {/* Professional Title Section */}
        <div className="w-full text-center mb-4">
          <h2 className="text-2xl lg:text-3xl font-semibold text-primary-800 mb-2">
            Booking Desk
          </h2>
          <div className="mx-auto w-16 border-b-2 border-primary-600"></div>
        </div>

        <div className=" items-center justify-center w-full ">
          <img src={`${AWS_S3_URL}/${bookingDesk}`} alt="" className="" />
        </div>
      </div>
    </div>
  );
};

export default BookingDesk;
