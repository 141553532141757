import React from "react";
import { AWS_S3_URL } from "../../constants";
import { useDeviceType } from "../../utils/useMobile";

const BottomNavbar = () => {
  const isTablet = useDeviceType("tablet");
  return (
    <div
      className={`w-full h-[70px] bg-white flex justify-between items-center fixed bottom-0 z-10 ${
        isTablet ? "px-12" : "px-5"
      } py-5 shadow-md border-t-3`}
    >
      <div className="flex flex-col items-center justify-center gap-1">
        <a href="/" className="flex flex-col justify-center items-center gap-1">
          <img
            src={AWS_S3_URL + "/header/liverate.svg"}
            alt="location"
            className="w-[25px] h-[25px]"
          />
          <span className="text-[#3D3D3D] text-[12px] ">LIVE RATES</span>
        </a>
      </div>
      <div className="flex flex-col items-center justify-center gap-1">
        <a
          href="/trade"
          className="flex flex-col items-center justify-center gap-1"
        >
          <img
            src={AWS_S3_URL + "/header/orders.svg"}
            alt="location"
            className="w-[25px] h-[25px]"
          />
          <span className="text-[#3D3D3D] text-[12px]">TRADES</span>
        </a>
      </div>
      <div className="flex flex-col items-center justify-center gap-1">
        <img
          src={AWS_S3_URL + "/header/pendingorders.svg"}
          alt="location"
          className="w-[25px] h-[25px]"
        />
        <span className="text-[#3D3D3D] text-[12px]">BANK DETAILS</span>
      </div>
      <div className="flex flex-col items-center justify-center gap-1">
        <img
          src={AWS_S3_URL + "/header/messages.svg"}
          alt="location"
          className="w-[25px] h-[25px]"
        />
        <span className="text-[#3D3D3D] text-[12px]">CONTACT</span>
      </div>
    </div>
  );
};

export default BottomNavbar;
