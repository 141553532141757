import {useState,useRef,useEffect} from 'react'
import toast from 'react-hot-toast'
function ResendOtpTimmer({handleResent,timmer=30}) {
    const countingSeconds=timmer
    const [counter,setCounter]=useState(countingSeconds)
    const interval=useRef()
    const startCounter=()=>{
        clearInterval(interval.current)
        setCounter(countingSeconds)
        interval.current=setInterval(()=>{
            setCounter(count=>count-1)
        },1000)
    }
    const handleResend=()=>{
        if(counter>0){
            toast.error("After 60seconds")
            return
        }
        startCounter()
        handleResent()
    }
    useEffect(()=>{
        interval.current=setInterval(()=>{
            setCounter(count=>count-1)
        },1000)
        return ()=>clearInterval(interval.current)
    },[])
    useEffect(()=>{
        if(counter==0) clearInterval(interval.current)
    },[counter])

    
  return (
        <button onClick={handleResend} className="self-end text-right text-xs mt-2 hover:pointer">Resend otp{counter>0 && ` in ${counter}`}</button>
  )
}

export default ResendOtpTimmer
