const apiEndPoints = {
  product: `/product/all-products`,
  silverProduct: `/silver-product/all-products`,
  similarProducts: `/product/similar-products`,
  silverSimilarProducts: `/silver-product/similar-products`,
  fetchPincodes: `/product/all-products`,
  token: `/token`,
  getOtp: "/user/requestOtp",
  verifyOtp: "/user/verifyOtp",
  login: "/user/login",
  loginCus: "user/pos/login",
  google: "/user/googleLogin",
  googleReg: "/user/googleLoginNew",
  facebook: "/user/facebookLogin",
  user: (id) => `/user/${id}`,
  customer: (id) => `/store-employee/details/${id}`,
  goldTreasureInvoiceDownload: `/gold-treasure-invoice/singleInsDetails`,
  imageUpload: `/imageUpload`,
  pincode: (id) => `/shipping_pincode?pincode=${id}`,
  subscribe: `/subscribe`,
  addToWishlist: `/firebase/wishlist`,
  deleteCoupon: `/coupon/remove`,
  // addToFirebaseCart: `/firebase/cart`,
  // updateFirebaseCart: (id, pid) => `/firebase/cart/${id}/${pid}`,
  // deleteFirebaseCart: (id, pid) => `/firebase/cart/${id}/${pid}`,
  // deleteFirebaseAllCart: (id) => `/firebase/cart/${id}`,
  applyCoupon: `/coupon`,
  deleteCoupon: `/coupon/remove`,
  promoCode: `/coupon/getActiveCoupons`,
  customPrice: `/productPrice/order`,
  shippingPincode: "/shipping_pincode",
  payuInit: `/payUApicall`,
  updateFireBaseCartById: (id) => `/firebase/cart/${id}`,
  order: `/order`,
  orderProducts: `/order-products`,
  orderProductsBulk: `/order-products/bulk`,
  verifyPayuPayment: `payUApicall/verifyPayuPayment`,
  updatesPaymentDetails: `payUApicall/updatesPaymentDetails`,
  removeCartData: `web-carts/removeCartData`,
  getOrder: `/order-products`,
  trackOrder: `/track_order`,
  getOffers: `/offers`,
  liveRates: `/live-rates`,
  liveRatesBullion: `/live-rates/bullion`,
  liveRatesCScript: `/live-rates/single-script`,
  liveRatesCScriptQty: `/live-rates/qty`,
  
  clientScript: `/client-script`,
  clientScriptById: (id) => `/client-script/${id}`,
  clientScriptSrNo: "/client-script/latestid",

  trade: `/trade`,
  tradeById: (id) => `/trade/${id}`,
  tradeSrNo: "/trade/latestid",
  statusChecker: "/trade/trade-checks",

  trackHistory: (query) => `/track_order/history?AWBNo=${query}`,
  updOrder: (id) => `/order/${id}`,
  cancelOrder: (id) => `/order-products/cancel/${id}`,
  returnOrder: (id) => `/order-products/return/${id}`,
  searchStock: `stocks/search-stock`,
  getGsp: `/gold-treasures/getGspByPopId`,
  recentViewed: `/recently-viewed`,
  addRecentView: `/recently-viewed`,
  ringSizerLatestId: `/notSureRingSize/latestid`,
  ringSizerRequest: `/notSureRingSize`,
  addVisitor: `/visitor`,
  modifyVisitor: (id) => `/visitor/${id}`,
  logWishlist: `/wishlist-logs`,
  logCart: `/cart-logs`,
  goldCoin: `/live-rates/gold-coin`
};

export default apiEndPoints;
