import { createSlice } from "@reduxjs/toolkit";

const initialState={
    selectedScriptCode:''
}

const orderSlice=createSlice({
    name:"order",
    initialState,
    reducers:{
        setSelectedScriptCodeToEmpty:(state,{payload})=>{
            state.selectedScriptCode=""
        },
        setSelectedScriptCode:(state,{payload})=>{
            state.selectedScriptCode=payload
        }
    }
})

//<----------------------- getters --------------------->
export const getSelectedScriptCode=(state)=>state.order.selectedScriptCode

//<---------------------- setters ---------------------->
export const {setSelectedScriptCode,setSelectedScriptCodeToEmpty}=orderSlice.actions

export default orderSlice.reducer