import React from "react";

const Footer = () => {
  return (
    <div className="bg-footer-backgound size-full">
      <div className="size-full h-full flex gap-5">
        <div className="w-1/2 py-6 px-5 h-[300px] flex flex-col justify-between">
          <div className="">
            <p className="text-xl font-semibold text-[#171659]">ADDRESS</p>
            <p className="mt-3 flex-wrap">
              Gemlay, Unit 15 , Ground Floor , Jubilee Juncttion SAS Nagar,
              Sector 66 - 160062
            </p>
          </div>
          <div className="flex">
            <p className="text-[10px] text-[#302F2F] cursor-pointer">
              SITE MAP{" "}
            </p>
            <p className="text-[10px] text-[#302F2F] cursor-pointer">
              | PRIVACY POLICY{" "}
            </p>
            <p className="text-[10px] text-[#302F2F] cursor-pointer">
              | TERMS AND CONDITIONS{" "}
            </p>
            <p className="text-[10px] text-[#302F2F] cursor-pointer">| FAQs</p>
          </div>
        </div>
        <div className="w-1/2 py-6 px-5 h-[200px] flex flex-col gap-2">
          <p className="text-xl font-semibold text-[#171659]">PHONE</p>
          <div className="flex flex-col gap-1">
            <p>
              {">"} +91 9888898866 {">"} +91 9988898866
            </p>
            <p>
              {">"} +91 9888898866 {">"} +91 9988898866
            </p>
          </div>
          <br />
          <p className="text-xl font-semibold text-[#171659]">EMAIL</p>
          <p>{">"} info@gemlay.com</p>
        </div>
        <div className="w-2/4 mr-5">
          <p className="text-xl font-semibold text-[#171659] py-6 ">
            Quick Links
          </p>
          <div className="flex justify-between size-full gap-16">
            <ul className="">
              <li className="py-1 cursor-pointer text-black]">{">"} Home</li>
              <li className="py-1 cursor-pointer text-black]">
                {">"} Live Rates
              </li>
              <li className="py-1 cursor-pointer text-black]">
                {">"} About Us
              </li>
              <li className="py-1 cursor-pointer text-black]">{">"} Trades</li>
              <li className="py-1 cursor-pointer text-black]">
                {">"} Pending Orders
              </li>
              <li className="py-1 cursor-pointer text-black]">
                {">"} Bank Details
              </li>
            </ul>
            <ul className="">
              <li className="py-1 cursor-pointer text-black]">{">"} Home</li>
              <li className="py-1 cursor-pointer text-black]">
                {">"} Live Rates
              </li>
              <li className="py-1 cursor-pointer text-black]">
                {">"} About Us
              </li>
              <li className="py-1 cursor-pointer text-black]">{">"} Trades</li>
              <li className="py-1 cursor-pointer text-black]">
                {">"} Pending Orders
              </li>
              <li className="py-1 cursor-pointer text-black]">
                {">"} Bank Details
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex h-[40px] bg-custom-gradient size-full justify-between items-center px-10 text-white mt-10">
        <p>Copyrights &copy; 2024. All Rights Reserved</p>
        <p>Powered by : Gemlay Software &copy;</p>
      </div>
    </div>
  );
};

export default Footer;
