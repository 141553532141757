import api from "./api";

const axiosBaseQuery =
  () =>
  async ({ url, method, data, params }) => {
    try {
      const result = await api({
        url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err=axiosError
      return {
        error: {
          status: err.is_success,
          msg: err.data || err.msg,
        },
      };
    }
  };
export default axiosBaseQuery