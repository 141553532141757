import React, { useState, useEffect } from "react";

function MarqueeContent({ marqueeData }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Set a timer for the current item
    const timer = setTimeout(() => {
      // Move to the next item, looping back to the first if at the end
      setCurrentIndex((prevIndex) =>
        prevIndex === marqueeData.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000); // Adjust the time duration as needed (5000ms = 5s)

    return () => clearTimeout(timer); // Cleanup on component unmount or before next effect
  }, [currentIndex, marqueeData.length]);

  return (
    <div className="overflow-auto h-10 flex items-center w-full">
      <div className=" transition-all duration-1000 ease-linear animate-marquee">
        <span key={marqueeData[currentIndex]?._id} className="inline-block">
          {marqueeData[currentIndex]?.content}
        </span>
      </div>
    </div>
  );
}

export default MarqueeContent;
