import { api, apiEndPoints } from "../../api/index";
import { CLIENT_URL } from "../../constants/index";
export const getlivePrice = async (no, type) => {
  console.log(type,'types');
  const response = await api.get(apiEndPoints.liveRatesBullion, {
    params: {
      pagination: false,
      no: no,
      alphaName: CLIENT_URL,
      scriptType: type,
    },
  });
  return response;
};

export const getCoinPrice = async (no) => {
  const response = await api.get(apiEndPoints.goldCoin, {
    params: {
      pagination: false,
      no: no,
      alphaName: CLIENT_URL,
    },
  });
  return response;
};

export const checkStatus = async (alphaName,betaName,amt) => {
  const response = await api.get(apiEndPoints.statusChecker, {
    params: {
      pagination: false,
      alphaName: alphaName,
      betaName: betaName,
      amt: amt,
      listing:true
    },
  });
  return response;
};

export const GetClientScript = async () => {
  const response = await api.get(apiEndPoints.clientScript, {
    params: {
      pagination: false,
      clientId: "66b4c365fc09db0bb45190b5",
      admin: true,
    },
  });
  return response;
};
