import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getScriptTypes,
  getSelectedScript,
  setSelectedScript,
} from "../State/homePageSlice";

function AllScriptsAvailable() {
  const dispatch = useDispatch();
  const scriptTypes = useSelector(getScriptTypes);
  const activeTab = useSelector(getSelectedScript);
  const loading = true;
  if (loading) {
  }

  return scriptTypes?.map((script, index) => {
    if (script?.active) {
      return (
        <React.Fragment key={index}>
          <div
            className="size-full flex flex-col items-center justify-center gap-1"
            onClick={() => {
              dispatch(setSelectedScript(script.title));
            }}
          >
            <p
              className={`
                    text-white cursor-pointer`}
            >
              {script?.title?.toUpperCase()}
            </p>
            {activeTab == script?.title && (
              <div className="w-[90px] bg-slate-700 h-[2px]"></div>
            )}
          </div>
          {index !== scriptTypes?.length - 1 && (
            <div className="h[4px] w-[2px] bg-slate-800"></div>
          )}
        </React.Fragment>
      );
    }
  });
}

export default AllScriptsAvailable;
