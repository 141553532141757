import { useEffect, useState, useContext, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import { getScriptQty, CreateOrder, getScriptByCode } from "./api";
import useForm from "../../hooks/useForm";
import toast from "react-hot-toast";
import { toastSettings } from "../../constants";
import { AppContext } from "../../context/AppContext";
import { hasValueInObject, required } from "../../utils/formhelper";
import { useNavigate } from "react-router-dom";
import { useUpdatePendingOrdersMutation } from "../../Features/PendingOrders/Store/pendingOrderApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedScriptCode } from "../../Features/Orders/State/OrderSlice";
import { priceDeviationCalculator } from "../../utils/PriceDeviationCalculator";
import { fetchNotificationCount } from "../../Features/Notifications/Store/notificationSlice";
import notificationApiSlice from "../../Features/Notifications/Store/notificationApiSlice";

const useOrder = () => {
  const code = useSelector(getSelectedScriptCode);
  debugger;
  const [isFetched, setIsFetched] = useState(false);
  const [loader, setLoader] = useState(false);
  const { form, handleChange, setForm, setInForm } = useForm(null);
  const { user, clearUser, setUser } = useContext(AppContext);
  const updateOrderValue = useRef(false);
  const userInfos = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [updateOrder, { isSuccess, isError, error }] =
    useUpdatePendingOrdersMutation();
  const Router = useNavigate();

  useEffect(() => {
    setForm({ ...form, limitType: "market" });
    if (code) {
      fetchScriptByScriptCode();
      qtyRefetch();
    } else {
      Router("/");
    }
  }, [code]);

  const dataRef = useRef(null);

  const {
    data: { data: scriptData = {} } = {},
    refetch: fetchScriptByScriptCode,
  } = useQuery("fetchScript", () => getScriptByCode(code), {
    enabled: false,
    onSuccess: (data) => {},
  });

  const { data: { data: { data: qtyData } = {} } = {}, refetch: qtyRefetch } =
    useQuery("fetchQty", () => getScriptQty(0, code), {
      enabled: false,
      onSuccess: (data) => {},
    });

  const handleChangeLimitType = (val) => {
    setForm({ ...form, limitType: val });
  };

  const updateForm = (val) => {
    setForm({ ...form, ...val, limitPrice: val.price });
    console.log("the initial val of the form is", form);
  };

  const findFormErrors = (data) => {
    const newErrors = {};
    newErrors.quantity = required(data?.quantity);
    if (data?.limiType == "limit") {
      newErrors.limitPrice = required(data?.limitPrice);
    }

    return newErrors;
  };

  const handleSubmit = async (type, price, basePrice) => {
    console.log("update order mutate");
    const newErrors = findFormErrors(form);

    if (hasValueInObject(newErrors, true)) {
      if (newErrors.quantity == true) {
        toast.error("Quantity is required");
      } else if (newErrors.limitPrice == true) {
        toast.error("Limit Price is required");
      }
      // return;
    } else {
      setLoader(true);
      let calculateRootPrice = priceDeviationCalculator({
        price,
        scriptData,
        type,
        basePrice,
      });
      console.log("this for the testing", calculateRootPrice);
      let payload = {
        ...form,
        clientScriptId: code,
        tradeType: type,
        price: price,
        customerId: userInfos,
        TradeId: form?.id,
        rootScript: scriptData?.rootScript,
        limitPriceForRootScript: calculateRootPrice,
      };
      if (updateOrderValue.current) {
        try {
          const value = await updateOrder({
            id: form?.id,
            data: payload,
          }).unwrap();
          toast.success(value.msg);
          Router("/pending-orders");
          return;
        } catch (err) {
          toast.error("Something went wrong");
        }
      }
      console.log(payload, "payload");
      addTradeMutate(payload);
    }
  };

  const resetform = () => {
    setForm({});
    Router("/");
  };
  // const { mutate: loggedUser } = useMutation((id) => getLoggedUser(id), {
  //   onSuccess: (data) => {
  //     const { data: { storeEmployee: { usedLimit, custLimit } = {} } = {} } =
  //       data;
  //     console.log(data, "data", usedLimit);
  //
  //     let loggedinUser = JSON.parse(localStorage.getItem("LoggedInUser"));
  //     loggedinUser.user.usedLimit = usedLimit;
  //     localStorage.setItem("LoggedInUser", JSON.stringify(loggedinUser));
  //   },
  //   // dispatch(setLoggedUserDetails(data?.data?.user)),
  //   enabled: false,
  // });
  const { mutate: addTradeMutate } = useMutation(
    (payload) => CreateOrder(payload),
    {
      onSuccess: (data) => {
        if (data?.data?.is_success) {
          resetform();
          setLoader(false);
          // loggedUser(userInfos);
          dispatch(fetchNotificationCount());
          dispatch(notificationApiSlice.util.resetApiState());

          console.log("script trading limit view", data);
          toast.success(data?.data?.msg);
        }
      },
      onError: (error) => {
        if (error == "Unauthorized") {
          toast.error("Session Expired please login again", toastSettings);
          const country = localStorage.getItem("COUNTRY");
          localStorage.clear();
          localStorage.setItem("COUNTRY", country);
          clearUser();
          setLoader(false);
        }
      },
    },
  );
  const handlePriceChange = (e) => {
    setForm({ ...form, limitPrice: e.target.value });
  };

  return {
    updateForm,
    scriptData,
    updateOrderValue,
    handlePriceChange,
    handleChangeLimitType,
    isFetched,
    qtyData,
    form,
    handleChange,
    handleSubmit,
    dataRef,
    loader,
    isSuccess,
    isError,
    error,
  };
};

export default useOrder;
