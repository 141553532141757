import { createSlice } from "@reduxjs/toolkit";

const initialState={
    userId:null,
}


const AuthSlice=createSlice({
    name:"auth",
    initialState,
    reducers:{
        setUserId(state,action){
            state.userId=action.payload
        }
    }
})

export const getUserId=(state)=>state.auth.userId

export const {setUserId}=AuthSlice.actions

export default AuthSlice.reducer