import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { updateLiveFeed } from "../Store/livePriceSlice";
import { API_BASE_URL } from "../../../constants";
import { getUserId } from "../../Auth/Store/AuthSlice";

function FetchLiveRates() {
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const userId = useSelector(getUserId);
  useEffect(() => {
    const country = localStorage.getItem("COUNTRY");
    console.log("Country from localStorage:", country);

    const liveRateSocket = io(`${API_BASE_URL}/liveRates`, {
      transports: ["websocket"],
      withCredentials: true,
      query: {
        clientId: process.env.REACT_APP_PUBLIC_CLIENT,
        country: country,
      },
    });

    setSocket(liveRateSocket);

    liveRateSocket.on("connect", () => {
      console.log("Socket connected");
    });

    liveRateSocket.on("live-feed", (data) => {
      dispatch(updateLiveFeed(data));
    });

    return () => {
      liveRateSocket.disconnect();
    };
  }, [dispatch, userId]);

  return <></>;
}

export default FetchLiveRates;
