import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchNotificationCount } from '../Store/notificationSlice'

function NotificationCountFetcher() {
    const dispatch=useDispatch()
    useEffect(()=>{
        dispatch(fetchNotificationCount())
    },[])
  return null
}

export default NotificationCountFetcher