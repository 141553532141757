import React from "react";
import HomePage from "../Features/Home/Components/HomePage";

const Home = () => {
  return (
    <>
      <HomePage />
    </>
  );
};

export default Home;
