import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const liveRatesSlice = createSlice({
  name: "LiveRates",
  initialState,
  reducers: {
    updateLiveFeed: (state, { payload }) => {
      if (!payload) return;
      state[payload.title] = payload.data;
    },
  },
});

//<-------------- Individual data fetcher --------------------->
export const getGoldPrice = (state) => state.liveRates["GOLD"];
export const getSilverPrice = (state) => state.liveRates["SILVER"];
export const getInrPrice = (state) => state.liveRates["INR"];
export const getGoldDollarPrice = (state) => state.liveRates["GOLD ($)"];
export const getSilverDollarPice = (state) => state.liveRates["SILVER ($)"];

//<------------- All Data fetcher ----------------------------->
export const getLiveData = (state) => state.liveRates.liveFeedData;

export const { updateLiveFeed } = liveRatesSlice.actions;

export default liveRatesSlice.reducer;
