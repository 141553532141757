import React from 'react'
import LiveChartPage from '../../container/Chart'
import { useLocation } from 'react-router-dom';
function Chart() {
  const location = useLocation();
  const item  = location ;
  return (
    <div>
      <LiveChartPage title={item.search}/>
    </div>
  )
}

export default Chart
