import React, { useContext } from "react";
import { AWS_CLIENT_CODE, AWS_S3_URL } from "../../constants";
import { Link } from "react-router-dom";
const DrawerHeader = () => {
  return (
    <div className="flex items-center justify-center w-full h-[40px]">
      <Link to="/login">
        <img
          src={`${AWS_S3_URL}/clients/${AWS_CLIENT_CODE}/logo.png`}
          alt=""
          className="w-[200px] h-[50px]"
        />
      </Link>
    </div>
  );
};

export default DrawerHeader;
