import React, { useEffect } from "react";
import useOrder from "./hooks";
import { useDeviceType } from "../../utils/useMobile";
import { quantityInGrams } from "../../constants/contents/qunatity";
import { useLocation } from "react-router-dom";
import { useBuyAndSellRate } from "./useBuyAndSellRate";
import { useDispatch } from "react-redux";
import { setSelectedScriptCodeToEmpty } from "../../Features/Orders/State/OrderSlice";
import LimitText from "../../Features/UserLimit/Components/LimitText";
const OrderPage = () => {
  const location = useLocation();
  const {
    dataRef: liverates,
    qtyData,
    form,
    updateOrderValue,
    scriptData,
    updateForm,
    handleChange,
    handleChangeLimitType,
    handleSubmit,
    loader,
    handlePriceChange,
  } = useOrder();
  const dispatch = useDispatch();
  useEffect(() => {
    const data = location.state?.data;
    if (data) {
      updateOrderValue.current = true;
      updateForm(data);
    }
    return () => dispatch(setSelectedScriptCodeToEmpty());
  }, []);

  const { buyPrice, buyColor, sellPrice, sellColor } =
    useBuyAndSellRate(scriptData);
  const isDesktop = useDeviceType("desktop");

  return (
    <div
      className={`h-full w-full bg-white flex flex-col justify-center items-center py-10  ${
        !isDesktop ? "mt-48 mb-20" : ""
      }`}
    >
      {!isDesktop && <LimitText />}
      <div className="flex border-2 border-primary-700 py-3 w-[370px]  md:mt-12 rounded-[20px] bg-row-background">
        <div className="flex flex-col justify-start items-center w-full gap-2 my-10">
          <p className="">{scriptData.title || "not found"}</p>
          <div className="flex py-1 px-4 mb-4">
            <div
              className={`px-8 py-1 rounded-l-xl cursor-pointer ${
                form?.limitType == "market" ? "bg-custom-gradient" : "bg-white"
              }`}
              onClick={() => {
                handleChangeLimitType("market");
              }}
            >
              <p
                className={` ${
                  form?.limitType == "market" ? "text-white" : ""
                }`}
              >
                MARKET
              </p>
            </div>
            <div
              className={`px-8 py-1 rounded-r-xl cursor-pointer ${
                form?.limitType === "pending"
                  ? "bg-custom-gradient"
                  : "bg-white"
              }`}
              onClick={() => {
                handleChangeLimitType("pending");
              }}
            >
              <p
                className={` ${
                  form?.limitType === "pending" ? "text-white" : ""
                }`}
              >
                PENDING
              </p>
            </div>
          </div>
          <div className="flex w-full justify-between items-center px-16 mb-4">
            <div className="flex flex-col items-center">
              <p>BID</p>
              <p
                className={`${buyColor === "red" ? "text-red-500" : buyColor === "green" ? "text-green-500" : "text-black"} text-[20px]`}
              >
                {scriptData?.hide_by_rate !== "-"
                  ? `${scriptData?.rootScript?.includes("$") ? "$" : "₹"}${buyPrice.toFixed(2)}`
                  : `${scriptData?.rootScript?.includes("$") ? "$" : "₹"}${sellPrice.toFixed(2)}`}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <p>ASK</p>
              <p
                className={`${sellColor === "red" ? "text-red-500" : sellColor === "green" ? "text-green-500" : "text-black"} text-[20px]`}
              >
                {scriptData?.rootScript?.includes("$") ? "$" : "₹"}
                {sellPrice.toFixed(2)}
              </p>
            </div>
          </div>
          <div className="flex flex-col w-full items-center justify-center px-16 gap-3">
            <div className="flex items-center justify-between gap-3 w-full">
              <p className="text-[14px]">QUANTITY:- </p>
              <select
                className="py-1 px-4 self-center bg-white rounded-2xl pr-8"
                name="quantity"
                value={form?.quantity}
                onChange={handleChange}
              >
                <option value={""}>{"select"}</option>
                {qtyData?.map((qty, index) => (
                  <option key={index} value={qty?.value}>
                    {qty?.label}
                  </option>
                ))}
              </select>
            </div>
            {form?.limitType == "pending" && (
              <div className="flex items-center justify-between w-full">
                <p className="text-[14px]">PRICE:- </p>
                <input
                  type="number"
                  className="py-[4px] px-4 bg-white rounded-2xl w-[130px] pl-8"
                  placeholder="Enter"
                  onChange={handlePriceChange}
                  value={form?.limitPrice}
                />
              </div>
            )}
          </div>
          {liverates?.current?.buy !== "-" &&
          form?.quantity &&
          form?.limitType === "market" ? (
            <div>
              Margin Required:
              {scriptData?.rootScript?.includes("$") ? "$" : "₹"}
              {(
                (Number(form?.quantity) || 0) *
                (Number(buyPrice) /
                  (Number(quantityInGrams?.[scriptData?.rootScript]) || 1))
              ).toFixed(2)}
            </div>
          ) : (
            <div>
              Margin Required:
              {scriptData?.rootScript?.includes("$") ? "$" : "₹"}
              {((form?.quantity || 0) * (form?.limitPrice || 0)).toFixed(2) ||
                0}
            </div>
          )}
          {!loader && (
            <div className="flex w-full justify-between items-center px-20 mt-8">
              <button
                className="px-6 py-2 bg-[#FF5353] text-white rounded-xl"
                onClick={() => {
                  form?.limitType == "market"
                    ? handleSubmit("sell", sellPrice, sellPrice)
                    : handleSubmit("sell", form?.limitPrice, sellPrice);
                }}
              >
                SELL
              </button>
              <button
                className="px-6 py-2 bg-[#008D17] text-white rounded-xl"
                onClick={() => {
                  form?.limitType == "market"
                    ? handleSubmit(
                        "buy",
                        scriptData.hide_by_rate != "-" ? buyPrice : sellPrice,
                        scriptData.hide_by_rate != "-" ? buyPrice : sellPrice,
                      )
                    : handleSubmit("buy", form?.limitPrice, buyPrice);
                }}
              >
                BUY
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
