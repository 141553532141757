import React from 'react'
import PendingOrderList from './Components/PendingOrderList'

function PendingOrdersPage() {
    
      
  return (
    <div>
      <PendingOrderList />
    </div>
  )
}

export default PendingOrdersPage
