import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../../api/axiosBaseQuery";
import { apiEndPoints } from "../../../api";
import { CLIENT_URL } from "../../../constants";

const authAPI = createApi({
  reducerPath: "authAPI",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    otpRequest: builder.mutation({
      query: ({ phone, name, country }) => ({
        url: apiEndPoints.getOtp,
        method: "POST",
        data: {
          phone,
          name: name,
          userType: "employee",
          clientId: CLIENT_URL,
          country,
        },
      }),
    }),
    otpVerification: builder.mutation({
      query: ({ phone, otp, ip, userLocation, country }) => ({
        url: apiEndPoints.verifyOtp,
        method: "POST",
        data: {
          phone,
          otp: otp,
          clientId: CLIENT_URL,
          currentIp: ip,
          userLocation,
          country,
        },
      }),
    }),
    login: builder.mutation({
      query: ({ phone, otp, userLocation, country }) => ({
        url: apiEndPoints.login,
        method: "POST",
        data: {
          phone,
          otp,
          clientId: CLIENT_URL,
          userLocation,
          country,
        },
      }),
    }),
  }),
});

export const {
  useOtpRequestMutation,
  useOtpVerificationMutation,
  useLoginMutation,
} = authAPI;
export default authAPI;

