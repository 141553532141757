import React from "react";
import MobileTableSkeleton from "./MobileTableSkeleton";
import MobileCardSkeleton from "./MobileCardSkeleton";
import { FaCircleChevronDown, FaCircleChevronUp } from "react-icons/fa6";
import gold2 from "../../../assets/cards/gold 3.png";
import gold3 from "../../../assets/cards/gold-bar 1.png";
import dollar from "../../../assets/cards/dollar.png";

const MobileScreenSkeleton = (props) => {
  const { isCoinScreen } = props;
  return (
    <div>
      {!isCoinScreen && (
        <div className="flex justify-between items-center gap-3 py-3 relative ">
          <div className="w-full h-[120px] overflow-auto flex justify-center items-center flex-col shadow-md bg-white rounded-xl ">
            <div className="w-[40px] h-[40px] border-[1px] border-solid rounded-full absolute top-[-5px] bg-white z-10 flex items-center justify-center">
              <img src={gold2} alt="Gold Icon" className="w-[22px] pb-2" />
            </div>
            <p className="text-[12px] mt-4">GOLD ($)</p>
            <MobileCardSkeleton />
          </div>

          <div className="w-full h-[120px] overflow-auto flex justify-center items-center flex-col shadow-md bg-white rounded-xl ">
            <div className="w-[40px] h-[40px] border-[1px] border-solid rounded-full absolute top-[-5px] bg-white z-10 flex items-center justify-center">
              <img src={dollar} alt="Gold Icon" className="w-[22px]" />
            </div>
            <p className="text-[12px] mt-4">USD INR</p>
            <MobileCardSkeleton />
          </div>

          <div className="w-full h-[120px] overflow-auto flex justify-center items-center flex-col shadow-md bg-white rounded-xl ">
            <div className="w-[40px] h-[40px] border-[1px] border-solid rounded-full absolute top-[-5px] bg-white z-10 flex items-center justify-center">
              <img src={gold3} alt="Gold Icon" className="w-[22px]" />
            </div>
            <p className="text-[12px] mt-4">GOLD COST</p>
            <MobileCardSkeleton />
          </div>
        </div>
      )}
      <MobileTableSkeleton />
    </div>
  );
};

export default MobileScreenSkeleton;
