import React, { useEffect, useState } from "react";
import gold2 from "../../../../assets/cards/gold 3.png";
import gold3 from "../../../../assets/cards/gold-bar 1.png";
import { FaCircleChevronDown } from "react-icons/fa6";
import { FaCircleChevronUp } from "react-icons/fa6";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { BsPatchCheckFill } from "react-icons/bs";
import dollar from "../../../../assets/cards/dollar.png";
import MobileScreenSkeleton from "../../../../components/Skeletons/Mobile/MobileScreenSkeleton";
import { useNavigate } from "react-router-dom";

const MainScreen = (props) => {
  const { liverates, handleColor, handleSCode, activeTab } = props;
  const [active, setActive] = useState(true);
  const [hasRoot, setHasRoot] = useState(true);
  const Router = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setActive(false);
    }, 2200);
  }, []);

  const roots = liverates?.filter((item) => item?.type == "root");

  return (
    <>
      <>
        {active ? (
          <MobileScreenSkeleton />
        ) : (
          <>
            <div className="flex justify-between items-center gap-2 py-3 relative ">
              {roots?.length > 0 &&
                liverates?.map((rate, index) => {
                  if (rate?.type == "root") {
                    return (
                      <div className="w-full h-[120px] overflow-auto flex justify-center items-center flex-col shadow-md bg-white rounded-xl ">
                        <div className="w-[40px] h-[40px] border-[1px] border-solid rounded-full absolute top-[-5px] bg-white z-10 flex items-center justify-center">
                          <img
                            src={gold2}
                            alt="Gold Icon"
                            className="w-[22px] pb-2"
                          />
                        </div>
                        <p className="text-[12px] mt-4">{rate?.product}</p>

                        <p
                          style={{
                            color: `${handleColor(index, "buy")}`,
                            transition: "color 0.1s ease-in-out",
                            fontSize: `13px`,
                            fontWeight: "bold",
                          }}
                        >
                          {rate?.sell}
                        </p>

                        {/* <div className="flex py-1 bg-[#FFEBEB] px-4 rounded-[30px] my-1 gap-1 mt-3">
                        <p className="text-[8px]">+2852 (3.281)</p>
                        <FaCircleChevronDown
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </div> */}
                      </div>
                    );
                  }
                })}
            </div>
            {liverates?.map((item, index) => {
              if (item?.type !== "root") {
                return (
                  <div
                    className="flex w-full h-auto min-h-[80px] border-[1px] border-gray-200 py-2 rounded-3xl bg-white justify-between items-center mt-3 px-2 last:mb-6"
                    key={index}
                  >
                    <div className="flex w-2/3 px-2 justify-start items-center gap-2">
                      <img
                        src={gold2}
                        alt="Gold Icon"
                        className="w-[28px] h-[28px] pb-2"
                      />
                      <p className="text-[14px]">{item?.product}</p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-1">
                      {item?.buy !== "-" ? (
                        <>
                          <div
                            className={`flex items-center justify-center border-2 ${
                              handleColor(index, "buy") == "red"
                                ? "border-red-500"
                                : handleColor(index, "buy") == "green"
                                ? "border-green-500"
                                : "border-gray-200"
                            } px-4  h-[40px] rounded-3xl`}
                          >
                            <p>{item?.buy}</p>
                          </div>
                          <p className="text-[10px] text-[#454545]">L:72660</p>
                        </>
                      ) : (
                        <p>-</p>
                      )}
                    </div>

                    <div className="flex gap-1 w-1/3 ml-2 justify-center items-start ">
                      <div className="flex flex-col items-center justify-center gap-1">
                        <div
                          className={`flex items-center justify-center border-2 ${
                            handleColor(index, "sell") == "red"
                              ? "border-red-500"
                              : handleColor(index, "sell") == "green"
                              ? "border-green-500"
                              : "border-gray-200"
                          } px-3  h-[40px] rounded-3xl`}
                          onClick={() => {
                            if (item?.allowTrade) {
                              Router("/new-order");
                              handleSCode(item?.scriptId);
                            }
                          }}
                        >
                          <p>{item?.sell}</p>
                        </div>
                        <p className="text-[10px] text-[#454545]">L:72660</p>
                      </div>

                      <div className="flex mr-2 mt-3">
                        {handleColor(index, "sell") == "green" ? (
                          <FaArrowAltCircleUp
                            style={{ fontSize: "20px", color: "green" }}
                          />
                        ) : handleColor(index, "sell") == "red" ? (
                          <FaArrowAltCircleDown
                            style={{ fontSize: "20px", color: "red" }}
                          />
                        ) : (
                          <BsPatchCheckFill
                            style={{ fontSize: "20px", color: "gray" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </>
        )}
      </>
    </>
  );
};

export default MainScreen;
