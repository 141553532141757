import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../../api/axiosBaseQuery";

const newsApiSlice = createApi({
  reducerPath: "newsApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getNews: builder.query({
      query: (search) => ({ url: "/news", method: "GET", params: { search } }),
      transformResponse: (response) => response.data,
      providesTags: ["news"],
    }),
  }),
});

// const newsApiSlice=createApi({
//     reducerPath:"news",
//     baseQuery:axiosBaseQuery(),
//     endpoints:(builder)=>({
// getNews:builder.query({
//     query: (search) => ({ url: '/news', method: 'GET',params: {search}}),
//     transformResponse:(response)=>response.data,
//     providesTags:["news"]
// }),
//     })
// })

export const { useGetNewsQuery } = newsApiSlice;
export default newsApiSlice;
