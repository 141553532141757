import React, { useEffect, useState, useContext, useRef } from "react";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { setBearerToken } from "../../utils/token";
import { getLoggedUser, login, otpVerification, otpRequest, searchCurentIp } from "./api";
import useForm from "../../hooks/useForm";
import { toastSettings } from "../../constants/index";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { encyptData } from "../../utils/cryptoenc";
export const useLoginHooks = (props) => {
  const { form, handleChange, setForm, setInForm } = useForm({});
  const [info, setInfo] = useState();
  const [phone, setPhone] = useState(props?.phone);
  const [otpMessage, setOtpMessage] = useState("");

  const [userId, setUserId] = useState("");
  const [otp, setOtp] = useState("");
  const [wish, setwish] = useState("sdfsd");
  const [openPage, setOpenPage] = useState("");
  const [error, setError] = useState(false);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const loginRef = useRef(null);
  const currentIp = useRef();
  const { setUser } = useContext(AppContext);

  const nameRegex = /^[a-zA-Z\s]*$/;
  const emailRegex = /^$|^\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b$/;
  const [counter, setCounter] = useState(31);

  const navigate = useNavigate();

  useEffect(() => {
    // if (counter === 0) router.push("/");
    counter > 0 && setTimeout(() => setCounter(counter - 1), 2000);
  }, [counter]);
  useEffect(() => {
    if (nameRegex.test(form?.name) == false) {
      setIsValidName(false);
    } else {
      setIsValidName(true);
    }
  }, [form?.name]);

  useEffect(() => {
    if (emailRegex.test(form?.email) == false && form !== null) {
      setIsValidEmail(false);
    } else {
      setIsValidEmail(true);
    }
  }, [form?.email]);
  useEffect(() => {
    searchIp();
  }, []);
  useEffect(() => {
    console.log(form,'formform');
    
  }, [form]);

  const { mutate: loggedUser } = useMutation((id) => getLoggedUser(id), {
    onSuccess: (data) => { },
    // dispatch(setLoggedUserDetails(data?.data?.user)),
    enabled: false,
  });

  const { mutate: getOtp, isLoading } = useMutation(
    (phone) => otpRequest(phone),
    {
      onSuccess: (data) => {
        if (data?.data?.is_success) {
          if (data?.data?.msg === "User Blocked!") {
            toast.error(
              "Your Account is Blocked!  Please contact customer care"
            );
            // handleLoginModalPopup(false);
          } else {
            // props.setModelContent(phone);
            toast.success("Verification code send!");
            setOtpMessage(data?.data?.msg);
            setCounter(31);
          }
        }
      },
      onError: () => {
        toast.error("An Error occured while sending OTP");
      },
    }
  );

  const handleContinueButtonPress = () => {
    if (phone?.length == 12) {
      // dispatch(setUserNumber(phone));
      getOtp(phone);
    } else if (!phone) {
      setError(true);
      toast.error("Please Enter correct Phone number");
    } else {
      setError(true);
      toast.error("Please Enter correct Phone number");
    }
  };

  //new login flow
  const handleGetOtpButton = () => {
    console.log("OTP_CALL", phone);
    if (phone?.length == 10) {
      setOpenPage("otp");
      //   dispatch(setUserNumber(phone));
      getNewOtp({ phone: `91${phone}`, name: form?.name });
    } else {
      // setError(true);
      console.log("ELSE");
      toast.error("Please Enter correct Phone number");
    }
  };
  const { mutate: getNewOtp } = useMutation(
    (payload) => otpRequest(payload?.phone, payload?.name),
    {
      onSuccess: (data) => {
        if (data?.data?.is_success) {
          if (data?.data?.msg === "User Blocked!") {
            toast.error(
              "Your Account is Blocked!  Please contact customer care"
            );
          } else {
            // setOpenPage("otp");
            toast.success("Verification code send!");
            setOtpMessage(data?.data?.msg);
            setCounter(31);
          }
        }
      },
      onError: () => {
        toast.error("An Error occured while sending OTP");
      },
    }
  );
  // verify OTP
  const { mutate: verifyOtp } = useMutation(
    (payload) => otpVerification(phone, payload, form?.currentIp),
    {
      onSuccess: (data) => {
        console.log(data, "data", data?.data?.msg);
        setOtpMessage(data?.data?.msg);

        // router.push("/");
        if (data?.data?.is_success) {
          if (data?.data?.msg === "Signin User!") {
            localStorage.setItem("tempId", data?.data?.id);
            localStorage.setItem("userId", data?.data?.id);
            setUserId(data?.data?.id);
            toast.success("New user registration");
          } else {
            console.log(data?.data?.msg, "loginSuccess");

            // }
            setUserId(data?.data?.id);
            // setOtp("");
          }
          setOpenPage("signup");
        } else {
          toast.error(otpMessage);
          setOtpMessage("");
        }
      },
      onError: (error) => {
        console.log(error, "newerror", error?.msg);
        toast.error(error?.msg);

        toast.error(error?.msg);
      },
      enabled: false,
    }
  );


  const { mutate: searchIp } = useMutation((payload) => searchCurentIp(), {
    onSuccess: (data) => {
      console.log(form,'form1');
      const {
        data: { ip },
      } = data;
      currentIp.current = ip;
      form.secret_key = "845d58e5d8e58s5e8dd7d5d42d1";
      // form.type = userType;
      const encdata = encyptData(currentIp.current, "GL-Key");
      setForm({ ...form, currentIp: encdata });
      console.log(form,'form');
      
      // form.currentIp = encdata;
      // form.userLocation = loc;
      // form.userCoordinates = latLong;
      // form.currentIps = encdata;
      // Login(form);
    },

    enabled: false,
  });
  // login user
  const { mutate: userLogin } = useMutation(() => login(phone, otp), {
    onSuccess: (data) => {
      if (data?.data) {
        toast.success("Logged in successfully!!");
        localStorage.setItem("LoggedInUser", JSON.stringify(data?.data));
        setUser(data?.data);
        localStorage.setItem("userId", data?.data?.userId);
        loginRef.current = data?.data?.userId;
        setBearerToken(data?.data?.token);
        if (data?.data?.is_success) {
          let id = data?.data?.userId;
          loggedUser(data?.data?.userId);
        }
        setOtpMessage(data?.data?.msg);
        navigate("/");
      } else {
        setOtpMessage("");
        toast.error(data?.data?.msg);
      }
    },
    onError: () => {
      setOtpMessage("");
      setPhone("");
    },
    enabled: false,
  });
  useEffect(() => {
    otpMessage == "Login User!" && userLogin();
  }, [otpMessage]);

  return {
    handleContinueButtonPress,

    setPhone,
    phone,
    otpMessage,
    getOtp,
    error,
    setError,
    info,
    setInfo,
    form,
    handleChange,
    setForm,
    setInForm,
    handleGetOtpButton,
    verifyOtp,
    otp,
    setOtp,
    openPage,
    setOpenPage,
    isValidName,
    isValidEmail,
    counter,
  };
};
