import React from "react";
import { AWS_S3_URL } from "../../constants";
import { useDeviceType } from "../../utils/useMobile";
import { useSelector } from "react-redux";
import liveRatesSvg from "../../assets/liveRates.svg";
import tradesSvg from "../../assets/tradeHistory.svg";
import bookingDeskSvg from "../../assets/bookingDesk.svg";
import pendingOrderSvg from "../../assets/pendingOrders.svg";
import bankDetailsSvg from "../../assets/bankDetials.svg";
import notificationsSvg from "../../assets/notifications.svg";
import contactUsSvg from "../../assets/contact-us.svg";
import { Link } from "react-router-dom";
import {
  getCanSeeBankDetails,
  getCanSeeBookingDesk,
  getCanSeeContactUs,
  getCanSeeLiveRates,
  getCanSeeNotifications,
  getCanSeeTradeHistory,
} from "../../Features/Access/Store/accessSlice";

const BottomNavbar = () => {
  const tradeHistory = useSelector(getCanSeeTradeHistory);
  const liveTrades = useSelector(getCanSeeLiveRates);
  const bookingDesk = useSelector(getCanSeeBookingDesk);
  const bankDetails = useSelector(getCanSeeBankDetails);
  const notifications = useSelector(getCanSeeNotifications);
  const contactUs = useSelector(getCanSeeContactUs);
  const bottomTabs = [
    liveTrades && {
      to: "/",
      icon: (
        <img src={liveRatesSvg} alt={"live rates"} width={25} height={25} />
      ),
      title: "LIVE RATES",
    },
    tradeHistory
      ? tradeHistory && {
          to: "/trade",
          icon: <img src={tradesSvg} alt={"Trades"} width={25} height={25} />,
          title: "TRADES",
        }
      : bookingDesk && {
          to: "/booking-desk",
          icon: (
            <img
              src={bookingDeskSvg}
              alt={"booking desk"}
              width={25}
              height={25}
            />
          ),
          title: "BOOKING DESK",
        },
    tradeHistory
      ? tradeHistory && {
          to: "/pending-orders",
          icon: (
            <img
              src={pendingOrderSvg}
              alt={"pending orders"}
              width={25}
              height={25}
            />
          ),
          title: "PENDING ORDERS",
        }
      : bankDetails && {
          to: "/bank-details",
          icon: (
            <img
              src={bankDetailsSvg}
              alt={"bank details"}
              width={25}
              height={25}
            />
          ),
          title: "BANK DETAILS",
        },
    notifications
      ? notifications && {
          to: "/notifications",
          icon: (
            <img
              src={notificationsSvg}
              alt={"Notifications"}
              width={25}
              height={25}
            />
          ),
          title: "NOTIFICATIONS",
        }
      : contactUs && {
          to: "/contact-us",
          icon: (
            <img src={contactUsSvg} alt={"contact us"} width={25} height={25} />
          ),
          title: "CONTACT US",
        },
  ].filter(Boolean);
  const isTablet = useDeviceType("tablet");
  return (
    <div
      className={`w-full h-[70px] bg-white flex justify-between items-center fixed bottom-0 z-10 ${
        isTablet ? "px-12" : "px-5"
      } py-5 shadow-md border-t-3`}
    >
      {bottomTabs?.map((item) => {
        return (
          <div className="flex flex-col items-center justify-center gap-1">
            <Link
              to={item?.to}
              className="flex flex-col justify-center items-center gap-1"
            >
              {item?.icon}{" "}
              <span className="text-[#3D3D3D] text-[12px] ">{item?.title}</span>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default BottomNavbar;
