import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: "",
  email: "",
  phoneNumber: "",
  whatsApp: "",
  playStoreLink:
    "https://play.google.com/store/apps/details?id=com.garnetlanee.gemlayliveratestrack",
  appStoreLink: "https://apps.apple.com/in/app/gemlay-bullion/id6633427721",
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClientData: (state, { payload }) => {
      if (payload) {
        state.address = payload.address;
        state.email = payload.email;
        state.phoneNumber = payload.phone;
        state.whatsApp = payload.whatsApp;
      }
    },
  },
});

//<---------------------getter------------------->
export const getClientAddress = (state) => state.client.address;
export const getClientEmail = (state) => state.client.email;
export const getClientphoneNumber = (state) => state.client.phoneNumber;
export const getClientPlayStoreLink = (state) => state.client.playStoreLink;
export const getClientAppStoreLink = (state) => state.client.appStoreLink;
export const getWhatsAppNumber = (state) =>
  state.client.whatsApp || state.client.phoneNumber;

export const { setClientData } = clientSlice.actions;

export default clientSlice.reducer;
