import axios from "axios";
import { api, apiEndPoints } from "../../../src/api/index";
import { CLIENT_URL } from "../../constants";

export const otpRequest = async (number, name) => {
  const response = await api.post(apiEndPoints.getOtp, {
    phone: number,
    name: name,
    userType: "employee",
    clientId: CLIENT_URL,
  });
  return response;
};

export const otpVerification = async (number, otp,ip) => {
  const response = await api.post(apiEndPoints.verifyOtp, {
    phone: number,
    otp: otp,
    clientId: CLIENT_URL,
    currentIp:ip
  });
  return response;
};

export const login = async (number, otp) => {
  const response = await api.post(apiEndPoints.login, {
    phone: `91${number}`,
    otp: otp,
    clientId: CLIENT_URL,
  });
  return response;
};
export const getLoggedUser = async (id) => { 
  if (id) {
    const response = id && (await api.get(apiEndPoints.customer(id)));
    return response;
  }
};
export const updateUser = async (info, id) => {
  // const  userID = localStorage?.getItem("userId")
  if (id) {
    const res = await api.put(apiEndPoints.customer(id), info);
    return res;
  }
};

// export const updateVisitor

export const updateUserWishList = async (wishList, cartItem, id) => {
  if (id) {
    const res = api.put(apiEndPoints.user(id), {
      wishlist: wishList,
      cart: cartItem,
    });
    return res;
  }
};
//cronberry api
export const PostToCronberry = async (payload) => {
  const options = {
    method: "POST",
    url: "https://api.cronberry.com/cronberry/api/campaign/register-audience-data",
    headers: {
      accept: "application/json",
    },
    data: {
      projectKey: "VW50aXRsZSBQcm9qZWN0MTY3NjYyMDU5Njc1OQ==",
      audienceId: payload?.phone,
      name: payload?.name || "",
      email: payload?.email || "",
      mobile: payload?.phone || "",
      ios_fcm_token: "",
      web_fcm_token: "",
      android_fcm_token: "",
      profile_path: "",
      active: "",
      audience_id: payload?.id,
      paramList: [
        {
          paramKey: "budget",
          paramValue: "",
        },
        {
          paramKey: "what_are_you_looking_for",
          paramValue: "",
        },
      ],
    },
  };
  var response = await axios(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
  return response;
};

export const googleLogin = async (googleData) => {
  const response = await api.post(
    apiEndPoints.googleReg,
    JSON.stringify(googleData)
  );
  return response;
};
export const facebookLogin = async (userData) => {
  if (userData.name && userData.email) {
    const response = await api.post(
      apiEndPoints.facebook,
      JSON.stringify(userData)
    );
    return response;
  }
};

export const searchCurentIp = async (pincode) => {
  const url = `https://api64.ipify.org/?format=json`;
  let result = {};
  await axios
    .get(url)
    .then((response) => {
      result = response;
    })
    .catch((error) => {
      result = {};
      console.log(error, " error");
    });
  return {
    data: result?.data,
  };
};
