import React from "react";
import NewsSection from "./NewsSection";
import { useDeviceType } from "../../../utils/useMobile";

function FullNewsPage() {
  const isDesktop = useDeviceType("desktop");
  return (
    <div
      className={`flex flex-col items-center w-full min-h-screen lg:mx-auto lg:w-4/5  ${!isDesktop ? "mt-40 mb-20" : "my-14"}`}
    >
      <div className="mt-10 w-full">
        <NewsSection limit={30} title={"latest news"} showLoadMore={false} />
      </div>
    </div>
  );
}

export default FullNewsPage;
