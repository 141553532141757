import { useState, useEffect } from "react";
import {
  useAddAlertMutation,
  useFetchClientScriptsQuery,
} from "../Store/alertApiSlice";
import toast from "react-hot-toast";
import { CLIENT_URL } from "../../../constants";

export const useClientScript = () => {
  const [selectedScript, setSelectedScript] = useState("");
  const [type, setType] = useState("price");
  const [buyValue, setBuyValue] = useState("true");
  const [value, setValue] = useState(0);
  const [condition, setCondition] = useState("greaterThan");
  const [addAlert] = useAddAlertMutation();
  const [selectedScriptData, setSelectedScriptData] = useState({});
  const { data = [], isLoading } = useFetchClientScriptsQuery();
  const scriptId = data?.map((script) => ({
    id: script._id,
    title: script.title,
  }));
  useEffect(() => {
    setSelectedScriptData(
      data?.find((script) => script._id === selectedScript),
    );
  }, [selectedScript, data]);
  const handleSubmit = () => {
    const user = JSON.parse(localStorage.getItem("LoggedInUser"));
    if (value == 0 || !selectedScript) {
      toast.error(value ? "Select an script" : "Enter price");
      return;
    }
    const multiplier = buyValue
      ? selectedScriptData?.buyMultiplier
      : selectedScriptData?.sellMultiplier;
    const additive = buyValue
      ? selectedScriptData?.buyAdditive
      : selectedScriptData?.sellAdditive;
    let triggerPrice = 0;
    if (type == "percentage") {
      triggerPrice = (value - additive) / multiplier;
    } else if (type == "price") {
      triggerPrice = (value - additive) / multiplier;
    }
    const title = selectedScriptData?.title;
    const data = {
      customerId: user?.userId,
      type,
      title,
      value,
      triggerPriceForRootScript: triggerPrice,
      rootScript: selectedScriptData?.rootScript,
      condition,
      clientId: CLIENT_URL,
      clientScriptId: selectedScript,
    };
    addAlert({ data });
  };

  return {
    selectedScript,
    setSelectedScript,
    condition,
    setCondition,
    type,
    setType,
    value,
    setValue,
    scriptId,
    loading: isLoading,
    selectedScriptData,
    handleSubmit,
  };
};
