import React from "react";

const MobileCardSkeleton = () => {
  return (
    <div className="flex flex-col w-full gap-2 px-2 mt-2">
      {/* <p>asdasd</p> */}
      <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
      <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
      <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
    </div>
  );
};

export default MobileCardSkeleton;
