import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CLIENT_URL } from "../../../constants";
import { api } from "../../../api";

export const fetchScriptTypes = createAsyncThunk(
  "home/scriptTypes",
  async () => {
    const { data } = await api.get(`/script-type/scriptTypes?id=${CLIENT_URL}`);
    return data.data;
  },
);

const initialState = {
  selectedScript: "gold",
  scriptTypes: [],
  scriptTotalPageCount: 1,
  scriptCurrentPageCount: 1,
  pageSize: 20,
};
const homePageSlice = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    setSelectedScript: (state, { payload }) => {
      if (state.selectedScript !== payload) {
        state.scriptCurrentPageCount = 1;
        state.selectedScript = payload;
      }
    },
    setCurrentPageCount: (state, { payload }) => {
      state.scriptCurrentPageCount = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScriptTypes.fulfilled, (state, { payload }) => {
      // localStorage.setItem("SCRYT_TYPES", JSON.stringify(payload));
      state.scriptTypes = payload;
      if (payload?.[0]?.title) {
        state.selectedScript = payload?.[0]?.title;
      }
    });
  },
});

//<------------------home page getters--------------->
export const getSelectedScript = (state) => state.homepage.selectedScript;
export const getScriptTypes = (state) => state.homepage.scriptTypes;
export const getPageCount = (state) => state.homepage.scriptCurrentPageCount;
export const getPageSize = (state) => state.homepage.pageSize;

//<------------------home page setters--------------->
export const { setSelectedScript, setCurrentPageCount } = homePageSlice.actions;

export default homePageSlice.reducer;
