import React from "react";
import gold1 from "../../../assets/cards/bars 1.png";
import gold2 from "../../../assets/cards/gold 3.png";
import goldRate from "../../../assets/cards/golddollar.png";
import dollar from "../../../assets/cards/dollar.png";
import silverRate from "../../../assets/cards/silverdollar.png";
import CardSkeleton from "./CardSkeleton";
import TableSkeleton from "./TableSkeleton";

const ScreenSkeleton = (props) => {
  const { isCoinScreen } = props;
  return (
    <>
      {!isCoinScreen && (
        <div className="flex justify-between items-center space-x-4 px-2 py-8">
          <div className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-60 text-center relative shadow-md">
            <div className="flex justify-center mb-2 border-[1px] border-gray-200 rounded-full h-[50px] w-[50px] absolute top-[-20px] left-[70px] z-30 bg-white items-center">
              <img
                src={goldRate}
                alt="Gold Icon"
                className="h-[25px] w-[25px]"
              />
            </div>
            <div className="text-gray-600 mt-5">GOLD ($)</div>
            <CardSkeleton />
          </div>

          <div
            className={`bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-60 text-center relative shadow-md`}
          >
            <div className="flex justify-center mb-2 border-[1px] border-gray-200 rounded-full h-[50px] w-[50px] absolute top-[-20px] left-[70px] z-30 bg-white items-center">
              <img
                src={silverRate}
                alt="Silver Icon"
                className="h-[30px] w-[30px]"
              />
            </div>
            <div className="text-gray-600 mt-5">SILVER ($)</div>
            <CardSkeleton />
          </div>

          <div className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-60 text-center relative shadow-md">
            <div className="flex justify-center mb-2 border-[1px] border-gray-200 rounded-full h-[50px] w-[50px] absolute top-[-20px] left-[70px] z-30 bg-white items-center">
              <img src={dollar} alt="Gold Icon" className="h-[25px] w-[25px]" />
            </div>
            <div className="text-gray-600 mt-5">USD INR</div>
            <CardSkeleton />
          </div>

          <div className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-60 text-center relative shadow-md">
            <div className="flex justify-center mb-2 border-[1px] border-gray-200 rounded-full h-[50px] w-[50px] absolute top-[-20px] left-[70px] z-30 bg-white items-center">
              <img src={gold1} alt="Silver Icon" />
            </div>
            <div className="text-gray-600 mt-5">SILVER COST</div>
            <CardSkeleton />
          </div>

          <div className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-60 text-center relative shadow-md">
            <div className="flex justify-center mb-2 border-[1px] border-gray-200 rounded-full h-[50px] w-[50px] absolute top-[-20px] left-[70px] z-30 bg-white items-center">
              <img src={gold2} alt="Gold Icon" />
            </div>
            <div className="text-gray-600 mt-5">GOLD COST</div>
            <CardSkeleton />
          </div>
        </div>
      )}
      <div className="flex h-full size-full px-4 flex-col mt-5">
        <div className="bg-custom-gradient h-[48px] rounded-t-[20px] size-full flex justify-between px-6 items-center">
          <div className="flex items-center justify-end ml-20 w-[2/3]">
            <p className="text-white text-xl">PRODUCT</p>
          </div>
          <div className="flex items-center justify-end w-1/3 gap-20">
            <div className="h-[40px] w-[1px] bg-white"></div>
            <p className="text-white text-xl">BUY</p>
          </div>
          <div className="flex items-center justify-center self-center w-1/3 gap-20">
            <div className="h-[40px] w-[1px] bg-white"></div>
            <p className="text-white text-xl">SELL</p>
          </div>
        </div>
        <TableSkeleton />;
      </div>
    </>
  );
};

export default ScreenSkeleton;
