import { useEffect, memo } from "react";
import { fetchUserLimits } from "../Store/limitSlice";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedScript } from "../../Home/State/homePageSlice";

function LimitContainer({ reference }) {
  const id = localStorage.getItem("userId");
  const userInfo = JSON.parse(localStorage.getItem("LoggedInUser"));
  const activeTab = useSelector(getSelectedScript);
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(fetchUserLimits(id));
    }
  }, [dispatch, id]);
  const marginLimit = useSelector(
    (state) => state.limit[activeTab.toLowerCase()],
  );
  return (
    userInfo &&
    marginLimit && (
      <div className=" mt-7 mr-3" ref={reference}>
        <div className="flex flex-col rounded-[20px]">
          <div className="bg-custom-gradient h-[40px] flex items-center justify-center rounded-t-xl">
            <p className="text-white">TRADING TERMINAL</p>
          </div>
          <div className="h-[200px] bg-white flex flex-col items-start justify-center rounded-b-[20px]">
            <div className="flex gap-3 w-full px-4 items-center">
              <label htmlFor="" className="text-[14px]">
                Total Margin :
              </label>
              <div className="bg-white w-2/3 border-2 rounded-lg h-[30px]">
                <p className="flex items-center justify-center">
                  {marginLimit?.limit}
                </p>
              </div>
            </div>
            <br />
            <div className="flex gap-3 w-full px-4 items-center">
              <label htmlFor="" className="text-[14px]">
                Used Margin:
              </label>
              <div className="bg-white w-2/3 border-2 rounded-lg h-[30px]">
                <p className="flex items-center justify-center">
                  {marginLimit?.used}
                </p>
              </div>
            </div>
            <br />
            <div className="flex gap-1 w-full px-4 items-center">
              <label className="text-[12px]" htmlFor="">
                Pending Margin :
              </label>
              <div className="bg-white w-2/3 border-2 rounded-lg h-[30px]">
                <p className="flex items-center justify-center">
                  {Number(marginLimit?.limit) - Number(marginLimit?.used)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default memo(LimitContainer);
