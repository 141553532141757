import React, { useContext, useState } from "react";
import { Modal, Button } from "antd";
import logoutImg from "../../assets/logout.svg";
import toast from "react-hot-toast";
import socket from "../../api/socket";
import { CLIENT_URL } from "../../constants";
import { AppContext } from "../../context/AppContext";

const LogoutModal = (props) => {
  const { isModalOpen, setModalOpen } = props;
  const { clearUser } = useContext(AppContext);

  const handleOk = () => {
    localStorage.clear();
    clearUser();
    toast.success("Logged Out");
    socket.emit("get-script-type", CLIENT_URL, "homePage");
    socket.on("client-script-types", (data) => {
      if (data?.isSuccess) {
        localStorage.setItem("SCRYT_TYPES", JSON.stringify(data?.data));
      }
    });
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} // Custom footer
        centered
        wrapClassName="rounded-lg"
        className="custom-modal bg-white p-6 rounded-lg  text-center"
        closeIcon={
          <span className="text-gray-500 hover:text-gray-700">&times;</span>
        }
      >
        <div className="text-center flex flex-col gap-5">
          <h2 className="text-3xl text-gray-900">LOGOUT</h2>
          <div className="flex justify-center items-center w-full">
            <img src={logoutImg} alt="" />
          </div>

          <p className="text-lg text-gray-700">
            Are you sure you want to Logout?
          </p>
          <div className="flex justify-center mt-6">
            <Button
              onClick={handleOk}
              className="bg-white border-2 border-black text-black px-12 py-5 rounded-full mr-4 hover:bg-gray-100"
            >
              OK
            </Button>
            <Button
              onClick={handleCancel}
              className="bg-custom-gradient text-white px-12 py-5 rounded-full hover:from-teal-600 hover:to-green-800"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LogoutModal;
