import { API_BASE_URL } from "../constants/index";
import { getBearerToken } from "../utils/token";
import { AUTH_HEADER_NAME } from "../constants/index";
import axios from "axios";
import { handleUnauthorizedError } from "./errorHandler";

const createAPI = () => {
  const apiHeader = {
    "Content-Type": "application/json",
  };
  const api = axios.create({
    baseURL: API_BASE_URL,
    headers: apiHeader,
  });
  api.interceptors.request.use(async (config) => {
    const bearerToken = await getBearerToken();
    if (bearerToken) {
      config.headers[`${AUTH_HEADER_NAME}`] = `${bearerToken}`;
    }
    const COUNTRY = localStorage.getItem("COUNTRY");
    if (!config.url?.includes("for future if you want any thing excluded")) {
      config.params = {
        ...config.params,
        country: COUNTRY,
      };
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (401 === error?.response?.status) {
        localStorage.removeItem("BEARER_TOKEN");
        localStorage.removeItem("LoggedInUser");
        localStorage.removeItem("userId");
        localStorage.removeItem("scId");
        handleUnauthorizedError();
        // store.dispatch(setUserId(null));
      }
      if (403 === error?.response?.status || 400 === error?.response?.status) {
        // logout();
        console.log(error?.response.data, "error");
      }
      throw error?.response?.data;
    },
  );
  return api;
};

export default createAPI();
