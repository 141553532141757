import React from "react";

export const NewsCard = ({ title, link, pubDate, tumbnail, source }) => {
  console.log("re-rendering multiple times in news comp");
  return (
    <div
      key={pubDate}
      className="flex items-start justify-between border-b pb-2 mb-4"
    >
      <div className="w-24 h-24 rounded-lg overflow-hidden">
        <img
          src={tumbnail || "https://placehold.co/24x24/000000/FFFFFF/png"}
          alt="cover img"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="w-full pl-4">
        <a href={link} target="_blank" rel="noreferrer">
          <h4 className="text-lg font-medium text-gray-900">{title}</h4>
        </a>
        <div className="flex items-center text-gray-500 text-sm mt-2">
          <span className="font-semibold">{source || "gold"}</span>
          <span className="mx-2">|</span>
          <span>{new Date(pubDate).toDateString()}</span>
        </div>
      </div>
    </div>
  );
};

// import React from "react";
//
// const BlogCard = ({ blogs }) => {
//   return (
//     <div className="p-4 bg-white rounded-lg shadow-lg cursor-pointer">
//       <div className="space-y-6">
//         {blogs.map((blog, index) => (
//           <div
//             key={index}
//             className="flex items-start justify-between border-b pb-2 mb-4"
//           >
//             <div className="w-full pr-4">
//               <div className="flex items-center mb-2">
//                 <div className="w-6 h-6 rounded-full overflow-hidden mr-2">
//                   <img
//                     src={blog.authorImg}
//                     alt="author img"
//                     className="w-full h-full object-cover"
//                   />
//                 </div>
//                 <span className="text-blue-500 text-sm font-semibold">
//                   {blog.author}
//                 </span>
//               </div>
//               <h4 className="text-lg font-medium text-gray-900">
//                 {blog.title}
//               </h4>
//               <div className="flex items-center text-gray-500 text-sm mt-2">
//                 <span className="font-semibold">{blog.category}</span>
//                 <span className="mx-2">|</span>
//                 <span>{blog.date}</span>
//               </div>
//             </div>
//             <div className="w-24 h-24 rounded-lg overflow-hidden">
//               <img
//                 src={blog.coverImg}
//                 alt="cover img"
//                 className="w-full h-full object-cover"
//               />
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
//
// export default BlogCard;

