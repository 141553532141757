import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useLoginMutation,
  useOtpRequestMutation,
  useOtpVerificationMutation,
} from "../Store/AuthApiSlice";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { setBearerToken } from "../../../utils/token";
import { useDispatch } from "react-redux";
import { setUserId } from "../Store/AuthSlice";
import { invalidateOnAuth } from "./useInValidateOnLogin";
import axios from "axios";

export const useAuthHook = () => {
  const [name, setName] = useState("");
  const [loc, setLoc] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [getOtp, { isLoading: loadingOtp, error: sendingOtpError }] =
    useOtpRequestMutation();
  const [authLogin, { isLoading }] = useLoginMutation();
  const [verifyOtp, { isLoading: lodingLogin }] = useOtpVerificationMutation();
  const phone = `91${phoneNumber}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setUser } = useContext(AppContext);
  const handleMobileNumberChange = (e) => {
    let number = e.target.value.slice(3);
    const value = number.replace(/\D/g, "");
    setPhoneNumber(value.slice(0, 10));
  };
  const handleOtpChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setOtp(value.slice(0, 4));
  };

  const inputValidator = () => {
    let msg = "";
    if (!name) {
      msg = "Name is required";
    } else if (!phoneNumber) {
      msg = "Phone number is required";
    } else if (phoneNumber.length != 10) {
      msg = "Phone number should be 10 digits";
    } else if (showOtp && !otp) {
      msg = "OTP is required";
    } else if (showOtp && otp.length != 4) {
      msg = "OTP should be 4 digits";
    }
    if (msg) {
      return { error: true, msg };
    } else {
      return { error: false };
    }
  };
  const handleSubmit = async () => {
    try {
      const check = inputValidator();
      if (check.error) {
        toast.error(check.msg);
        return;
      }
      if (showOtp) {
        verifyOtp({ phone, otp, userLocation: loc, country }).then(
          (payload) => {
            console.log("came here to show error top", payload);
            if (payload.data?.is_success) {
              console.log("came here to show error success", payload);
              localStorage.setItem("tempId", payload.id);
              authLogin({ phone, otp, userLocation: loc, country }).then(
                (data) => {
                  if (data.error) {
                    toast.error(data.error.msg);
                    return;
                  }
                  setUser(data.data);
                  localStorage.setItem(
                    "LoggedInUser",
                    JSON.stringify(data.data),
                  );
                  localStorage.setItem("userId", data.data.userId);
                  localStorage.setItem("COUNTRY", data?.data?.user?.country);
                  toast.success("Logged in successfully!!");
                  setBearerToken(data.data.token);
                  dispatch(setUserId(JSON.stringify(data.data)));
                  navigate("/");
                  // dispatch(fetchNotificationCount());
                  // dispatch(
                  //   notificationApiSlice.util.invalidateTags(["notificationsapi"]),
                  // );
                  invalidateOnAuth(dispatch);
                },
              );
            } else if (payload.error) {
              console.log("came here to show error if payload error", payload);
              toast.error(payload.error.msg);
              return;
            } else {
              toast.error("Some thing went wrong");
            }
          },
        );
        return;
      }
      setShowOtp(true);
      getOtp({ name, phone, country });
      toast.success(`OTP sent Successfully`);
    } catch (err) {
      if (showOtp) {
        toast.error("Error while logging in");
        return;
      }
      setShowOtp(false);
      toast.error("Error while sending otp");
    }
  };

  const getLocation = async () => {
    let result = {};
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          if (latitude && longitude) {
            await axios
              .get(
                `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${Number(
                  latitude,
                )}&lon=${Number(longitude)}`,
              )
              .then((response) => {
                setCountry(
                  (response?.data?.address?.country || "").toUpperCase(),
                );
                setLoc(response?.data?.display_name);
              });
          }
        },
        (error) => {
          result = {};
          console.error("Error getting user location:", error.message);
        },
      );
    } else {
      result = {};
      console.error("Geolocation is not supported by this browser.");
    }
  };
  useEffect(() => {
    getLocation();
  }, []);

  const handleResendOtp = async () => {
    try {
      const check = inputValidator();
      if (check.error) {
        toast.error(check.msg);
        return;
      }
      getOtp({ name, phone });
      toast.success("OTP re-sent successfully");
    } catch (e) {}
  };
  return {
    setName,
    handleOtpChange,
    otp,
    showOtp,
    phoneNumber,
    handleMobileNumberChange,
    handleResendOtp,
    loadingOtp,
    lodingLogin,
    handleSubmit,
  };
};
