import React from "react";
import { useDeviceType } from "../../../utils/useMobile";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedScript } from "../../Home/State/homePageSlice";
import { useEffect } from "react";
import { fetchUserLimits } from "../Store/limitSlice";

const LimitText = () => {
  const isMobile = useDeviceType("mobile");
  const id = localStorage.getItem("userId");
  const activeTab = useSelector(getSelectedScript);
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(fetchUserLimits(id));
    }
  }, [dispatch, id]);
  const marginLimit = useSelector(
    (state) => state.limit[activeTab.toLowerCase()],
  );
  return (
    <div
      className={`max-w-md mx-auto p-4 bg-white shadow-lg rounded-lg ${isMobile && "mb-4"}`}
    >
      <div className="space-y-2">
        <div className="flex justify-between border-b pb-2">
          <span className="text-gray-600 font-medium">Total Margin</span>
          <span className="text-gray-900 font-semibold ml-10">
            {marginLimit?.limit}
          </span>
        </div>
        <div className="flex justify-between border-b pb-2">
          <span className="text-gray-600 font-medium">Available Margin</span>
          <span className="text-green-600 font-semibold ml-10">
            {Number(marginLimit?.limit) - Number(marginLimit?.used)}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-gray-600 font-medium">Used Margin</span>
          <span className="text-red-600 font-semibold ml-10">
            {marginLimit?.used}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LimitText;
