import React, { useEffect, useRef, memo } from "react";
import NewsSection from "../../../Features/News/Components/NewsSection";
import { useDeviceType } from "../../../utils/useMobile";

function TradingViewWidget({ symbol, title }) {
  const container = useRef();
  const isDesktop = useDeviceType("desktop");
  let searchText =
    title === "?gold"
      ? "Gold market updates"
      : title === "?silver"
        ? "Silver market updates"
        : title === "?int"
          ? "INR new updates"
          : "";
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "width": "100%",
          "height": "100%",
          "symbol": "${symbol}",
          "interval": "D",
          "timezone": "Asia/Kolkata",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
    console.log("script of the trading view", script.innerHTML);
    container.current.appendChild(script);
    return () => container.current && container.current.remove();
  }, []);

  return (
    <div
      className={`flex flex-col items-center w-full min-h-screen lg:mx-auto lg:w-4/5  ${!isDesktop ? "mt-40 mb-20" : "my-14"}`}
    >
      {/* Chart Section */}
      <div
        className="flex flex-col justify-center items-center w-full bg-white shadow-lg rounded-lg p-4 lg:p-8"
        style={{ height: "500px" }}
      >
        <div
          className="tradingview-widget-container flex-grow w-full"
          ref={container}
        >
          <div className="tradingview-widget-container__widget h-full"></div>
        </div>
      </div>

      {/* News Section */}
      <div className="mt-10 w-full">
        <NewsSection
          type={searchText}
          limit={10}
          title={searchText}
          showLoadMore={false}
        />
      </div>
    </div>
  );
}

export default memo(TradingViewWidget);
