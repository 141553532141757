import { memo, useEffect } from "react";
import { NewsCard } from "./NewsCard";
import { useGetNewsQuery } from "../Hooks/getNewsApiSlice";
import { useNavigate } from "react-router-dom";

function NewsSection({
  type = "",
  limit = 2,
  title = "NEWS & EVENTS",
  showLoadMore = true,
  reference,
  setNewsLoaded,
  newsLoaded,
}) {
  const { data: news = [], isLoading } = useGetNewsQuery(type || "");
  const userInfo = JSON.parse(localStorage.getItem("LoggedInUser"));
  useEffect(() => {
    if (isLoading) return;
    setNewsLoaded && setNewsLoaded(true);
  }, [isLoading, newsLoaded]);
  const navigate = useNavigate();
  return (
    <div
      className={` flex flex-col mr-3  mb-4 ${userInfo ? "mt-7" : "mt-7"}`}
      ref={reference}
    >
      <div className="bg-custom-gradient flex items-center justify-center h-[40px] rounded-t-[20px]">
        <p className="text-lg text-white">
          {title.toUpperCase() || "NEWS & EVENTS"}
        </p>
      </div>
      <div
        className="flex items-start bg-white rounded-b-[20px] p-4
            "
      >
        <div className="flex flex-col w-full h-full">
          {news.map((news, index) => {
            if (index > limit) return;
            return (
              <NewsCard
                title={news.title}
                link={news.link}
                pubDate={news.pubDate}
                tumbnail={news.thumbnail}
                source={news.source}
                key={index}
              />
            );
          })}
          {showLoadMore && (
            <p
              onClick={() => {
                navigate("/news");
                window.scrollTo(0, 0);
              }}
              className="text-md font-semibold text-blue-600  text-center hover:cursor-pointer"
            >
              Load more ...
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(NewsSection);
