import { useDispatch, useSelector } from "react-redux";
import {
  getShowNotificationPanel,
  setNotificationPanel,
} from "../Store/notificationSlice";
import { useGetNotificationsQuery } from "../Store/notificationApiSlice";

export const useNotificationPanelHook = () => {
  const isOpen = useSelector(getShowNotificationPanel);
  const dispatch = useDispatch();
  const notificationHandler = () => {
    dispatch(setNotificationPanel(false));
  };

  return {
    isOpen,
    notificationHandler,
    // data,
  };
};

