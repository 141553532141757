import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getTrade } from "./api";
import { useLocation } from "react-router-dom";

const useTrade = () => {
  const user = JSON.parse(localStorage.getItem("LoggedInUser"));
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const location = useLocation();

  const payloadRef = useRef();

  useEffect(() => {
    payloadRef.current = {
      pagination: true,
      userId: user?.userId,
      pageNumber: currentPage,
      pageSize: pageSize,
      status: location.pathname == "/history" ? "completed" : "",
    };
    refetchTrade();
  }, []);

  useEffect(() => {
    payloadRef.current = {
      pagination: true,
      userId: user?.userId,
      pageNumber: currentPage,
      pageSize: pageSize,
    };
    refetchTrade();
  }, [pageSize, currentPage]);

  const {
    data: { data: { data: tradeOrder, pages, count } = {} } = {},
    refetch: refetchTrade,
    isLoading,
  } = useQuery("getTrade", () => getTrade(payloadRef.current), {
    enabled: false,
  });

  return {
    isLoading,
    tradeOrder,
    user,
    pages,
    count,
    pageSize,
    currentPage,
    setCurrentPage,
    setPageSize,
  };
};

export default useTrade;
