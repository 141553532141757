import React from "react";

const MobileTableSkeleton = () => {
  return (
    <>
      <div
        className={`flex flex-col h-[90px] justify-between rounded-xl
    mb-3 border-[1px] border-gray-300 size-full bg-white py-4 gap-4 px-4`}
      >
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
      </div>
      <div
        className={`flex flex-col h-[90px] justify-between rounded-xl
    mb-3 border-[1px] border-gray-300 size-full bg-white py-4 gap-4 px-4`}
      >
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
      </div>
      <div
        className={`flex flex-col h-[90px] justify-between rounded-xl
    mb-3 border-[1px] border-gray-300 size-full bg-white py-4 gap-4 px-4`}
      >
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
      </div>
      <div
        className={`flex flex-col h-[90px] justify-between rounded-xl
    mb-3 border-[1px] border-gray-300 size-full bg-white py-4 gap-4 px-4`}
      >
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
      </div>
      <div
        className={`flex flex-col h-[90px] justify-between rounded-xl
    mb-3 border-[1px] border-gray-300 size-full bg-white py-4 gap-4 px-4`}
      >
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
        <div className="flex items-center self-center justify-center h-[10px] w-full rounded-3xl bg-gray-300 animate-pulse"></div>
      </div>
    </>
  );
};

export default MobileTableSkeleton;
