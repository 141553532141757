import React from 'react'
import LiveChart from './Components/LiveChart'
import { useLocation } from 'react-router-dom';

const ChartPage = ({title}) => {
  const symbols={
    '?gold':"OANDA:XAUUSD",
    '?silver': "CAPITALCOM:SILVER",
    '?inr':"FX_IDC:USDINR"
  }
  return (
      <div>
      <LiveChart symbol={symbols[title]?symbols[title]:symbols["?gold"]} title={title}/>
      </div>
  )
}

export default ChartPage