import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, apiEndPoints } from "../../../api";

const initialState = {
  notificationCount: 0,
  showNotificationPanel: false,
};
export const fetchNotificationCount = createAsyncThunk(
  "notification/count",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.get(apiEndPoints.notificationCount);
      return data || {};
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationPanel: (state, { payload }) => {
      state.showNotificationPanel = payload;
    },
    resetNotificationCount: (state, { payload }) => {
      state.notificationCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationCount.fulfilled, (state, { payload }) => {
      if (payload?.is_success) {
        state.notificationCount = payload?.count || 0;
      }
    });
  },
});
export const getShowNotificationPanel = (state) =>
  state.notification.showNotificationPanel;
export const getNotificationCount = (state) =>
  state.notification.notificationCount;
export const { setNotificationPanel, resetNotificationCount } =
  notificationSlice.actions;
export default notificationSlice.reducer;
