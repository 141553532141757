import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../../api/axiosBaseQuery";
import { apiEndPoints, api } from "../../../api";

const notificationApiSlice = createApi({
  reducerPath: "notificationsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["notificationsapi"],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => ({ url: apiEndPoints.notification, method: "get" }),
      transformResponse: (response) => response.Notification,
      providesTags: ["notificationsapi"],
    }),
    updateViewedStatus: builder.mutation({
      query: (id) => ({
        url: `${apiEndPoints.notification}/${id}`,
        method: "post",
        data: { customerView: true },
      }),
      invalidatesTags: ["notificationsapi"],
    }),
  }),
});

export const { useGetNotificationsQuery, useUpdateViewedStatusMutation } =
  notificationApiSlice;
export default notificationApiSlice;
