export function checkKeys(obj, type) {
  const requiredShipKeys = [
    "receipentName",
    "street",
    "pincode",
    "city",
    "state",
    "country",
    "receipentNumber",
    "addressType",
  ];

  const requiredBillKeys = [
    "receipentName",
    "street",
    "pincode",
    "city",
    "state",
    "country",
    "receipentNumber",
  ];

  if (type == 'SHP') {
    for (const key of requiredShipKeys) {
        if (!obj.hasOwnProperty(key) || obj[key] == '') {
          return false; // If any required key is missing, return false
        }
      }
      return true;
  } else {
    for (const key of requiredBillKeys) {
        if (!obj.hasOwnProperty(key)) {
          return false; // If any required key is missing, return false
        }
      }
      return true;
  }

   // If all required keys are present, return true
}

export function allFieldsHaveValues(obj) {
  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) continue; // Skip prototype properties

    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      return false; // If any field is empty, return false
    }
  }
  return true; // If all fields have values, return true
}


export const required = (value) => {
  if (!value || !value.toString().trim().length) {
    return true;
  }
  return false;
};

export const hasValueInObject = (object, value) => {
  if (Object.values(object).includes(value)) {
    return true;
  }
  return false;
};

export const indexExists=(array, index) =>{
  return index >= 0 && index < array.length;
}
