import { useSelector } from "react-redux";
import { getSelectedScript } from "../State/homePageSlice";
import { useHandleCalculatePrice } from "../Hooks/useHandleCalculatePrice";
import NoScriptFound from "./NoScriptFound";
import NewsSection from "../../News/Components/NewsSection";
import { useHandleTradeRouting } from "../Hooks/useHandleTradeRouting";
import { getMargin } from "../../Access/Store/accessSlice";
import FooterPagination from "../../Pagination/components/pagination";
import { useClientScriptFetcher } from "../Hooks/useClientScriptFetcher";
import { Spinner } from "@heroui/react";

function ScriptsTable() {
  const { scripts, page, totalPages, updatePageCount, isLoading } =
    useClientScriptFetcher({ pagination: true });
  const showScript = useSelector(getSelectedScript);
  const filteredData = scripts.filter(
    (item) => item.scriptType.includes(showScript) && item.active,
  );
  if (isLoading) {
    return <Spinner type={"danger"} />;
  }
  if (filteredData.length === 0)
    return (
      <>
        <NoScriptFound />
        <NewsSection
          type={"Business and news"}
          limit={20}
          title={"BUSINESS & NEWS"}
          showLoadMore={false}
        />
      </>
    );
  return (
    <div className="flex  size-full px-4 flex-col mt-4">
      <div className="bg-custom-gradient h-[48px] rounded-t-[20px] size-full flex justify-between px-6 items-center">
        <div className="flex items-center justify-end ml-20 w-[2/3]">
          <p className="text-white text-xl">PRODUCT</p>
        </div>
        <div className="flex items-center justify-end w-1/3 gap-20">
          <div className="h-[40px] w-[1px] bg-white"></div>
          <p className="text-white text-xl">BUY</p>
        </div>
        <div className="flex items-center justify-center self-center w-1/3 gap-20">
          <div className="h-[40px] w-[1px] bg-white"></div>
          <p className="text-white text-xl">SELL</p>
        </div>
      </div>
      {/* map*/}
      {filteredData.map((item, index) => (
        <TableRow key={index} item={item} index={index} />
      ))}
      <div className="mx-auto">
        <FooterPagination
          currentPage={page}
          total={totalPages}
          onChange={updatePageCount}
        />
      </div>
      <NewsSection
        type={"Business and news"}
        limit={5}
        title={"BUSINESS & NEWS"}
        showLoadMore={false}
      />
    </div>
  );
}
export default ScriptsTable;

const TableRow = ({ item, index }) => {
  return (
    <div className="" key={index}>
      <div
        className={`flex h-[90px] justify-between ${
          index === 0 ? "rounded-b-2xl" : "rounded-2xl"
        }  mb-3 border-[1px] border-gray-300 size-full bg-white`}
      >
        <div className="w-1/3 flex items-center justify-start ml-20 gap-6">
          {/* <img src={gold2} alt="Gold Icon" /> */}
          <p>{item?.title}</p>
        </div>
        <Price type={"buy"} item={item} />
        <Price type={"sell"} item={item} />
      </div>
    </div>
  );
};

const Price = ({ type, item }) => {
  const { price, color, high, low } = useHandleCalculatePrice({
    type,
    item,
  });
  const canTrade = useSelector(getMargin);
  const { tradeChecker } = useHandleTradeRouting();
  return (
    <div className="flex flex-col gap-2 w-1/3 items-center justify-center">
      <div className="flex items-center justify-center mt-2 gap-1">
        {type === "buy" && item.buy === "-" ? (
          <p>{item?.buy}</p>
        ) : (
          <div
            className={`flex h-[30px] w-[100px] rounded-xl ${
              color === "red"
                ? "border-red-500"
                : color === "green"
                  ? "border-green-500"
                  : "border-gray-200"
            } border-[2px] items-center justify-center py-4 text-xl ${canTrade && "hover:cursor-pointer"}`}
            onClick={() => {
              canTrade && tradeChecker(item);
            }}
          >
            <p
              style={{
                fontWeight: 600,
                fontSize: "18px",
                color: `${color}`,
                transition: "color 0.1s ease-in-out",
              }}
            >
              {item.rootScript?.includes("$") || item.isCosting ? "$" : "₹"}
              {price && parseFloat(price).toFixed(1) % 1 !== 0
                ? parseFloat(price).toFixed(1)
                : parseFloat(price).toFixed(0)}
            </p>
          </div>
        )}
        <>
          {color == "red" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={`${color}`}
              class="size-6"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z"
                clip-rule="evenodd"
              />
            </svg>
          ) : color === "green" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={`${color}`}
              className="size-6"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="gray"
              className="size-6"
            >
              <path
                fillRule="evenodd"
                d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </>
      </div>
      {type === "buy" && item?.buy == "-" ? (
        <p className=" text-gray-500 text-[12px]">H: - | L: -</p>
      ) : (
        <p className=" text-gray-500 text-[12px]">
          <span className="text-green-500 font-medium">
            H:{high.toFixed(1)}
          </span>{" "}
          |<span className="text-red-500 font-medium"> L:{low.toFixed(1)}</span>
        </p>
      )}
    </div>
  );
};
