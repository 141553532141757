import React from "react";
import TradePage from "../container/Trade/Trade";

const Trade = () => {
  return (
    <>
      <TradePage />
    </>
  );
};

export default Trade;
