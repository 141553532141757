import React from "react";
import { useDeviceType, useMobile } from "../../utils/useMobile";
import useTrade from "./hooks";
import moment from "moment";
import { Pagination } from "antd";

const TradePage = () => {
  const isMobileScreen = useDeviceType("mobile");
  const isDesktop = useDeviceType("desktop");
  const isTablet = useDeviceType("tablet");
  const {
    tradeOrder,
    user,
    pages,
    count,
    pageSize,
    currentPage,
    setCurrentPage,
    setPageSize,
  } = useTrade();

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  console.log(user, "LogegdIndUser");

  return (
    <>
      {isDesktop && (
        <div className="flex flex-col items-start justify-center mb-5">
          <div className="h-full w-full flex mb-6">
            <div className="w-3/4 h-full">
              <div className="rounded-[20px] overflow-hidden shadow-md mt-5 ml-28 mr-20">
                <table className="w-full text-sm text-left text-gray-500 border-collapse border-spacing-0">
                  <thead className="">
                    <tr className="bg-custom-gradient text-white">
                      <th className="px-6 py-3 text-sm font-medium">SR</th>
                      <th className="px-6 py-3 text-sm font-medium">PRODUCT</th>
                      <th className="px-6 py-3 text-sm font-medium">
                        BUY/SELL
                      </th>
                      <th className="px-6 py-3 text-sm font-medium">QTY</th>
                      <th className="px-6 py-3 text-sm font-medium">PRICE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tradeOrder?.map((item, index) => {
                      if (item?.status !== "completed") {
                        return (
                          <tr
                            className="bg-login-color px-6 border-b-2 border-gray-300"
                            key={index}
                          >
                            <td className="px-6 py-4 text-sm font-medium text-gray-900">
                              {item?.srno}
                            </td>
                            <td className="px-6 py-4 text-sm font-medium text-gray-900 ">
                              <p>{item?.product}</p>
                              <br />
                              <span className="text-xs text-gray-500">
                                {moment(item?.createdAt).format(
                                  "DD-MM-YYYY hh:mm:ss A"
                                )}
                              </span>
                            </td>
                            <td className="px-6 py-4 text-sm font-medium text-gray-900 ">
                              {item?.tradeType?.toUpperCase()}
                            </td>
                            <td className="px-6 py-4 text-sm font-medium text-gray-900 ">
                              {item?.quantity}
                            </td>
                            <td className="px-6 py-4 text-sm font-medium text-gray-900 ">
                              {item?.price}
                              <br />
                              <span className="text-xs text-gray-500">
                                {item?.orderId}
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="w-1/4 mt-5 mr-10">
              <div className="h-1/3 mr-3">
                <div className="flex flex-col rounded-[20px]">
                  <div className="bg-custom-gradient h-[40px] flex items-center justify-center rounded-t-xl">
                    <p className="text-white">TRADING TERMINAL</p>
                  </div>
                  <div className="h-[200px] bg-login-color flex flex-col items-start justify-center rounded-b-[20px]">
                    <div className="flex gap-3 w-full px-4 items-center">
                      <label htmlFor="" className="text-[14px]">
                        Total Limit :
                      </label>
                      <div className="bg-white w-2/3 border-2 rounded-lg h-[30px]">
                        <p className="flex items-center justify-center">
                          {user?.user?.custLimit}
                        </p>
                      </div>
                    </div>
                    <br />
                    <div className="flex gap-3 w-full px-4 items-center">
                      <label htmlFor="" className="text-[14px]">
                        Used Limit:
                      </label>
                      <div className="bg-white w-2/3 border-2 rounded-lg h-[30px]">
                        <p className="flex items-center justify-center">
                          {user?.user?.usedLimit}
                        </p>
                      </div>
                    </div>
                    <br />
                    <div className="flex w-full px-4 items-center justify-center">
                      <label className="text-[15px]" htmlFor="">
                        Pending Limit :
                      </label>
                      <div className="bg-white w-2/3 border-2 rounded-lg h-[30px]">
                        <p className="flex items-center justify-center">
                          {Number(user?.user?.custLimit) -
                            Number(user?.user?.usedLimit)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            total={count}
            itemRender={itemRender}
            current={currentPage}
            showQuickJumper
            align="center"
            className="ml-32"
            onChange={(page, pageSize) => {
              setCurrentPage(page);
            }}
            pageSize={pageSize}
            onShowSizeChange={(current, size) => {
              setPageSize(size);
            }}
            showTotal={() => `Total ${count} Orders`}
          />
        </div>
      )}
      {(isMobileScreen || isTablet) && (
        <>
          <div className="mt-40">
            <div class="mb-4">
              <table class="min-w-full bg-white rounded-b-lg shadow">
                <thead class="bg-custom-gradient">
                  <tr>
                    <th class="p-2 text-left text-sm font-medium text-white">
                      SR
                    </th>
                    <th class="p-2 text-left text-sm font-medium text-white">
                      PRODUCT
                    </th>
                    <th class="p-2 text-left text-sm font-medium text-white">
                      BUY/SELL
                    </th>
                    <th class="p-2 text-left text-sm font-medium text-white">
                      QTY
                    </th>
                    <th class="p-2 text-left text-sm font-medium text-white">
                      PRICE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tradeOrder?.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr class="border-b bg-login-color">
                        <td class="p-2 text-sm text-gray-700 py-10" rowSpan={2}>
                          {item?.srno}
                        </td>
                        <td>
                          <p className="text-[15px] font-bold">
                            {item?.product}
                          </p>
                        </td>
                        <td>{item?.tradeType?.toUpperCase()}</td>
                        <td>{item?.quantity}</td>
                        <td>{item?.price}</td>
                      </tr>
                      <tr class="border-b bg-login-color">
                        <td className="text-[13px]" colSpan={2}>
                          {moment(item?.createdAt).format(
                            "DD-MM-YYYY hh:mm:ss A"
                          )}
                        </td>
                        <td className="text-[13px]" colSpan={2}>
                          {item?.orderId}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              total={count}
              itemRender={itemRender}
              current={currentPage}
              showQuickJumper
              align="center"
              className="ml-32"
              onChange={(page, pageSize) => {
                setCurrentPage(page);
              }}
              pageSize={pageSize}
              onShowSizeChange={(current, size) => {
                setPageSize(size);
              }}
              showTotal={() => `Total ${count} Orders`}
            />
          </div>
        </>
      )}
    </>
  );
};

export default TradePage;
