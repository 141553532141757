import { API_BASE_URL } from "../constants/index";
import axios from "axios";

export const setBearerToken = (token) => {
  return localStorage.setItem("BEARER_TOKEN", `${token}`);
};
export const getBearerToken = () => {
  const token = localStorage.getItem("BEARER_TOKEN");
  return token;
};

export const getLoginUserType = () => {
  const userType =
    typeof window !== "undefined" && localStorage?.getItem("USER_TYPE");

  return userType;
};
export const getLoginUserAcess = () => {
  const userAccess =
    typeof window !== "undefined" &&
    JSON.parse(
      localStorage?.getItem("USER_ACCESS")
        ? localStorage?.getItem("USER_ACCESS")
        : "{}"
    );
  return userAccess;
};
export const removeBearerToken = () => {
  localStorage.removeItem("userData");
  return localStorage.removeItem("BEARER_TOKEN");
};
export const handleLogOut = async () => {
  // const router = useRouter();
  removeBearerToken();
  localStorage.removeItem("userType");
  localStorage.removeItem("userInfo");
  // router.reload("/");
};

export const handleVisitor = async (userId) => {
  const response = await axios.get(
    `${API_BASE_URL}/visitor/getId?userId=${userId}`
  );

  if (response?.data?.visitorId !== undefined) {
    localStorage.setItem("visitorId", response.data.visitorId);
  }
};
