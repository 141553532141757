import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../../api/axiosBaseQuery";
import toast from "react-hot-toast";
import { apiEndPoints } from "../../../api";
const user = JSON.parse(localStorage.getItem("LoggedInUser"));

const alertApiSlice = createApi({
  reducerPath: "alert",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAlerts: builder.query({
      query: () => ({
        url: `/alert/${user?.userId}`,
        method: "GET",
        params: {},
      }),
      transformResponse: (response) => response.alerts,
      providesTags: ["AllAlerts"],
    }),
    fetchClientScripts: builder.query({
      query: () => ({
        url: apiEndPoints.clientScript,
        method: "GET",
        params: {
          pagination: false,
          clientId: process.env.REACT_APP_PUBLIC_CLIENT,
          admin: true,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    addAlert: builder.mutation({
      query: ({ data }) => ({ url: `/alert`, method: "POST", data: data }),
      async onQueryStarted({ data }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          toast.success("Alert created successfully");
          dispatch(alertApiSlice.util.invalidateTags(["AllAlerts"]));
        } catch (err) {
          console.log(err);
          toast.error("Cannot create alert");
        }
      },
      invalidatesTags: ["AllAlerts"],
    }),
  }),
});

export const {
  useGetAlertsQuery,
  useAddAlertMutation,
  useFetchClientScriptsQuery,
} = alertApiSlice;
export default alertApiSlice;
