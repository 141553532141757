import { fetchScriptTypes } from "../../Home/State/homePageSlice";
import { fetchNotificationCount } from "../../Notifications/Store/notificationSlice";
import notificationApiSlice from "../../Notifications/Store/notificationApiSlice";
import clientScriptAPI from "../../Home/State/ClientScriptsApi";

export const invalidateOnAuth = (dispatch) => {
  dispatch(fetchScriptTypes());
  dispatch(fetchNotificationCount());
  dispatch(notificationApiSlice.util.invalidateTags(["notificationsapi"]));
  dispatch(
    clientScriptAPI.util.invalidateTags(["ClientScripts", "rootScripts"]),
  );
};
