// import React,{useState} from 'react'
// import { chattingSokcet } from '../../../api/socket'

// function ChattingPage() {
//     const [text,setText]=useState()
//     const handleSendMessage=()=>{
//         console.log('connected to server websocker',text)
//         chattingSokcet.emit("sendMessage",text)
//     }
//   return (
//     <div>
//         <input type='text' onChange={(e)=>setText(e.target.value)} value={text}/>
//         <button onClick={handleSendMessage}>submit</button>
//     </div>
//   )
// }

// export default ChattingPage
