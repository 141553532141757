import NewsSection from "../../News/Components/NewsSection";
import LimitContainer from "../../UserLimit/Components/LimitContainer";
import ScriptsTable from "./ScriptsTable";
import AllScriptsAvailable from "./AllScriptsAvailable";
import { RootDataCards } from "./RootDataCards";
import PredictionContainer from "../../Prediction/Components/PredicionContainer";
import { useSelector } from "react-redux";

import MobileHome from "./Mobile/MobileHome";
import { getMargin } from "../../Access/Store/accessSlice";
import { useDeviceType } from "../../../utils/useMobile";
import TabletScreen from "./Tablet/TabletScreen";
import { useLayoutEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { useState } from "react";

const HomePage = () => {
  const isMobileScreen = useDeviceType("mobile");
  const isDesktop = useDeviceType("desktop");
  const isTablet = useDeviceType("tablet");

  const containerRef = useRef(null);
  const limitContainerRef = useRef(null);
  const newsSectionRef = useRef(null);
  const predictionSectionRef = useRef(null);
  const rightContainerRef = useRef(null);
  const [newsLoaded, setNewsLoaded] = useState(false);
  useEffect(() => {
    if (!rightContainerRef.current) return;

    const rightObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        requestAnimationFrame(() => {
          if (containerRef.current) {
            containerRef.current.style.height = `${rightContainerRef.current.scrollHeight}px`;
          }
        });
      }
    });

    rightObserver.observe(rightContainerRef.current);

    // containerRef.current.style.height = `${rightContainerRef.current.offsetHeight}px`;
    const timmer = setTimeout(() => {
      containerRef.current.style.height = "auto";
      containerRef.current.style.height = `${rightContainerRef.current.offsetHeight}px`;
    }, 0);
    return () => {
      // rightObserver.disconnect();
      clearTimeout(timmer);
    };
  }, [newsLoaded]);
  const canSeeMargin = useSelector(getMargin);

  return (
    <>
      <>
        {isDesktop && (
          <div className="overflow-hidden">
            <div
              className="flex bg-[#F3F3F3] flex-container overflow-hidden"
              ref={containerRef}
            >
              <div className=" w-3/4 h-[100%] px-6 py-6 flex flex-col overflow-y-auto">
                <div className="flex border-[1px]  bg-custom-gradient justify-around px-6 rounded-full border-gray-200 py-2  shadow-sm">
                  <AllScriptsAvailable />
                </div>
                <RootDataCards />

                <ScriptsTable />

                {/* <ScriptsTable /> */}
              </div>

              <div
                className="w-1/4 h-full flex-fixed-height"
                ref={rightContainerRef}
              >
                {canSeeMargin ? (
                  <LimitContainer reference={limitContainerRef} />
                ) : (
                  <></>
                )}
                <NewsSection
                  reference={newsSectionRef}
                  setNewsLoaded={setNewsLoaded}
                  newsLoaded={newsLoaded}
                />
                <PredictionContainer reference={predictionSectionRef} />
              </div>
            </div>
          </div>
        )}
        {isMobileScreen && <MobileHome />}
        {isTablet && <TabletScreen />}
      </>
    </>
  );
};

export default HomePage;
