import { useSelector } from "react-redux";
import {
  getClientAddress,
  getClientAppStoreLink,
  getClientEmail,
  getClientphoneNumber,
  getClientPlayStoreLink,
} from "../../Store/clientSlice";

export const useClientData = () => {
  const address = useSelector(getClientAddress);
  const phone = useSelector(getClientphoneNumber);
  const email = useSelector(getClientEmail);
  const playStoreLink = useSelector(getClientPlayStoreLink);
  const appStoreLink = useSelector(getClientAppStoreLink);
  return { address, phone, email, playStoreLink, appStoreLink };
};
