import React, { useContext } from "react";
import profile from "../../assets/drawerProfile.png";
import { AppContext } from "../../context/AppContext";
import { AWS_S3_URL } from "../../constants";

const DrawerContent = () => {
  const { user } = useContext(AppContext);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="flex w-full bg-custom-gradient h-[60px] items-center justify-start px-6 gap-3">
        <img src={profile} alt="profile" className="h-[30px] w-[30px]" />
        <div className="">
          {user ? (
            <p className="text-white text-xl font-semibold">
              Hi, {user?.user?.name}
            </p>
          ) : (
            <div className="">
              <a href="/login" className="text-white text-xl font-semibold">
                LOGIN
              </a>
            </div>
          )}
        </div>
      </div>
      <div
        className="flex flex-col justify-start
       items-start w-full px-6"
      >
        <div className="border-b-1 w-full py-2">
          <a
            href="/"
            className="flex  justify-start items-center gap-4 w-full py-2"
          >
            <img
              src={AWS_S3_URL + "/header/liverate.svg"}
              alt="location"
              className="w-[30px] h-[30px]"
            />
            <span className="text-[#3D3D3D] text-[15px] ">LIVE RATES</span>
          </a>
        </div>

        <div className="border-b-1 w-full py-2">
          <a
            href="/trade"
            className="flex  justify-start items-center gap-4 w-full py-2"
          >
            <img
              src={AWS_S3_URL + "/header/orders.svg"}
              alt="location"
              className="w-[30px] h-[30px]"
            />
            <span className="text-[#3D3D3D] text-[15px] ">TRADES</span>
          </a>
        </div>

        <div className="border-b-1 w-full py-2">
          <a
            href="/"
            className="flex  justify-start items-center gap-4 w-full py-2"
          >
            <img
              src={AWS_S3_URL + "/header/pendingorders.svg"}
              alt="location"
              className="w-[30px] h-[30px]"
            />
            <span className="text-[#3D3D3D] text-[15px] ">PENDING LIMIT</span>
          </a>
        </div>

        <div className="border-b-1 w-full py-2">
          <a
            href="/"
            className="flex  justify-start items-center gap-4 w-full py-2"
          >
            <img
              src={AWS_S3_URL + "/header/messages.svg"}
              alt="location"
              className="w-[30px] h-[30px]"
            />
            <span className="text-[#3D3D3D] text-[15px] ">HISTORY</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DrawerContent;
