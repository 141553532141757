import { api, apiEndPoints } from "../../../api";
import { CLIENT_URL } from "../../../constants";
import { ACCESS_KEYS } from "../../../constants/PlanConstants";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  liveRates: true,
  margin: 0,
  tradesPerCustomer: 0,
  limitTradesPerCustomer: 0,
  tradeHistory: false,
  notifications: false,
  bankDetails: true,
  contactUs: true,
  bookingDesk: true,
  tdsCalculator: false,
  priceAlerts: 0,
  chartsNews: true,
  isLoading: true,
};

export const fetchAccess = createAsyncThunk("access/fetchAccess", async () => {
  try {
    const { data } = await api.get(apiEndPoints.getAccess, {
      params: { clientId: CLIENT_URL },
    });
    console.log("data from the getaccess", data);
    return data.plan;
  } catch (e) {}
});

const accessSlice = createSlice({
  name: "access",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccess.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.liveRates = payload[ACCESS_KEYS.LIVE_RATES] ?? state.liveRates;
      state.margin = payload[ACCESS_KEYS.MARGIN] ?? state.margin;
      state.tradesPerCustomer =
        payload[ACCESS_KEYS.TRADES_PER_CUSTOMER] ?? state.tradesPerCustomer;
      state.limitTradesPerCustomer =
        payload[ACCESS_KEYS.LIMIT_TRADES_PER_CUSTOMER] ??
        state.limitTradesPerCustomer;
      state.tradeHistory =
        payload[ACCESS_KEYS.TRADE_HISTORY] ?? state.tradeHistory;
      state.notifications =
        payload[ACCESS_KEYS.NOTIFICATIONS] ?? state.notifications;
      state.bankDetails =
        payload[ACCESS_KEYS.BANK_DETAILS] ?? state.bankDetails;
      state.contactUs = payload[ACCESS_KEYS.CONTACT_US] ?? state.contactUs;
      state.bookingDesk =
        payload[ACCESS_KEYS.BOOKING_DESK] ?? state.bookingDesk;
      state.tdsCalculator =
        payload[ACCESS_KEYS.TDS_CALCULATOR] ?? state.tdsCalculator;
      state.priceAlerts =
        payload[ACCESS_KEYS.PRICE_ALERTS] ?? state.priceAlerts;
      state.chartsNews = payload[ACCESS_KEYS.CHARTS_NEWS] ?? state.chartsNews;
      state.isLoading = false;
    });
  },
});
//<-----------------getters----------------------------->
export const getCanSeeLiveRates = (state) => state.access.liveRates;
export const getMargin = (state) => state.access.margin;
export const getTradesPerCustomer = (state) => state.access.tradesPerCustomer;
export const getLimitTradesPerCustomer = (state) =>
  state.access.limitTradesPerCustomer;
export const getCanSeeTradeHistory = (state) => state.access.tradeHistory;
export const getCanSeeNotifications = (state) => state.access.notifications;
export const getCanSeeBankDetails = (state) => state.access.bankDetails;
export const getCanSeeContactUs = (state) => state.access.contactUs;
export const getCanSeeBookingDesk = (state) => state.access.bookingDesk;
export const getCanSeeTdsCalculator = (state) => state.access.tdsCalculator;
export const getCanSeePriceAlerts = (state) => state.access.priceAlerts;
export const getCanSeeChartsNews = (state) => state.access.chartsNews;

export const {} = accessSlice.actions;

export default accessSlice.reducer;
