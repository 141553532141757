import React, { useEffect, useRef, useState } from "react";
import { API_BASE_URL } from "../../constants";
import MarqueeContent from "../../components/Marquee/Marquee";
import DOMPurify from "dompurify";
import { getMarquee } from "./api";
const MarqueeContainer = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const marqueeRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getMarquee();
        setData(response?.data?.marquees);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data?.length);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [data?.length]);

  const handleMouseOver = () => {
    if (marqueeRef.current) {
      marqueeRef.current.stop();
    }
  };

  const handleMouseOut = () => {
    if (marqueeRef.current) {
      marqueeRef.current.start();
    }
  };

  return (
    <div>
      <div className="w-full  h-[40px] bg-custom-gradient flex items-center justify-center">
        <p className="text-xl text-white">LIVE RATES</p>
      </div>
      <div className="w-full h-[50px] bg-row-background flex items-center justify-center">
        <marquee
          ref={marqueeRef}
          behavior="scroll"
          direction="left"
          scrollamount="7"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {data.length > 0 && (
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data[currentIndex]?.content),
              }}
            ></span>
          )}
        </marquee>
      </div>
    </div>
  );
};

export default MarqueeContainer;
