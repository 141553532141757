export const ACCESS_KEYS = {
  LIVE_RATES: "live_rates",
  MARGIN: "margin",
  TRADES_PER_CUSTOMER: "trades_per_customer",
  LIMIT_TRADES_PER_CUSTOMER: "limit_trades_per_customer",
  TRADE_HISTORY: "trade_history",
  NOTIFICATIONS: "notifications",
  BANK_DETAILS: "bank_details",
  CONTACT_US: "contact_us",
  BOOKING_DESK: "booking_desk",
  TDS_CALCULATOR: "tds_calculator",
  PRICE_ALERTS: "price_alerts",
  CHARTS_NEWS: "charts_news",
  CLIENT_PANEL: "client_panel",
};
