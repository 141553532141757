import CryptoJS from "crypto-js";

// Encrypt data before sending it to the server

// Send encryptedData to the server

export const encyptData = (dataToEncrypt, key) => {
  const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, key).toString();

  return encryptedData;
};
