export const priceDeviationCalculator = ({
  price,
  basePrice,
  scriptData,
  type,
}) => {
  const diff = Number(price) - Number(basePrice);
  const additive =
    type === "buy" ? scriptData?.buyAdditive : scriptData?.sellAdditive;
  const multiplicative =
    type === "buy" ? scriptData?.buyMultiplier : scriptData?.sellMultiplier;
  let calculateRootPrice =
    (Number(basePrice) + diff - additive) / multiplicative;
  console.log(
    "this for the testing",
    price,
    basePrice,
    scriptData,
    diff,
    additive,
    multiplicative,
    calculateRootPrice,
  );
  return calculateRootPrice;
};
