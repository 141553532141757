import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useSelector } from "react-redux";
import {
  getCanSeeBankDetails,
  getCanSeeBookingDesk,
  getCanSeeChartsNews,
  getCanSeeContactUs,
  getCanSeePriceAlerts,
  getCanSeeTdsCalculator,
} from "../../Features/Access/Store/accessSlice";
import chartSvg from "../../assets/chart.svg";
import bankDetailsSvg from "../../assets/bankDetials.svg";
import contactUsSvg from "../../assets/contact-us.svg";
import bookingDeskSvg from "../../assets/bookingDesk.svg";
import alertSvg from "../../assets/alert.svg";
import calculatorSvg from "../../assets/calculator.svg";
import newsSvg from "../../assets/news.svg";

const Checking = () => {
  const [visible, setVisible] = useState(false);
  const dropdownRef = useRef(null);
  const bankDetails = useSelector(getCanSeeBankDetails);
  const contactUs = useSelector(getCanSeeContactUs);
  const bookingDesk = useSelector(getCanSeeBookingDesk);
  const tdsCalculator = useSelector(getCanSeeTdsCalculator);
  const alert = useSelector(getCanSeePriceAlerts);
  const chart = useSelector(getCanSeeChartsNews);
  const news = useSelector(getCanSeeChartsNews);
  const list = [
    bankDetails && {
      to: "/bank-details",
      title: "Bank Details",
      icon: (
        <img
          src={bankDetailsSvg}
          alt="bankDetials"
          className="h-[18px] w-[18px]"
        />
      ),
    },
    contactUs && {
      to: "/contact-us",
      title: "Contact Us",
      icon: (
        <img src={contactUsSvg} alt="chart" className="h-[18px] w-[18px]" />
      ),
    },
    bookingDesk && {
      to: "/booking-desk",
      title: "Booking Desk",
      icon: (
        <img src={bookingDeskSvg} alt="chart" className="h-[18px] w-[18px]" />
      ),
    },
    tdsCalculator && {
      to: "/tds-calculator",
      title: "Tds Calculator",
      icon: (
        <img src={calculatorSvg} alt="chart" className="h-[18px] w-[18px]" />
      ),
    },
    alert && {
      to: "/alert",
      title: "Alert",
      icon: <img src={alertSvg} alt="chart" className="h-[18px] w-[18px]" />,
    },
    chart && {
      to: "/chart",
      title: "Chart",
      icon: <img src={chartSvg} alt="chart" className="h-[18px] w-[18px]" />,
    },
    news && {
      to: "/news",
      title: "News",
      icon: <img src={newsSvg} alt="chart" className="h-[18px] w-[18px]" />,
    },
  ].filter(Boolean);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="py-[7px] mx-2 w-full relative flex flex-col items-center gap-2"
      role="button"
      ref={dropdownRef}
      onClick={() => setVisible(!visible)}
    >
      <div className="flex items-center justify-center flex-col gap-1">
        <img src={"/more.png"} alt="location" className="w-[25px] h-[25px]" />
        <span className="text-[#3D3D3D] text-[10px]">MORE</span>
      </div>

      {visible && (
        <div
          id="dropdownNavbar"
          className="absolute bg-gray-400 top-full mt-1 z-50 font-normal bg-white divide-y divide-gray-100 rounded-lg shadow-sm w-44 dark:bg-gray-700 dark:divide-gray-600"
        >
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
            {list.map((item) => {
              return (
                <ListItems
                  to={item.to}
                  title={item.title}
                  icon={item?.icon}
                  onClick={() => setVisible(false)}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Checking;

const ListItems = ({ to, title, icon }) => {
  return (
    <li>
      <Link
        to={to}
        className=" px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white flex"
      >
        <span>{icon}</span>
        <span className="ml-2">{title}</span>
      </Link>
    </li>
  );
};
