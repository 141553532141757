import { useClientScript } from "../Hooks/useClientScript";
import { useDeviceType } from "../../../utils/useMobile";
import { useHandleCalculatePrice } from "../../Home/Hooks/useHandleCalculatePrice";

const AddAlert = () => {
  const {
    selectedScript,
    setSelectedScript,
    scriptId,
    loading,
    selectedScriptData,
    value,
    setValue,
    type,
    setType,
    condition,
    setCondition,
    handleSubmit,
  } = useClientScript();
  const isDesktop = useDeviceType("desktop");

  return (
    <div
      className={`max-w-sm mx-auto p-6 bg-white rounded-lg shadow-lg ${!isDesktop ? "mt-40 mb-20" : "my-16"}`}
    >
      <h3 className="text-xl font-semibold text-center mb-4">Create Alert</h3>
      {/* Script Type (Dropdown) */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Script Type:
        </label>
        <select
          value={selectedScript}
          onChange={(e) => {
            debugger;
            setSelectedScript(e.target.value);
          }}
          className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-md"
        >
          <option value="">Select Script</option>
          {scriptId.map((script) => (
            <option key={script.id} value={script.id}>
              {script.title}
            </option>
          ))}
        </select>
      </div>

      {/* Live Price */}
      <LivePrices item={selectedScriptData} />

      {/* Alert Type */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Alert Type:
        </label>
        <select
          value={type}
          onChange={(e) => setType(e.target.value)}
          className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-md"
        >
          <option value="percentage">Percentage</option>
          <option value="price">Price</option>
        </select>
      </div>

      {/* Alert Value */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Alert Value:
        </label>
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
          placeholder="Enter value"
          className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-md"
        />
      </div>

      {/* Condition */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Condition:
        </label>
        <select
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
          className="w-full mt-2 px-4 py-2 border border-gray-300 rounded-md"
        >
          <option value="greaterThan">Greater Than</option>
          <option value="lessThan">Less Than</option>
          <option value="equalTo">Equal To</option>
        </select>
      </div>

      {/* Submit Button */}
      <div className="text-center">
        <button
          onClick={handleSubmit}
          disabled={loading}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="w-4 h-4 border-4 border-t-4 border-white rounded-full animate-spin"></div>
            </div>
          ) : (
            "Create Alert"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddAlert;

const LivePrices = ({ item }) => {
  const { price, color } = useHandleCalculatePrice({ type: "buy", item });
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
        Live Price:
      </label>
      <input
        type="text"
        value={price}
        disabled
        className={`w-full mt-2 px-4 py-2 border border-gray-300 rounded-md text-${color}-700 text-gray-700 bg-gray-100`}
      />
    </div>
  );
};
