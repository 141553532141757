import { useSelector } from "react-redux";
import { getWhatsAppNumber } from "../../Store/clientSlice";
function FloatingWhatsApp() {
  const phone = useSelector(getWhatsAppNumber);

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 1000,
      }}
    >
      <a
        // href={`whatsapp://send?phone=919999999999`}
        href={`https://wa.me/${phone}?text=Hello`}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={"/WhatsAppLogo.svg"}
          alt="whats app logo"
          className="w-[50px] h-[50px]"
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.35)",
            borderRadius: "20%",
          }}
        />
      </a>
    </div>
  );
}

export default FloatingWhatsApp;
