import { createApi } from "@reduxjs/toolkit/query/react";
import { apiEndPoints } from "../../../api";
import { CLIENT_URL } from "../../../constants";
import axiosBaseQuery from "../../../api/axiosBaseQuery";

const clientScriptAPI = createApi({
  reducerPath: "clientScript",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["ClientScripts", "rootScripts"],
  endpoints: (builder) => ({
    getClientScripts: builder.query({
      query: ({ page, limit, pagination, scriptType }) => ({
        url: apiEndPoints.allClientScripts,
        method: "GET",
        params: { id: CLIENT_URL, page, limit, pagination, scriptType },
      }),
      providesTags: ["ClientScripts"],
      transformResponse: (res) => res,
    }),
    getRootScripts: builder.query({
      query: () => ({
        url: apiEndPoints.rootScripts,
        method: "GET",
        params: { id: CLIENT_URL },
      }),
      providesTags: ["rootScripts"],
      transformResponse: (res) => res.data,
    }),
  }),
});

export const { useGetClientScriptsQuery, useGetRootScriptsQuery } =
  clientScriptAPI;

export default clientScriptAPI;
