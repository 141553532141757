import React, { useState } from "react";
import { AWS_CLIENT_CODE, AWS_S3_URL } from "../../constants";
import flag from "../../assets/flag.png";
import { useLoginHooks } from "./hook";
import { useDeviceType } from "../../utils/useMobile";

const LoginPage = () => {
  const {
    setPhone,
    phone,
    handleSubmit,
    form,
    handleChange,
    handleGetOtpButton,
    otpMessage,
    verifyOtp,
    otp,
    setOtp,
    openPage,
  } = useLoginHooks();

  const [otpData, setOtpData] = useState(new Array(4).fill(""));

  const handleOtpChange = (e, index) => {
    if (isNaN(e.target.value)) return false;

    setOtpData([
      ...otpData?.map((data, indx) => (indx == index ? e.target.value : data)),
    ]);

    if (e.target.value && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  const handleOtpSubmit = () => {
    setOtp();
    verifyOtp(otpData.join(""));
  };

  const isTablet = useDeviceType("tablet");
  const isMobile = useDeviceType("mobile");

  return (
    <div
      className={`h-full w-full bg-white flex items-center md:items-start justify-center ${
        isTablet ? "mt-64" : isMobile ? "mt-44" : "mt-10"
      }  md:mb-10`}
    >
      <div
        className={`flex flex-col bg-login-color ${
          openPage == "" ? "h-[520px]" : "h-[430px]"
        }  w-[350px] lg:w-[400px] border-2 border-primary-800 rounded-3xl items-center gap-8`}
      >
        <div className="flex items-center justify-center w-full mt-10">
          <img
            src={`${AWS_S3_URL}/clients/${AWS_CLIENT_CODE}/logo.png`}
            alt=""
            className="w-[200px] h-[50px]"
          />
        </div>
        <p className="text-primary-800 font-bold">LOGIN / SIGNUP </p>
        {openPage == "" ? (
          <div className="flex w-full flex-col px-12 gap-2">
            <input
              type="text"
              className="py-2 px-5 rounded-2xl mb-2 focus:outline-none focus:ring-0"
              placeholder="Name"
              name="name"
              onChange={handleChange}
            />

            <div className="">
              <div className="flex bg-white py-2 rounded-2xl px-3">
                <div className="flex w-[50px] gap-2 px-1 items-center">
                  <img src={flag} alt="flag" className="w-[15px] h-[15px]" />
                  <div className="h-[15px] w-[2px] bg-gray-700"></div>
                </div>
                <p className="mr-2 text-gray-500">+91</p>
                <input
                  type="text"
                  className="w-[140px] focus:outline-none focus:ring-0"
                  placeholder="Phone Number"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="flex justify-end mt-1 mr-2">
                <p className="text-[12px] underline cursor-pointer">
                  Change Number
                </p>
              </div>
            </div>

            {/* <div className="">
              <input
                type="text"
                className="py-2 px-3 rounded-2xl w-full"
                placeholder="Enter OTP"
              />
              <div className="flex justify-end mt-1 mr-2">
                <p className="text-[12px] underline cursor-pointer">
                  Resend OTP
                </p>
              </div>
            </div> */}
          </div>
        ) : (
          <div className="w-full flex items-center justify-center gap-4">
            {otpData?.map((data, i) => (
              <input
                type="text"
                maxLength={1}
                value={data}
                onChange={(e) => handleOtpChange(e, i)}
                className="w-[60px] h-[60px] p-3 outline-none text-center rounded-2xl focus:border focus:border-gray-600"
              />
            ))}
          </div>
        )}
        <p className="px-20 text-[12px] text-[#012433]">
          By continuing, I agree to the Terms of Use & Privacy Policy.
        </p>
        <div className="w-full flex justify-center items-center">
          {openPage == "" ? (
            <button
              className="px-28  py-2 bg-custom-gradient text-white rounded-2xl"
              onClick={handleGetOtpButton}
            >
              SUBMIT
            </button>
          ) : (
            <button
              className="px-28 py-2 mt-5 bg-custom-gradient text-white rounded-2xl"
              onClick={handleOtpSubmit}
            >
              Verify
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
