export default function TermsAndConditions() {
  const currentDomain =
    typeof window !== "undefined" ? window.location.hostname : "";
  const companyName = currentDomain;
  const providedBy = (
    <a href="" className="text-blue-400">
      provided by Gemlay Bullion
    </a>
  );

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-6 md:px-16">
      <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-2xl p-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          Privacy Policy
        </h1>
        <p className="text-gray-600 mb-4">
          {companyName} offers gold and silver prices obtained from various
          sources believed to be reliable, but we do not guarantee their
          accuracy. Our gold and silver price information is provided without
          warranty or representation of reliability. It is received by the site
          visitor on the understanding that errors or omissions shall not be the
          basis of any claim, demand, or cause for action.
        </p>

        <h2 className="text-xl font-semibold text-gray-700 mt-6">
          Intellectual Property
        </h2>
        <p className="text-gray-600 mb-4">
          {companyName} {providedBy} retains copyright to all contents of this
          site, including images. All trademarks, logos, and other intellectual
          property are owned or licensed by us (unless otherwise stated).
        </p>

        <h2 className="text-xl font-semibold text-gray-700 mt-6">
          Usage Restrictions
        </h2>
        <p className="text-gray-600 mb-4">
          You cannot copy, reproduce, distribute, republish, download, display,
          post, or transmit any portion of the website without written
          permission from us. You may print or download any page(s) for your
          personal and non-commercial use only.
        </p>

        <h2 className="text-xl font-semibold text-gray-700 mt-6">
          Liability Disclaimer
        </h2>
        <p className="text-gray-600 mb-4">
          Although our site is as precise as possible, we cannot be held liable
          for any errors or inaccuracies outside of our reasonable control.
        </p>
        <p className="text-gray-600 mb-4">
          In no event shall the website owner, nor any third party or data or
          content provider, be liable to you or any other person, firm, or
          corporation for any loss, liability, damage (whether direct or
          consequential), personal injury, or expense of any nature arising from
          any delays, inaccuracies, errors in, or omission of any bullion price
          information or its transmission.
        </p>

        <h2 className="text-xl font-semibold text-gray-700 mt-6">Agreement</h2>
        <p className="text-gray-600 mb-4">
          By accessing this site, you agree and accept these terms and
          conditions. If you have questions about our privacy policy, feel free
          to contact {providedBy} support.
        </p>
      </div>
    </div>
  );
}
