import { useEffect, useState, useContext, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getlivePrice,
  getSingleScriptPrice,
  getScriptQty,
  CreateOrder, getLoggedUser
} from "./api";
import useForm from "../../hooks/useForm";
import toast from "react-hot-toast";
import { CLIENT_URL, toastSettings } from "../../constants";
import socket from "../../api/socket";
import { AppContext } from "../../context/AppContext";
import {

  hasValueInObject,
  required,
  requiredString,
} from "../../utils/formhelper";
import { useNavigate } from "react-router-dom";

const useOrder = () => {
  const [count, setcount] = useState(0);
  const [scData, setSc] = useState(0);
  const [isFetched, setIsFetched] = useState(false);
  const [loader, setLoader] = useState(false);
  const [oldRateval, setOldRateVal] = useState(0);
  const { form, handleChange, setForm, setInForm } = useForm(null);
  const { user, clearUser, setUser } = useContext(AppContext);
  const userInfos = localStorage.getItem("userId");
  const Router = useNavigate();
  useEffect(() => {
    setForm({ ...form, limitType: "market" });
    priceRefetch();
    if (localStorage.getItem("scId")) {
      setSc(localStorage.getItem("scId"));
    }
  }, []);

  useEffect(() => {
    console.log(form, 'form')

  }, []);

  const dataRef = useRef(null);

  useEffect(() => {
    console.log(form, "form");
  }, [form]);

  useEffect(() => {
    if (scData && scData != 0) {
      socket.emit("single-script", CLIENT_URL, count, scData, "tradePage");
      setcount(1);
    }
  }, [scData]);

  useEffect(() => {
    setTimeout(() => {
      if (count != 0) {
        setcount(count + 1);
        // priceRefetch();
        socket.on("data-single-script", (data) => {

          if (data?.isSuccess) {
            dataRef.current = data?.data;
          }
        });
      }
      if (count && count == 1) {
        qtyRefetch();
      }
    }, 1000);
  }, [count]);

  const {
    data: { data: { data: liverates } = {} } = {},
    refetch: priceRefetch,
    isLoading,
    isFetching,
  } = useQuery("fetchOffer", () => getSingleScriptPrice(count, scData), {
    enabled: false,
    onSuccess: (data) => {
      setIsFetched(true);
    },
  });

  const { data: { data: { data: qtyData } = {} } = {}, refetch: qtyRefetch } =
    useQuery("fetchQty", () => getScriptQty(count, scData), {
      enabled: false,
      onSuccess: (data) => { },
    });

  const handleColor = (index, field) => {
    const oldData = oldRateval && oldRateval[index][field];
    const liveData = liverates[index][field];

    let sign = Math.sign(liveData - oldData);
    if (sign === 1) {
      return "green";
    } else if (sign === -1) {
      return "red";
    } else {
      return "black";
    }
  };

  const handleSCode = (sc) => {
    let scriptCode = sc;
    localStorage.setItem("scId", scriptCode);
  };

  const handleChangeLimitType = (val) => {
    setForm({ ...form, limitType: val });
  };

  const findFormErrors = (data) => {
    const newErrors = {};
    newErrors.quantity = required(data?.quantity);
    if (data?.limiType == "limit") {
      newErrors.limitPrice = required(data?.limitPrice);
    }

    return newErrors;
  };

  const handleSubmit = (val, price) => {

      const newErrors = findFormErrors(form);

      if (hasValueInObject(newErrors, true)) {
        if (newErrors.quantity == true) {
          toast.error("Quantity is required");
        } else if (newErrors.limitPrice == true) {
          toast.error("Limit Price is required");
        }
        // return;
      } else {
        setLoader(true);
        let payload = {
          ...form,
          clientScriptId: scData,
          tradeType: val,
          price: price,
          customerId: userInfos,
        };
        console.log(payload, "payload");
        addTradeMutate(payload);
      }
    
  };

  const resetform = () => {
    setForm({});
    Router("/");
  };
  const { mutate: loggedUser } = useMutation((id) => getLoggedUser(id), {
    onSuccess: (data) => {
      const { data: { storeEmployee: { usedLimit, custLimit } = {} } = {} } = data;
      console.log(data, 'data', usedLimit);

      let loggedinUser = JSON.parse(localStorage.getItem('LoggedInUser'));
      loggedinUser.user.usedLimit = usedLimit;
      localStorage.setItem('LoggedInUser', JSON.stringify(loggedinUser));
    },
    // dispatch(setLoggedUserDetails(data?.data?.user)),
    enabled: false,
  });
  const { mutate: addTradeMutate } = useMutation(
    (payload) => CreateOrder(payload),
    {
      onSuccess: (data) => {
        if (data?.data?.is_success) {
          resetform();
          setLoader(false);
          loggedUser(userInfos);
          toast.success(data?.data?.msg);
        }
      },
      onError: (error) => {
        if (error == "Unauthorized") {
          toast.error("Session Expired please login again", toastSettings);
          localStorage.clear();
          clearUser();
          setLoader(false);
        }


      },
    }
  );

  return {
    isLoading,
    count,
    handleColor,
    handleChangeLimitType,
    isFetching,
    isFetched,
    liverates,
    oldRateval,
    handleSCode,
    qtyData,
    form,
    handleChange,
    handleSubmit,
    dataRef,loader
  };
};

export default useOrder;
