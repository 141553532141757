import React, { useEffect, useState, useContext, useRef } from "react";
import { useMutation,useQuery } from "react-query";
import toast from "react-hot-toast";
import { setBearerToken } from "../../utils/token";
import { getBankdetails } from "./api";

export const useHooks = (props) => {
  useEffect(() => {
 refetch();
  }, []);
  const {
    data: { 
      
      data: { accounts, count: counts } = {}
     } = {},
    refetch,
  } = useQuery("accounts", () => getBankdetails(), {
    enabled: false,
    onSuccess: (data) => {
      console.log(data?.data,'data');
      
      if (data?.data?.is_success) {
      }
    },
    onError: async (data) => {
 
    },
  });




  return {
    accounts
  };
};
