import { useDispatch, useSelector } from "react-redux";
import { useGetClientScriptsQuery } from "../State/ClientScriptsApi";
import {
  getPageCount,
  getPageSize,
  getSelectedScript,
  setCurrentPageCount,
} from "../State/homePageSlice";

export const useClientScriptFetcher = ({ pagination }) => {
  const page = useSelector(getPageCount);
  const limit = useSelector(getPageSize);
  const scriptType = useSelector(getSelectedScript);
  const dispatch = useDispatch();
  const updatePageCount = (num) => {
    dispatch(setCurrentPageCount(num));
  };

  const { data: response, isLoading } = useGetClientScriptsQuery({
    page,
    limit,
    pagination,
    scriptType: scriptType.toUpperCase(),
  }) ?? { data: [], currentPage: 1, totalPages: 1 };
  const scripts = pagination ? response?.data || [] : response;
  const totalPages = response?.totalPages || 1;
  return { scripts, page, totalPages, updatePageCount, isLoading };
};
