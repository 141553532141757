import { useSelector } from "react-redux";
import { useLivePrice } from "../../LivePrice/Hooks/useLivePrice";

export const useHandleCalculatePrice = ({ type, item }) => {
  const multiplier =
    type === "buy" ? item?.buyMultiplier : item?.sellMultiplier;
  const addition = type === "buy" ? item?.buyAdditive : item?.sellAdditive;
  const { price } = useLivePrice(item?.rootScript);
  const high = (price?.high || 0) * (multiplier || 1) + (addition || 0);
  const low = (price?.low || 0) * (multiplier || 1) + (addition || 0);
  const doller =
    useSelector((state) => state.liveRates["INR (₹)"])?.commodity || 0;
  if (item?.isCosting) {
    const total = total1Price(
      price?.commodity,
      item?.premium,
      item?.multiplier,
    );
    const totalcustom = totalCustomPrice(total, doller, item?.customDuty);
    const gstval =
      (Number(totalcustom) * Number(item?.gst ? item?.gst : 0)) / 100;
    const totalaftergst = (Number(totalcustom) + gstval)?.toFixed(2);
    const onegm = (Number(totalaftergst) / 100)?.toFixed(2);
    let liveratesval = Number(onegm);
    const finalPrice = liveratesval * (multiplier || 1) + (addition || 0);

    return {
      price: finalPrice,
      color: price?.buyColor || "black",
      high,
      low,
    };
  }
  const finalPrice =
    (price?.commodity || 0) * (multiplier || 1) + (addition || 0);
  return {
    price: finalPrice,
    color: price?.buyColor || "black",
    high,
    low,
  };
};

const total1Price = (comex, premium, multiplier) => {
  let val =
    (Number(comex) + Number(premium ? premium : 0)) * Number(multiplier);
  return (Math.ceil(val * 100) / 100)?.toFixed(2)?.toString();
};

const totalCustomPrice = (total, rate, customduty) => {
  let val = Number(total) * Number(rate ? rate : 0) + Number(customduty);
  return (Math.ceil(val * 100) / 100)?.toFixed(2)?.toString();
};
