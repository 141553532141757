import React from "react";
import { useGetNotificationsQuery } from "../Store/notificationApiSlice";
import { useNotificationPanelHook } from "../Hooks/useNotificationPanelHook";
import { useNotificatoinCardHook } from "../Hooks/useNotificationCardHook";
import { useDeviceType } from "../../../utils/useMobile";

function NotificationScreen() {
  const { notificationHandler, isOpen } = useNotificationPanelHook();
  const isDesktop = useDeviceType("desktop");
  const { data = [] } = useGetNotificationsQuery();
  return (
    <>
      <div
        className={`flex items-center justify-between ${!isDesktop && "mt-40"}`}
      >
        <p className="text-xl sm:text-2xl font-semibold leading-6 text-gray-800">
          Notifications
        </p>
        {isOpen && isDesktop && (
          <button
            aria-label="close modal"
            className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 rounded-md cursor-pointer"
            onClick={() => notificationHandler()}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#4B5563"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#4B5563"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>

      <div
        className={`mt-8 space-y-4  overflow-y-auto h-full ${!isDesktop && "mb-20"}`}
      >
        {data.map((item) => (
          <NotificationItem item={item} key={item._id} />
        ))}
      </div>
    </>
  );
}

export default NotificationScreen;

const NotificationItem = ({ item }) => {
  const { handleNotificationClick, timePassed } = useNotificatoinCardHook(item);
  return (
    <div
      className={`w-full p-3 ${item.customerView ? "bg-white" : "bg-blue-50"} border rounded flex items-center space-x-3 hover:cursor-pointer justify-center`}
      onClick={handleNotificationClick}
    >
      <div className="flex-shrink-0 w-8 h-8 border rounded-full border-gray-200 flex items-center justify-center">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00059 3.01934C9.56659 1.61334 11.9866 1.66 13.4953 3.17134C15.0033 4.68334 15.0553 7.09133 13.6526 8.662L7.99926 14.3233L2.34726 8.662C0.944589 7.09133 0.997256 4.67934 2.50459 3.17134C4.01459 1.662 6.42992 1.61134 8.00059 3.01934Z"
            fill="#EF4444"
          />
        </svg>
      </div>

      <div className="min-w-0 flex-1">
        <p className="text-sm whitespace-normal break-words">
          <span className="text-indigo-700">{item.title}</span> {item.message}
        </p>
        <p className="text-xs pt-1 text-gray-500">{timePassed}</p>
      </div>
    </div>
  );
};
