import React, { useState, useCallback } from "react";
import { useDeviceType } from "../../utils/useMobile";

const TdsTcsCalculator = () => {
  const [selectedKarat, setSelectedKarat] = useState("TDS");
  const [weight, setWeight] = useState("");
  const [rate, setRate] = useState("");
  const [result, setResult] = useState(null);
  const isDesktop = useDeviceType("desktop");

  const OPTION = ["TDS", "TCS"];

  const handleKaratChange = useCallback((karat) => {
    setSelectedKarat(karat);
  }, []);

  const reset = useCallback(() => {
    setSelectedKarat("TDS");
    setWeight("");
    setResult(null);
    setRate("");
  }, []);

  const calculate = useCallback(() => {
    if (!weight || !rate) return;

    const goldWeightAmount = Number(weight) * Number(rate);
    const tdsAmount = Math.ceil(goldWeightAmount * (0.097 / 100));
    const tcsAmount = Math.ceil(goldWeightAmount * (0.1 / 100));
    const tdsFinalAmount = Math.ceil(goldWeightAmount - tdsAmount);
    const tcsFinalAmount = Math.ceil(goldWeightAmount + tcsAmount);
    const totalAmount =
      selectedKarat === "TDS"
        ? goldWeightAmount - tdsAmount
        : goldWeightAmount + tcsAmount;

    setResult({
      goldWeight: weight,
      goldWeightAmount: Math.ceil(goldWeightAmount),
      taxToShow:
        selectedKarat === "TDS" ? Math.ceil(tdsAmount) : Math.ceil(tcsAmount),
      tdsAmount: tdsAmount.toFixed(2),
      tcsAmount: tcsAmount.toFixed(2),
      finalAmount: Math.ceil(totalAmount),
      tdsFinalAmount,
      tcsFinalAmount,
    });
  }, [weight, rate, selectedKarat]);

  return (
    <div
      className={`p-6 bg-gray-50 min-h-screen ${!isDesktop && "mt-40 mb-20"}`}
    >
      <h1 className="text-center text-2xl font-bold mb-6 text-gray-800">
        TDS/TCS Calculator
      </h1>

      <div className="mb-6">
        <h3 className="text-lg font-medium text-gray-700 mb-2">
          Choose your type of TDS / TCS
        </h3>
        <div className="flex space-x-4">
          {OPTION.map((option) => (
            <label key={option} className="flex items-center space-x-2">
              <input
                type="radio"
                name="karat"
                value={option}
                checked={selectedKarat === option}
                onChange={() => handleKaratChange(option)}
                className="form-radio h-5 w-5 text-blue-600"
              />
              <span className="text-gray-700">{option}</span>
            </label>
          ))}
        </div>
      </div>

      <p className="text-sm text-gray-600 mb-2">
        {selectedKarat === "TDS"
          ? "Note: 0.1% TDS calculated on taxable amount (excluding GST)."
          : "Note: 0.1% TCS calculated on taxable amount (including GST)."}
      </p>
      <p className="text-sm text-gray-600 mb-6">
        {selectedKarat === "TDS" ? "TDS = 0.1%" : "TCS = 0.1%"}
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Weight (Grams):
          </label>
          <input
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            placeholder="Enter Gold Weight"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Rate / Gram:
          </label>
          <input
            type="number"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
            placeholder="Enter Rate"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      <div className="flex space-x-4">
        <button
          onClick={reset}
          className="px-6 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
        >
          Reset
        </button>
        <button
          onClick={calculate}
          className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Calculate
        </button>
      </div>

      {result && (
        <div className="mt-8 p-6 bg-white shadow rounded-md">
          <h3 className="text-lg font-medium text-gray-800 mb-4">
            Calculation Result:
          </h3>
          <div className="space-y-2">
            <p className="text-sm text-gray-700">
              <strong>Total Weight:</strong> {result.goldWeight} Gram
            </p>
            <p className="text-sm text-gray-700">
              <strong>Total Amount:</strong> ₹ {result.goldWeightAmount}
            </p>
            <p className="text-sm text-gray-700">
              <strong>Total {selectedKarat}:</strong> ₹{" "}
              {selectedKarat === "TDS" ? result.tdsAmount : result.tcsAmount}
            </p>
            <h3 className="text-xl font-bold text-blue-600 mt-4">
              Payable Amount: ₹{" "}
              {selectedKarat === "TDS"
                ? result.tdsFinalAmount
                : result.tcsFinalAmount}
            </h3>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
        TDS/TCS Calculator
      </h1>
      <div style={{ marginBottom: "20px" }}>
        <h3>Choose your type of TDS / TCS</h3>
        <div>
          {OPTION.map((option) => (
            <label key={option} style={{ marginRight: "20px" }}>
              <input
                type="radio"
                name="karat"
                value={option}
                checked={selectedKarat === option}
                onChange={() => handleKaratChange(option)}
              />
              {option}
            </label>
          ))}
        </div>
      </div>
      <p>
        {selectedKarat === "TDS"
          ? "Note: 0.1% TDS calculated on taxable amount (excluding GST)."
          : "Note: 0.1% TCS calculated on taxable amount (including GST)."}
      </p>
      <p>{selectedKarat === "TDS" ? "TDS = 0.1%" : "TCS = 0.1%"}</p>
      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <div>
          <label>
            Weight (Grams):{" "}
            <input
              type="number"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              placeholder="Enter Gold Weight"
            />
          </label>
        </div>
        <div>
          <label>
            Rate / Gram:{" "}
            <input
              type="number"
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              placeholder="Enter Rate"
            />
          </label>
        </div>
      </div>
      <div style={{ display: "flex", gap: "20px" }}>
        <button
          onClick={reset}
          style={{ padding: "10px 20px", backgroundColor: "#f0f0f0" }}
        >
          Reset
        </button>
        <button
          onClick={calculate}
          style={{
            padding: "10px 20px",
            backgroundColor: "#4CAF50",
            color: "#fff",
          }}
        >
          Calculate
        </button>
      </div>
      {result && (
        <div
          style={{
            marginTop: "20px",
            border: "1px solid #ddd",
            padding: "20px",
          }}
        >
          <h3>Calculation Result:</h3>
          <p>Total Weight: {result.goldWeight} Gram</p>
          <p>Total Amount: ₹ {result.goldWeightAmount}</p>
          <p>
            Total {selectedKarat}: ₹{" "}
            {selectedKarat === "TDS" ? result.tdsAmount : result.tcsAmount}
          </p>
          <h3 style={{ color: "#4CAF50" }}>
            Payable Amount: ₹{" "}
            {selectedKarat === "TDS"
              ? result.tdsFinalAmount
              : result.tcsFinalAmount}
          </h3>
        </div>
      )}
    </div>
  );
};

export default TdsTcsCalculator;
