import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationCount,
  setNotificationPanel,
} from "../Store/notificationSlice";

function NotificationIcon() {
  const dispatch = useDispatch();
  const handleOpenNotificationPanel = () => {
    dispatch(setNotificationPanel(true));
  };
  return (
    <div
      className="py-[7px] mx-2 flex w-full flex-col gap-2 items-center"
      role="button"
      onClick={handleOpenNotificationPanel}
    >
      <div className="relative flex justify-center flex-col gap-1 ">
        <svg
          width="25"
          height="25"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.17413 12.0624C1.00293 11.1256 1.00293 10.4168 1.00293 7.4C1.00293 4.3832 1.00293 2.8744 2.17413 1.9376C3.34693 1 5.23173 1 9.00293 1C12.7741 1 14.6597 1 15.8309 1.9376C17.0029 2.8744 17.0029 4.3832 17.0029 7.4C17.0029 10.4168 17.0029 11.1256 15.8309 12.0624C14.6605 13 12.7741 13 9.00293 13C6.99493 13 5.96293 14.3904 4.20293 15.4V12.8304C3.32773 12.7 2.68373 12.4704 2.17413 12.0624Z"
            stroke="#4F4E4E"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {/* <svg */}
        {/*   className="w-[25px] h-[25px]" */}
        {/*   xmlns="http://www.w3.org/2000/svg" */}
        {/*   fill="currentColor" */}
        {/*   viewBox="0 0 24 24" */}
        {/* > */}
        {/*   <path */}
        {/*     d="M18 8a6 6 0 0 0-12 0c0 7-3 9-3 9h18s-3-2-3-9" */}
        {/*     fill="none" */}
        {/*     stroke="currentColor" */}
        {/*     strokeWidth="2" */}
        {/*     strokeLinecap="round" */}
        {/*     strokeLinejoin="round" */}
        {/*   ></path> */}
        {/*   <path */}
        {/*     d="M13.73 21a2 2 0 0 1-3.46 0" */}
        {/*     fill="none" */}
        {/*     stroke="currentColor" */}
        {/*     strokeWidth="2" */}
        {/*     strokeLinecap="round" */}
        {/*     strokeLinejoin="round" */}
        {/*   ></path> */}
        {/* </svg> */}
        <CountBadge />
      </div>
      <span className="text-[#3D3D3D] text-[10px]">NOTIFICATION</span>
    </div>
  );
}

const CountBadge = () => {
  const count = useSelector(getNotificationCount);
  return (
    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-3 -right-3">
      {JSON.stringify(count)}
    </div>
  );
};

export default NotificationIcon;
