// import { useState, useEffect, useCallback } from "react";

// export const useMobile = () => {
//   const [width, setWidth] = useState(0);
//   // checks if its a mobile browser, return bool
//   const detectMob = () => {
//     const toMatch = [
//       /Android/i,
//       /webOS/i,
//       /iPhone/i,
//       /iPad/i,
//       /iPod/i,
//       /BlackBerry/i,
//       /Windows Phone/i,
//     ];
//     return toMatch.some((toMatchItem) => {
//       return navigator.userAgent.match(toMatchItem);
//     });
//   };
//   // const hasWindow = typeof window !== "undefined";

//   const handleWindowSizeChange = useCallback(() => {
//     if (detectMob()) {
//       setWidth(window.screen.width);
//     } else {
//       setWidth(window.screen.width);
//     }
//   }, []);

//   useEffect(() => {
//     handleWindowSizeChange();
//     window.addEventListener("resize", handleWindowSizeChange);
//     return () => {
//       window.removeEventListener("resize", handleWindowSizeChange);
//     };
//   }, [handleWindowSizeChange]);

//   return width > 0 && width <= 767;
// };

import { useState, useEffect, useCallback } from "react";

export const useDeviceType = (type) => {
  const [isMatch, setIsMatch] = useState(false);

  // const detectMob = () => {
  //   const toMatch = [
  //     /Android/i,
  //     /webOS/i,
  //     /iPhone/i,
  //     /iPad/i,
  //     /iPod/i,
  //     /BlackBerry/i,
  //     /Windows Phone/i,
  //   ];
  //   return toMatch.some((toMatchItem) => {
  //     return navigator.userAgent.match(toMatchItem);
  //   });
  // };
  //
  const handleWindowSizeChange = useCallback(() => {
    const screenWidth = window.screen.width;

    let match = false;
    if (type === "mobile") {
      // detectMob() ||
      match = screenWidth <= 767;
    } else if (type === "tablet") {
      match = screenWidth > 767 && screenWidth <= 1024;
    } else if (type === "desktop") {
      match = screenWidth > 1024;
    }

    setIsMatch(match);
  }, [type]);

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  return isMatch;
};
