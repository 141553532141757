import React, { useEffect, useState } from "react";
import gold1 from "../../../../assets/cards/bars 1.png";
import gold2 from "../../../../assets/cards/gold 3.png";
import goldRate from "../../../../assets/cards/golddollar.png";
import dollar from "../../../../assets/cards/dollar.png";
import silverRate from "../../../../assets/cards/silverdollar.png";
import ScreenSkeleton from "../../../../components/Skeletons/Desktop/ScreenSkeleton";
import CardSkeleton from "../../../../components/Skeletons/Desktop/CardSkeleton";
import TableSkeleton from "../../../../components/Skeletons/Desktop/TableSkeleton";
import { useNavigate } from "react-router-dom";
import { useDeviceType, useMobile } from "../../../../utils/useMobile";

const MainScreen = (props) => {
  const isMobileScreen = useDeviceType("mobile");
  const isDesktop = useDeviceType("desktop");
  const {
    handleColor,
    liverates,
    isSkeleton,
    handleSCode,
    handleSocket,
    activeTab,tradeChecker,oldrates
  } = props;


  const [active, setActive] = useState(false);
  const roots = liverates?.filter((item) => item?.type == "root");

  const Router = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 2200);
  }, [activeTab]);

  console.log(liverates, "MAIN_SCREEN_DATA");

  return (
    <>
      {!active ? (
        <ScreenSkeleton />
      ) : (
        <>
          <div className="flex justify-between items-center space-x-4 px-2 py-8">
            {roots?.length > 0 &&
              liverates?.map((rate, index) => {
                if (rate?.type == "root") {
                  return (
                    <div key={index} className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-full text-center shadow-md flex flex-col items-center justify-center">
                      <div className="flex justify-center mb-2 border-[1px] border-gray-600 rounded-full h-[50px] w-[50px] z-30 bg-white items-center mt-[-30px]">
                        <img
                          src={goldRate}
                          alt="Gold Icon"
                          className="h-[25px] w-[25px]"
                        />
                      </div>
                      <div className="text-gray-600 mt-5">{rate?.product}</div>
                      <>
                        <>
                          <div key={index}>
                            {isDesktop && (
                              <div
                                className=""
                                style={{ height: "15px", width: "10px" }}
                              ></div>
                            )}
                            {handleColor(index, "sell") && (
                              <>
                                {liverates?.length>0 ? (
                                  <p
                                    style={{
                                      color: `${handleColor(index, "sell")}`,
                                      transition: "color 0.1s ease-in-out",
                                      fontSize: `${
                                        isMobileScreen ? "30px" : "24px"
                                      }`,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {handleColor(index, "sell",true)}
                                  </p>
                                ) : (
                                  <div className="h-[30px] rouned-3xl w-[120px]bg-gray-400 animate-pulse"></div>
                                )}
                              </>
                            )}
                          </div>
                          <div
                            className={` rounded-full py-[5px] px-4 mt-3 mx-1 bg-gray-200`}
                          >
                            <div className="flex justify-center items-center">
                              <span className="text-[10px] mr-1">
                                -2852 (3.281)
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="grey"
                                class="size-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                        </>
                      </>
                    </div>
                  );
                }
              })}

            {/* <div className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-60 text-center relative shadow-md">
              <div className="flex justify-center mb-2 border-[1px] border-gray-200 rounded-full h-[50px] w-[50px] absolute top-[-20px] left-[70px] z-30 bg-white items-center">
                <img
                  src={silverRate}
                  alt="Silver Icon"
                  className="h-[30px] w-[30px]"
                />
              </div>
              <div className="text-gray-600 mt-5">SILVER ($)</div>
              {liverates?.length || isSkeleton ? (
                <>
                  {liverates?.map((item, index) => {
                    if (item?.type == "root" && item?.product == "SILVER ($)") {
                      return (
                        <>
                          <div key={index}>
                            {!isMobileScreen && (
                              <div
                                className=""
                                style={{ height: "15px", width: "10px" }}
                              ></div>
                            )}
                            {handleColor(index, "sell") && (
                              <p
                                style={{
                                  color: `${handleColor(index, "buy")}`,
                                  transition: "color 0.1s ease-in-out",
                                  fontSize: `${
                                    isMobileScreen ? "30px" : "24px"
                                  }`,
                                  fontWeight: "bold",
                                }}
                              >
                                {item?.sell}
                              </p>
                            )}
                          </div>
                          <div
                            className={`bg-[#E8FFDA] rounded-full py-[5px] mt-3 mx-3`}
                          >
                            <div className="flex justify-center items-center">
                              <span className="text-[10px] mr-1">
                                -2852 (3.281)
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="green"
                                class="size-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}
                </>
              ) : (
                <CardSkeleton />
              )}
            </div> */}

            {/* <div className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-60 text-center relative shadow-md">
              <div className="flex justify-center mb-2 border-[1px] border-gray-200 rounded-full h-[50px] w-[50px] absolute top-[-20px] left-[70px] z-30 bg-white items-center">
                <img
                  src={dollar}
                  alt="Gold Icon"
                  className="h-[25px] w-[25px]"
                />
              </div>
              <div className="text-gray-600 mt-5">USD INR</div>
              {liverates?.length || isSkeleton ? (
                <>
                  {liverates?.map((item, index) => {
                    if (item?.type == "root" && item?.product == "INR (₹)") {
                      return (
                        <>
                          <div key={index}>
                            {!isMobileScreen && (
                              <div
                                className=""
                                style={{ height: "15px", width: "10px" }}
                              ></div>
                            )}
                            {handleColor(index, "sell") && (
                              <p
                                style={{
                                  color: `${handleColor(index, "buy")}`,
                                  transition: "color 0.1s ease-in-out",
                                  fontSize: `${
                                    isMobileScreen ? "30px" : "24px"
                                  }`,
                                  fontWeight: "bold",
                                }}
                              >
                                {item?.sell}
                              </p>
                            )}
                          </div>
                          <div className="bg-[#FFEBEB] rounded-full py-[5px] mt-3 mx-3">
                            <div className="flex justify-center items-center">
                              <span className="text-[10px] mr-1">
                                -2852 (3.281)
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="red"
                                class="size-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}
                </>
              ) : (
                <CardSkeleton />
              )}
            </div> */}

            {/* <div className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-60 text-center relative shadow-md">
              <div className="flex justify-center mb-2 border-[1px] border-gray-200 rounded-full h-[50px] w-[50px] absolute top-[-20px] left-[70px] z-30 bg-white items-center">
                <img src={gold1} alt="Silver Icon" />
              </div>
              <div className="text-gray-600 mt-5">SILVER COST</div>
              {liverates?.length || isSkeleton ? (
                <>
                  {liverates?.map((item, index) => {
                    if (item?.type == "root" && item?.product == "SILVER") {
                      return (
                        <>
                          <div key={index}>
                            {!isMobileScreen && (
                              <div
                                className=""
                                style={{ height: "15px", width: "10px" }}
                              ></div>
                            )}
                            {handleColor(index, "sell") && (
                              <p
                                style={{
                                  color: `${handleColor(index, "buy")}`,
                                  transition: "color 0.1s ease-in-out",
                                  fontSize: `${
                                    isMobileScreen ? "30px" : "24px"
                                  }`,
                                  fontWeight: "bold",
                                }}
                              >
                                {item?.sell}
                              </p>
                            )}
                          </div>
                          <div className="bg-[#E8FFDA] rounded-full py-[5px] mt-3 mx-3">
                            <div className="flex justify-center items-center">
                              <span className="text-[10px] mr-1">
                                -2852 (3.281)
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="green"
                                class="size-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}
                </>
              ) : (
                <CardSkeleton />
              )}
            </div> */}

            {/* <div className="bg-white rounded-[25px] border-[1px] border-gray-200 p-4 w-60 text-center relative shadow-md">
              <div className="flex justify-center mb-2 border-[1px] border-gray-200 rounded-full h-[50px] w-[50px] absolute top-[-20px] left-[70px] z-30 bg-white items-center">
                <img src={gold2} alt="Gold Icon" />
              </div>
              <div className="text-gray-600 mt-5">GOLD COST</div>
              {liverates?.length || isSkeleton ? (
                <>
                  {" "}
                  {liverates?.map((item, index) => {
                    if (item?.type == "root" && item?.product == "GOLD") {
                      return (
                        <>
                          <div key={index}>
                            {!isMobileScreen && (
                              <div
                                className=""
                                style={{ height: "15px", width: "10px" }}
                              ></div>
                            )}
                            {handleColor(index, "sell") && (
                              <p
                                style={{
                                  color: `${handleColor(index, "buy")}`,
                                  transition: "color 0.1s ease-in-out",
                                  fontSize: `${
                                    isMobileScreen ? "30px" : "24px"
                                  }`,
                                  fontWeight: "bold",
                                }}
                              >
                                {item?.sell}
                              </p>
                            )}
                          </div>
                          <div className="bg-[#FFEBEB] rounded-full py-[5px] mt-3 mx-3">
                            <div className="flex justify-center items-center">
                              <span className="text-[10px] text-red-500 mr-1">
                                -2852 (3.281)
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="red"
                                class="size-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}
                </>
              ) : (
                <CardSkeleton />
              )}
            </div> */}
          </div>

          <div className="flex h-full size-full px-4 flex-col">
            <div className="bg-custom-gradient h-[48px] rounded-t-[20px] size-full flex justify-between px-6 items-center">
              <div className="flex items-center justify-end ml-20 w-[2/3]">
                <p className="text-white text-xl">PRODUCT</p>
              </div>
              <div className="flex items-center justify-end w-1/3 gap-20">
                <div className="h-[40px] w-[1px] bg-white"></div>
                <p className="text-white text-xl">BUY</p>
              </div>
              <div className="flex items-center justify-center self-center w-1/3 gap-20">
                <div className="h-[40px] w-[1px] bg-white"></div>
                <p className="text-white text-xl">SELL</p>
              </div>
            </div>
            {liverates?.length || isSkeleton ? (
              <>
                {" "}
                {liverates?.map((item, index) => {
                  if (item?.type != "root") {
                    return (
                      <div className="" key={index}>
                        <div
                          className={`flex h-[90px] justify-between ${
                            index == 0 ? "rounded-b-2xl" : "rounded-xl"
                          }  mb-3 border-[1px] border-gray-300 size-full bg-white`}
                        >
                          <div className="w-1/3 flex items-center justify-start ml-20 gap-6">
                            <img src={gold2} alt="Gold Icon" />
                            <p>{item?.product}</p>
                          </div>
                          <div className="flex flex-col gap-2 w-1/3 items-center justify-center ml-24">
                            <div className="flex items-center justify-center gap-1">
                              {item?.buy !== "-" ? (
                                <div
                                  className={`flex h-[30px] w-[90px] rounded-xl ${
                                    handleColor(index, "sell") == "red"
                                      ? "border-red-500"
                                      : handleColor(index, "sell") == "green"
                                      ? "border-green-500"
                                      : "border-gray-200"
                                  } border-[2px] items-center justify-center py-4 text-xl`}
                                  // onClick={() => {
                                  //   if (item?.allowTrade) {
                                  //     Router("/new-order");
                                  //     handleSCode(item?.scriptId);
                                  //   }
                                  // }}
                                >
                                  <p
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "18px",
                                      // color: `${handleColor(index, "buy")}`,
                                      transition: "color 0.1s ease-in-out",
                                    }}
                                  >
                                    ₹ {item?.buy}
                                  </p>
                                </div>
                              ) : (
                                <p>{item?.buy}</p>
                              )}
                              {item?.buy !== "-" && (
                                <>
                                  {handleColor(index, "buy") == "red" ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill={`${handleColor(index, "buy")}`}
                                      class="size-6"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                  ) : handleColor(index, "buy") == "green" ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill={`${handleColor(index, "sell")}`}
                                      className="size-6"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="gray"
                                      className="size-6"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                </>
                              )}
                            </div>
                            {item?.buy !== "-" && (
                              <p className=" text-gray-500 text-[12px]">
                                H:72760 | L:72660
                              </p>
                            )}
                          </div>
                          <div className="flex flex-col gap-2 w-1/3 items-center justify-center">
                            <div className="flex items-center justify-center mt-2 gap-1">
                              <div
                                className={`flex h-[30px] w-[90px] rounded-xl 
                                  ${
                                    handleColor(index, "sell") == "red"
                                      ? "border-red-500"
                                      : handleColor(index, "sell") == "green"
                                      ? "border-green-500"
                                      : "border-gray-200"
                                  } border-[2px] items-center justify-center text-xl py-4 ml-8 cursor-pointer`}
                                onClick={() => {
                                  tradeChecker(item);
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    // color: `${handleColor(index, "sell")}`,
                                    transition: "color 0.1s ease-in-out",
                                  }}
                                >
                                  ₹ {item?.sell}
                                </p>
                              </div>
                              {handleColor(index, "sell") == "red" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill={`${handleColor(index, "sell")}`}
                                  class="size-6"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              ) : handleColor(index, "sell") == "green" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill={`${handleColor(index, "sell")}`}
                                  className="size-6"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="gray"
                                  className="size-6"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </div>
                            <p className=" text-gray-500 text-[12px]">
                              H:72760 | L:72660
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </>
            ) : (
              <TableSkeleton />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MainScreen;
